import async from "./components/Async";
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
import AuthGuard from "./components/guards/AuthGuard";
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ResetPassword from "./pages/auth/ResetPassword";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";
import ProtectedPage from "./pages/protected/ProtectedPage";
import GroupsPage from "./pages/pages/Groups";
import OfficesPage from "./pages/pages/Offices";
import DevicesPage from "./pages/pages/Devices";
import AdminsPage from "./pages/pages/Admins";
import ManagersPage from "./pages/pages/Managers";
import StaffsPage from "./pages/pages/Staffs";
import DeviceNewPage from "./pages/pages/DeviceNew";
import PlansPage from "./pages/pages/Plans";
import OfficeDeviceLogListPage from "./pages/pages/OfficeDeviceLogList";
import GroupsNewPage from "./pages/pages/GroupsNew";
import OfficesNewPage from "./pages/pages/OfficesNew";
import OfficeDevicesPage from "./pages/pages/OfficeDevices";
import OfficeDevicesNewPage from "./pages/pages/OfficeDevicesNew";
import AdminsNewPage from "./pages/pages/AdminsNew";
import StaffsNewPage from "./pages/pages/StaffsNew";
import ManagersNewPage from "./pages/pages/ManagersNew";
import PlansNewPage from "./pages/pages/PlansNew";
import AdminsEditPage from "./pages/pages/AdminsEdit";
import ManagersEditPage from "./pages/pages/ManagersEdit";
import StaffsEditPage from "./pages/pages/StaffsEdit";
import DevicesEditPage from "./pages/pages/DevicesEdit";
import GroupsEditPage from "./pages/pages/GroupsEdit";
import OfficesEditPage from "./pages/pages/OfficesEdit";
import OfficeDevicesEditPage from "./pages/pages/OfficeDevicesEdit";
import PlansEditPage from "./pages/pages/PlansEdit";
import ReportsPage from "./pages/pages/Reports";
import ResetPasswordCreate from "./pages/auth/ResetPasswordCreate";
import SettingsPage from "./pages/pages/Settings";
import AlertSettingsPage from "./pages/pages/AlertSettings";
import AlertSettingsNewPage from "./pages/pages/AlertSettingsNew";
import AlertSettingsEditPage from "./pages/pages/AlertSettingsEdit";
import OfficeDeviceLogDownloadPage from "./pages/pages/OfficeDeviceLogDownload";
import OfficeCategoriesPage from "./pages/pages/OfficeCategories";
import OfficeCategoriesNewPage from "./pages/pages/OfficeCategoriesNew";
import OfficeCategoriesEditPage from "./pages/pages/OfficeCategoriesEdit";
import OfficeDeviceDashboardPage from "./pages/pages/OfficeDeviceDashboard";

const Default = async(() => import("./pages/dashboards/Default"));

const routes = [
  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Default />,
        // element: <GroupsPage />,
      },
    ],
  },
  {
    path: "groups",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <GroupsPage />,
      },
      {
        path: "new",
        element: <GroupsNewPage />,
      },
      {
        path: ":id",
        element: <GroupsEditPage />,
      },
    ],
  },
  {
    path: "offices",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <OfficesPage />,
      },
      {
        path: "new",
        element: <OfficesNewPage />,
      },
      {
        path: ":id",
        element: <OfficesEditPage />,
      },
    ],
  },
  {
    path: "categories",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <OfficeCategoriesPage />,
      },
      {
        path: "new",
        element: <OfficeCategoriesNewPage />,
      },
      {
        path: ":id",
        element: <OfficeCategoriesEditPage />,
      },
    ],
  },
  {
    path: "devices",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <DevicesPage />,
      },
      {
        path: "new",
        element: <DeviceNewPage />,
      },
      {
        path: ":id",
        element: <DevicesEditPage />,
      },
    ],
  },
  {
    path: "admins",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <AdminsPage />,
      },
      {
        path: "new",
        element: <AdminsNewPage />,
      },
      {
        path: ":id",
        element: <AdminsEditPage />,
      },
    ],
  },
  {
    path: "managers",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ManagersPage />,
      },
      {
        path: "new",
        element: <ManagersNewPage />,
      },
      {
        path: ":id",
        element: <ManagersEditPage />,
      },
    ],
  },
  {
    path: "staffs",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <StaffsPage />,
      },
      {
        path: "new",
        element: <StaffsNewPage />,
      },
      {
        path: ":id",
        element: <StaffsEditPage />,
      },
    ],
  },
  {
    path: "alert-setting",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <AlertSettingsPage />,
      },
      {
        path: "new",
        element: <AlertSettingsNewPage />,
      },
      {
        path: ":id",
        element: <AlertSettingsEditPage />,
      },
    ],
  },
  {
    path: "reports",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ReportsPage />,
      },
    ],
  },
  {
    path: "office-devices",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <OfficeDevicesPage />,
      },
      {
        path: "new",
        element: <OfficeDevicesNewPage />,
      },
      {
        path: ":id",
        children: [
          {
            path: "",
            element: <OfficeDevicesEditPage />,
          },
          {
            path: "log",
            element: <OfficeDeviceLogListPage />,
          },
          {
            path: "report",
            element: <ReportsPage />,
          },
          {
            path: "dashboard",
            element: <OfficeDeviceDashboardPage />,
          },
        ],
      },
      {
        path: "log/download",
        element: <OfficeDeviceLogDownloadPage />,
      },
    ],
  },
  {
    path: "announcement_histories",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Default />,
      },
    ],
  },
  {
    path: "plans",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <PlansPage />,
      },
      {
        path: "new",
        element: <PlansNewPage />,
      },
      {
        path: ":id",
        element: <PlansEditPage />,
      },
    ],
  },
  {
    path: "settings",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <SettingsPage />,
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "reset-password-create",
        element: <ResetPasswordCreate />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "private",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ProtectedPage />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
