import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import {
  Autocomplete,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import { ErrorResponse } from "../../types/api";
import { axiosInstance } from "../../utils/axios";
import { DatePicker } from "@mui/lab";
import { format } from "date-fns";
import { OfficeListResponse, OfficeListURI } from "../../types/api/office";
import {
  OfficeDeviceResponse,
  OfficeDeviceURI,
  UpdateOfficeDeviceParams,
} from "../../types/api/officeDevice";
import Notification from "../../components/Notification";
import { DeviceListURI, FetchDeviceListResponse } from "../../types/api/device";
import useAuth from "../../hooks/useAuth";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)<{ m?: number }>`
  min-width: 240px;
  width: 100%;
`;

interface ButtonProps extends SpacingProps {
  component?: string;
}
const Button = styled(MuiButton)<ButtonProps>(spacing);

const deviceLocationTypeList = [
  { id: "office_address", name: "事業所の住所" },
  { id: "manual", name: "手動で入力" },
];

type Props = {
  id: string;
};

const OfficeDeviceEditPageContent = ({ id }: Props) => {
  const [errorMessageOpen, setErrorMessageOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [officeDevice, setOfficeDevice] = useState<OfficeDeviceResponse>();
  const [officeList, setOfficeList] = useState<OfficeListResponse["objects"]>(
    []
  );
  const [deviceList, setDeviceList] = useState<
    FetchDeviceListResponse["objects"]
  >([]);
  const [selectedOffice, setSelectedOffice] =
    useState<{ id: string; label: string }>();
  const [selectedDevice, setSelectedDevice] =
    useState<{ id: string; label: string }>();
  const [selectedSetupDate, setSelectedSetupDate] = useState<string | null>(
    null
  );
  const [selectedRemovalDate, setSelectedRemovalDate] = useState<string | null>(
    null
  );
  const [selectedDeviceLocationType, setSelectedDeviceLocationType] =
    useState<String | null>(null);
  const navigate = useNavigate();
  const { user } = useAuth();

  const fetchOfficeList = async () => {
    const officeListResponse = await axiosInstance.get<
      OfficeListResponse | ErrorResponse
    >(OfficeListURI, {
      params: {
        offset: 0,
        limit: 5000,
        sort_by: "created_at-desc",
      },
    });
    if (officeListResponse.status === 200) {
      setOfficeList((officeListResponse.data as OfficeListResponse).objects);
    } else {
      setErrorMessage((officeListResponse.data as ErrorResponse).message!);
      setErrorMessageOpen(true);
      setTimeout(() => {
        setErrorMessageOpen(false);
      }, 5000);
    }
  };

  const fetchDeviceList = async () => {
    const deviceListResponse = await axiosInstance.get<
      FetchDeviceListResponse | ErrorResponse
    >(DeviceListURI, {
      params: {
        offset: 0,
        limit: 5000,
        sort_by: "created_at-desc",
      },
    });
    if (deviceListResponse.status === 200) {
      setDeviceList(
        (deviceListResponse.data as FetchDeviceListResponse).objects
      );
    } else {
      setErrorMessage((deviceListResponse.data as ErrorResponse).message!);
      setErrorMessageOpen(true);
      setTimeout(() => {
        setErrorMessageOpen(false);
      }, 5000);
    }
  };

  const fetchOfficeDevice = async () => {
    const officeDeviceResponse = await axiosInstance.get<
      OfficeDeviceResponse | ErrorResponse
    >(OfficeDeviceURI(id));
    if (officeDeviceResponse.status === 200) {
      const officeDevice = officeDeviceResponse.data as OfficeDeviceResponse;
      setOfficeDevice(officeDevice);
      setSelectedOffice({
        id: officeDevice.office.id,
        label: officeDevice.office.name,
      });
      setSelectedDevice({
        id: officeDevice.device.id,
        label: officeDevice.device.name,
      });
      setSelectedDeviceLocationType(
        (officeDeviceResponse.data as OfficeDeviceResponse).locationType
      );
      const setupDate = (officeDeviceResponse.data as OfficeDeviceResponse)
        .setupDate;
      if (setupDate) {
        setSelectedSetupDate(format(new Date(setupDate), "yyyy-MM-dd"));
      }
      const removalDate = (officeDeviceResponse.data as OfficeDeviceResponse)
        .removalDate;
      if (removalDate) {
        setSelectedRemovalDate(format(new Date(removalDate), "yyyy-MM-dd"));
      }
    } else {
      setErrorMessage((officeDeviceResponse.data as ErrorResponse).message!);
      setErrorMessageOpen(true);
      setTimeout(() => {
        setErrorMessageOpen(false);
      }, 5000);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (user?.role !== "staff") {
        fetchOfficeList();
      }

      if (user?.role === "admin") {
        fetchDeviceList();
      }

      fetchOfficeDevice();
    };

    fetchData();
  }, [id]);

  const submit = async () => {
    const res = await axiosInstance.patch<OfficeDeviceResponse | ErrorResponse>(
      OfficeDeviceURI(id),
      {
        office_id: selectedOffice?.id,
        device_id: selectedDevice?.id,
        mac_address: officeDevice?.macAddress,
        place_name: officeDevice?.placeName,
        setup_date: selectedSetupDate,
        location_type: selectedDeviceLocationType,
        lat_lng: officeDevice?.latLng,
        removal_date: selectedRemovalDate,
      } as UpdateOfficeDeviceParams
    );
    if (res.status === 200) {
      navigate("/office-devices");
    } else {
      setErrorMessage((res.data as ErrorResponse).message!);
      setErrorMessageOpen(true);
      setTimeout(() => {
        setErrorMessageOpen(false);
      }, 5000);
    }
  };

  return (
    <>
      <Card mb={6}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item md={6}>
              {selectedOffice && officeList && (
                <FormControl my={2}>
                  <Autocomplete
                    disablePortal
                    id="office-list-select"
                    fullWidth
                    defaultValue={{
                      id: selectedOffice.id,
                      label: selectedOffice.label,
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="事業所" required />
                    )}
                    options={officeList.map((item) => {
                      return { id: item.id, label: item.name };
                    })}
                    onChange={(event, target) => {
                      if (target) {
                        setSelectedOffice({
                          id: target.id,
                          label: target.label,
                        });
                      }
                    }}
                  />
                </FormControl>
              )}
            </Grid>
            <Grid item md={6}>
              {selectedDevice && (
                <FormControl my={2}>
                  <Autocomplete
                    disablePortal
                    id="device-list-select"
                    fullWidth
                    defaultValue={{
                      id: selectedDevice.id,
                      label: selectedDevice.label,
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="デバイス" required />
                    )}
                    options={officeList.map((item) => {
                      return { id: item.id, label: item.name };
                    })}
                    onChange={(event, target) => {
                      if (target) {
                        setSelectedDevice({
                          id: target.id,
                          label: target.label,
                        });
                      }
                    }}
                  />
                </FormControl>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item md={6}>
              <FormControl fullWidth my={2} variant="outlined">
                <TextField
                  id="mac-address"
                  label="MACアドレス"
                  required
                  variant="outlined"
                  defaultValue={officeDevice?.macAddress}
                  value={officeDevice?.macAddress}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    if (officeDevice)
                      setOfficeDevice({
                        ...officeDevice,
                        macAddress: e.target?.value.replace(/:/g, ""),
                      });
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl fullWidth my={2} variant="outlined">
                <TextField
                  id="place-name"
                  label="設置場所名"
                  required
                  variant="outlined"
                  defaultValue={officeDevice?.placeName}
                  value={officeDevice?.placeName}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    if (officeDevice)
                      setOfficeDevice({
                        ...officeDevice,
                        placeName: e.target?.value,
                      });
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item md={6}>
              <FormControl fullWidth my={2} variant="outlined">
                <DatePicker
                  label="設置日"
                  value={selectedSetupDate}
                  inputFormat={"yyyy-MM-dd"}
                  onChange={(date: Date | null) => {
                    if (date) setSelectedSetupDate(format(date, "yyyy-MM-dd"));
                  }}
                  renderInput={(params) => <TextField {...params} required />}
                />
              </FormControl>
            </Grid>
            {user?.role === "admin" && (
              <Grid item md={6}>
                <FormControl fullWidth my={2} variant="outlined">
                  <DatePicker
                    label="廃止日"
                    value={selectedRemovalDate}
                    inputFormat={"yyyy-MM-dd"}
                    onChange={(date: Date | null) => {
                      setSelectedRemovalDate(
                        date ? format(date, "yyyy-MM-dd") : null
                      );
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </FormControl>
              </Grid>
            )}
          </Grid>
          <Grid container spacing={6}>
            <Grid item md={6}>
              {selectedDeviceLocationType && (
                <FormControl my={2}>
                  <InputLabel
                    id="device-location-type-list-select-label"
                    required
                  >
                    デバイス位置情報の種別
                  </InputLabel>
                  <Select
                    labelId="device-location-type-list-select-label"
                    id="device-location-type-list-select"
                    value={selectedDeviceLocationType}
                    defaultValue={selectedDeviceLocationType}
                    label="デバイス位置情報の種別"
                    required
                    fullWidth
                    onChange={(e) => {
                      setSelectedDeviceLocationType(e.target.value);
                    }}
                  >
                    {deviceLocationTypeList.map((deviceLocationType, i) => {
                      return (
                        <MenuItem
                          key={deviceLocationType.id}
                          value={deviceLocationType.id}
                        >
                          {deviceLocationType.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
            </Grid>
            {selectedDeviceLocationType === "manual" && (
              <Grid item md={6}>
                <FormControl fullWidth my={2} variant="outlined">
                  <TextField
                    id="lat-lng"
                    label="緯度経度"
                    variant="outlined"
                    value={officeDevice?.latLng}
                    defaultValue={officeDevice?.latLng}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      if (officeDevice)
                        setOfficeDevice({
                          ...officeDevice,
                          latLng: e.target?.value,
                        });
                    }}
                  />
                </FormControl>
              </Grid>
            )}
          </Grid>
          <Button
            my={2}
            variant="contained"
            color="primary"
            onClick={() => submit()}
          >
            変更を保存する
          </Button>
        </CardContent>
      </Card>
      <Notification
        message={errorMessage}
        isOpen={errorMessageOpen}
        onClose={() => {
          setErrorMessageOpen(false);
        }}
      />
    </>
  );
};

function OfficeDevicesEditPage() {
  const location = useLocation();
  const pathNames = location.pathname.split("/").filter((x) => x);
  const id = pathNames[pathNames.length - 1];

  return (
    <React.Fragment>
      <Typography variant="h3" gutterBottom display="inline">
        設置デバイス編集
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          ダッシュボード
        </Link>
        <Link component={NavLink} to="/office-devices">
          設置デバイス一覧
        </Link>
        <Typography>設置デバイス編集</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <OfficeDeviceEditPageContent id={id} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default OfficeDevicesEditPage;
