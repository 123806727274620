import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";

import {
  Autocomplete,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import { axiosInstance } from "../../utils/axios";
import { ErrorResponse } from "../../types/api";
import Notification from "../../components/Notification";
import {
  FetchGroupListResponse,
  GroupListURI,
  GroupNewParams,
  GroupNewResponse,
  GroupNewURI,
} from "../../types/api/group";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const FormControl = styled(MuiFormControl)<{ my?: number }>(spacing);
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}
const Button = styled(MuiButton)<ButtonProps>(spacing);

const GroupsNewPageContent = () => {
  const navigate = useNavigate();
  const [errorMessageOpen, setErrorMessageOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [nameValue, setNameValue] = useState<String | null>(null);
  const [groupList, setGroupList] = useState<FetchGroupListResponse["objects"]>(
    []
  );
  const [selectedGroupId, setSelectedGroupId] = useState<string>();

  useEffect(() => {
    const fetchData = async () => {
      const res = await axiosInstance.get<
        FetchGroupListResponse | ErrorResponse
      >(GroupListURI, {
        params: {
          offset: 0,
          limit: 5000,
          sort_by: "created_at-desc",
        },
      });

      if (res.status === 200) {
        setGroupList((res.data as FetchGroupListResponse).objects);
      } else {
        setErrorMessage((res.data as ErrorResponse).message!);
        setErrorMessageOpen(true);
        setTimeout(() => {
          setErrorMessageOpen(false);
        }, 5000);
      }
    };

    fetchData();
  }, []);

  const submit = async () => {
    const res = await axiosInstance.post<GroupNewResponse | ErrorResponse>(
      GroupNewURI,
      {
        parent_group_id: selectedGroupId,
        name: nameValue,
      } as GroupNewParams
    );

    if (res.status === 200) {
      navigate("/groups");
    } else {
      setErrorMessage((res.data as ErrorResponse).message!);
      setErrorMessageOpen(true);
      setTimeout(() => {
        setErrorMessageOpen(false);
      }, 5000);
    }
  };

  return (
    <>
      <Card mb={6}>
        <CardContent>
          <FormControl fullWidth my={2} variant="outlined">
            <Autocomplete
              disablePortal
              id="group-list-select"
              fullWidth
              renderInput={(params) => (
                <TextField {...params} label="親グループ" />
              )}
              options={groupList.map((item) => {
                return { id: item.id, label: item.name };
              })}
              onChange={(event, target) => {
                if (target) {
                  setSelectedGroupId(target.id);
                }
              }}
            />
          </FormControl>
          <FormControl fullWidth my={2} variant="outlined">
            <TextField
              id="name"
              label="グループ名"
              required
              variant="outlined"
              defaultValue={nameValue}
              fullWidth
              my={2}
              onChange={(e) => {
                setNameValue(e.target.value);
              }}
            />
          </FormControl>

          <Button variant="contained" color="primary" onClick={() => submit()}>
            作成する
          </Button>
        </CardContent>
      </Card>
      <Notification
        message={errorMessage}
        isOpen={errorMessageOpen}
        onClose={() => {
          setErrorMessageOpen(false);
        }}
      />
    </>
  );
};

const GroupsNewPage = () => {
  return (
    <React.Fragment>
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            事業所グループ作成
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              ダッシュボード
            </Link>
            <Link component={NavLink} to="/groups">
              グループ一覧
            </Link>
            <Typography>事業所グループ作成</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={6} />

      <GroupsNewPageContent />
    </React.Fragment>
  );
};

export default GroupsNewPage;
