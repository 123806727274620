import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";

import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";

import {
  FetchOfficeDeviceLogListParams,
  FetchOfficeDeviceLogListResponse,
  OfficeDeviceLogListURI,
} from "../../../types/api";
import { axiosInstance } from "../../../utils/axios";
import {
  changeTimestampDateToTargetDate,
  formatDate,
  formatDateFromTimestamp,
  getYesterday,
} from "../../../utils/dateHelper";
import {
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import Notification from "../../../components/Notification";
import useAuth from "../../../hooks/useAuth";
import NavbarOfficesDropdown from "../../../components/navbar/NavbarOfficesDropdown";
import { useRecoilState } from "recoil";
import { officeDeviceAtom } from "../../../atoms/officeDevice";
import NavbarOfficeDevicesDropdown from "../../../components/navbar/NavbarOfficeDevicesDropdown";
import { DatePicker } from "@mui/lab";
import { format } from "date-fns";
import LatestDeviceLog from "./LatestDeviceLog";
import NavbarGroupsDropdown from "../../../components/navbar/NavbarGroupsDropdown";

const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)<{ m?: number }>`
  min-width: 240px;
  width: 100%;
`;

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const sortByTimestamp = (list: any[]) => {
  return list.sort((a, b) => (a.timestamp < b.timestamp ? -1 : 1));
};

interface TooltipLabelType {
  [key: string]: string;
}
const tooltipLabels: TooltipLabelType = {
  co2: "CO2",
  pm25: "PM2.5",
  co2Comparison: "CO2（比較）",
  pm25Comparison: "PM2.5（比較）",
  temp: "温度",
  rh: "湿度",
  tvoc: "tvoc",
  tempComparison: "温度（比較）",
  rhComparison: "湿度（比較）",
  tvocComparison: "tvoc（比較）",
};

const OfficeDashboard = () => {
  const { user } = useAuth();
  const [currentOfficeDevice, setCurrentOfficeDevice] =
    useRecoilState(officeDeviceAtom);
  const [errorMessageOpen, setErrorMessageOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [data, setData] = useState<
    FetchOfficeDeviceLogListResponse["logs"] | any[]
  >([]);
  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const [targetDate, setTargetDate] = useState<string>(
    formatDate(getYesterday(new Date()), "yyyy-MM-dd")
  );
  const [comparisonDate, setComparisonDate] = useState<string | null>(null);

  useEffect(() => {
    if (currentOfficeDevice && currentOfficeDevice.id.length > 0) {
      const fetchData = async () => {
        setIsLoading(true);
        const officeDeviceLogListResponse =
          await axiosInstance.get<FetchOfficeDeviceLogListResponse>(
            OfficeDeviceLogListURI(currentOfficeDevice.id),
            {
              params: {
                timezone: "jst",
                target_date: targetDate,
                type: "list",
              } as FetchOfficeDeviceLogListParams,
            }
          );
        let logList: any[] = [];
        officeDeviceLogListResponse.data.logs.forEach((log, index) => {
          logList.push({
            name: "",
            date: log.date,
            pm25: log.batteryValue > 0 ? log.pm25Value : null,
            co2: log.batteryValue > 0 ? log.co2Value : null,
            tvoc: log.batteryValue > 0 ? log.tvocValue : null,
            temp: log.batteryValue > 0 ? log.temperatureValue : null,
            rh: log.batteryValue > 0 ? log.humidityValue : null,
            timestamp: log.timestampValue,
          });
        });
        const sortedLogList = sortByTimestamp(logList);

        let comparisonLogList: any[] = [];
        if (comparisonDate) {
          const comparisonOfficeDeviceLogListResponse =
            await axiosInstance.get<FetchOfficeDeviceLogListResponse>(
              OfficeDeviceLogListURI(currentOfficeDevice.id),
              {
                params: {
                  timezone: "jst",
                  target_date: comparisonDate,
                  type: "list",
                } as FetchOfficeDeviceLogListParams,
              }
            );
          comparisonOfficeDeviceLogListResponse.data.logs.forEach(
            (log, index) => {
              comparisonLogList.push({
                pm25Comparison: log.batteryValue > 0 ? log.pm25Value : null,
                co2Comparison: log.batteryValue > 0 ? log.co2Value : null,
                tvocComparison: log.batteryValue > 0 ? log.tvocValue : null,
                tempComparison:
                  log.batteryValue > 0 ? log.temperatureValue : null,
                rhComparison: log.batteryValue > 0 ? log.humidityValue : null,
                timestamp: log.timestampValue,
              });
            }
          );
        }
        const sortedComparisonLogList = sortByTimestamp(comparisonLogList);

        let newLogList: any[] = [];
        sortedLogList.forEach((a, index) => {
          newLogList.push({
            ...a,
            ...sortedComparisonLogList[index],
          });
        });

        setData(newLogList);
        setIsLoading(false);
      };

      fetchData();
    }
  }, [currentOfficeDevice, targetDate, comparisonDate]);

  return (
    <>
      <Grid justifyContent="space-between" container spacing={6} mt="32px">
        <Grid item>
          <Typography variant="h3" gutterBottom>
            ダッシュボード
          </Typography>
          <Typography variant="subtitle1">
            {!!currentOfficeDevice
              ? `${currentOfficeDevice?.placeName} (${currentOfficeDevice?.macAddress})`
              : ""}
          </Typography>
        </Grid>

        <Grid item>
          <Grid container>
            <Grid item ml={2}>
              {user && ["admin"].includes(user.role) && (
                <NavbarGroupsDropdown />
              )}
            </Grid>
            <Grid item ml={2}>
              {user && ["manager", "admin"].includes(user.role) && (
                <NavbarOfficesDropdown />
              )}
            </Grid>
            <Grid item ml={2}>
              <NavbarOfficeDevicesDropdown />
            </Grid>
            <Grid item ml={2}>
              <FormControl fullWidth variant="outlined">
                <DatePicker
                  label="対象日"
                  value={targetDate}
                  inputFormat={"yyyy-MM-dd"}
                  onChange={(date: Date | null) => {
                    if (date) setTargetDate(format(date, "yyyy-MM-dd"));
                  }}
                  mask="____-__-__"
                  renderInput={(params) => (
                    <TextField size="small" {...params} />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item ml={2}>
              <FormControl fullWidth variant="outlined">
                <DatePicker
                  label="比較日"
                  value={comparisonDate}
                  inputFormat={"yyyy-MM-dd"}
                  onChange={(date: Date | null) => {
                    setComparisonDate(date ? format(date, "yyyy-MM-dd") : date);
                  }}
                  clearable
                  clearText="クリア"
                  mask="____-__-__"
                  renderInput={(params) => (
                    <TextField size="small" {...params} />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Divider my={6} />

      {currentOfficeDevice && currentOfficeDevice.id && (
        <Grid container spacing={6}>
          <Grid item xs={12} lg={5}>
            <LatestDeviceLog officeDeviceId={currentOfficeDevice.id} />
          </Grid>
          <Grid item xs={12} lg={7}>
            {/* <BarChart
              officeDeviceId={currentOfficeDevice.id}
              targetDate={targetDate}
              comparisonDate={comparisonDate}
            /> */}
          </Grid>
        </Grid>
      )}

      {!isLoading && data.length > 0 && (
        <Grid container spacing={6}>
          <Grid item xs={12} lg={12}>
            <Card mb={6}>
              <CardHeader title={`CO2とPM2.5の推移`} />
              <CardContent>
                <ResponsiveContainer width="100%" aspect={4.0 / 1.0}>
                  <ComposedChart
                    data={data}
                    margin={{ top: 4, right: 0, left: 0, bottom: 4 }}
                  >
                    <YAxis
                      yAxisId={1}
                      tick={{ fontSize: 14 }}
                      width={80}
                      domain={[400, 1200]}
                    />
                    <YAxis
                      yAxisId={2}
                      tick={{ fontSize: 12 }}
                      width={80}
                      orientation="right"
                    />
                    <XAxis
                      tick={{ fontSize: 12 }}
                      dataKey="timestamp"
                      tickFormatter={(tickItem: any) => {
                        const mm = formatDateFromTimestamp(tickItem, "mm");
                        const HH = formatDateFromTimestamp(tickItem, "HH");
                        return mm === "00" ? `${HH}:${mm}` : "";
                      }}
                      interval={1}
                      angle={30}
                      textAnchor="start"
                    />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Line
                      connectNulls
                      dataKey="co2"
                      stroke="#ff9800"
                      yAxisId={1}
                      dot={true}
                      isAnimationActive={true}
                    />
                    <Line
                      connectNulls
                      dataKey="pm25"
                      stroke="#4caf50"
                      yAxisId={2}
                      dot={true}
                      isAnimationActive={true}
                    />
                    <Line
                      connectNulls
                      dataKey="co2Comparison"
                      stroke="#ff9800"
                      opacity={0.5}
                      yAxisId={1}
                      dot={true}
                      isAnimationActive={true}
                      legendType="none"
                    />
                    <Line
                      connectNulls
                      dataKey="pm25Comparison"
                      stroke="#4caf50"
                      opacity={0.5}
                      yAxisId={2}
                      dot={true}
                      isAnimationActive={true}
                      legendType="none"
                    />
                    <Tooltip
                      labelFormatter={(label) =>
                        formatDate(
                          new Date(
                            changeTimestampDateToTargetDate(
                              targetDate,
                              label as number
                            )
                          ),
                          "MM/dd HH:mm"
                        )
                      }
                      formatter={(value: any, name: string, props: any) => [
                        value.toLocaleString(),
                        tooltipLabels[name] || "",
                      ]}
                    />
                    <Legend
                      formatter={(value, entry, index) =>
                        value === "co2"
                          ? "CO2"
                          : value === "pm25"
                          ? "PM2.5"
                          : ""
                      }
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              </CardContent>
            </Card>

            <Card mb={6}>
              <CardHeader title={`温度と湿度の推移`} />
              <CardContent>
                <ResponsiveContainer width="100%" aspect={4.0 / 1.0}>
                  <ComposedChart
                    data={data}
                    margin={{ top: 4, right: 0, left: 0, bottom: 4 }}
                  >
                    <YAxis yAxisId={1} tick={{ fontSize: 14 }} width={80} />
                    <YAxis
                      yAxisId={2}
                      tick={{ fontSize: 12 }}
                      width={80}
                      orientation="right"
                    />
                    <XAxis
                      tick={{ fontSize: 12 }}
                      dataKey="timestamp"
                      tickFormatter={(tickItem: any) => {
                        const mm = formatDateFromTimestamp(tickItem, "mm");
                        const HH = formatDateFromTimestamp(tickItem, "HH");
                        return mm === "00" ? `${HH}:${mm}` : "";
                      }}
                      interval={1}
                      angle={30}
                      textAnchor="start"
                    />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Line
                      connectNulls
                      dataKey="temp"
                      stroke="#ff9800"
                      yAxisId={1}
                      dot={true}
                      isAnimationActive={true}
                    />
                    <Line
                      connectNulls
                      dataKey="rh"
                      stroke="#4caf50"
                      yAxisId={2}
                      dot={true}
                      isAnimationActive={true}
                    />
                    <Line
                      connectNulls
                      dataKey="tempComparison"
                      stroke="#ff9800"
                      opacity={0.5}
                      yAxisId={1}
                      dot={true}
                      isAnimationActive={true}
                      legendType="none"
                    />
                    <Line
                      connectNulls
                      dataKey="rhComparison"
                      stroke="#4caf50"
                      opacity={0.5}
                      yAxisId={2}
                      dot={true}
                      isAnimationActive={true}
                      legendType="none"
                    />
                    <Tooltip
                      labelFormatter={(label) =>
                        formatDate(
                          new Date(
                            changeTimestampDateToTargetDate(
                              targetDate,
                              label as number
                            )
                          ),
                          "MM/dd HH:mm"
                        )
                      }
                      formatter={(value: any, name: string, props: any) => [
                        value.toLocaleString(),
                        tooltipLabels[name] || "",
                      ]}
                    />
                    <Legend
                      formatter={(value, entry, index) =>
                        value === "temp" ? "温度" : value === "rh" ? "湿度" : ""
                      }
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              </CardContent>
            </Card>
            <Card mb={6}>
              <CardHeader title={`tvocの推移`} />
              <CardContent>
                <ResponsiveContainer width="100%" aspect={4.0 / 1.0}>
                  <ComposedChart
                    data={data}
                    margin={{ top: 4, right: 0, left: 0, bottom: 4 }}
                  >
                    <YAxis yAxisId={1} tick={{ fontSize: 14 }} width={80} />
                    <YAxis
                      yAxisId={2}
                      tick={{ fontSize: 12 }}
                      width={80}
                      orientation="right"
                    />
                    <XAxis
                      tick={{ fontSize: 12 }}
                      dataKey="timestamp"
                      tickFormatter={(tickItem: any) => {
                        const mm = formatDateFromTimestamp(tickItem, "mm");
                        const HH = formatDateFromTimestamp(tickItem, "HH");
                        return mm === "00" ? `${HH}:${mm}` : "";
                      }}
                      interval={1}
                      angle={30}
                      textAnchor="start"
                    />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Line
                      connectNulls
                      dataKey="tvoc"
                      stroke="#4caf50"
                      yAxisId={2}
                      dot={true}
                      isAnimationActive={true}
                    />
                    <Line
                      connectNulls
                      dataKey="tvocComparison"
                      stroke="#ff9800"
                      opacity={0.5}
                      yAxisId={1}
                      dot={true}
                      isAnimationActive={true}
                      legendType="none"
                    />
                    <Tooltip
                      labelFormatter={(label) =>
                        formatDate(
                          new Date(
                            changeTimestampDateToTargetDate(
                              targetDate,
                              label as number
                            )
                          ),
                          "MM/dd HH:mm"
                        )
                      }
                      formatter={(value: any, name: string, props: any) => [
                        value.toLocaleString(),
                        tooltipLabels[name] || "",
                      ]}
                    />
                    <Legend
                      formatter={(value, entry, index) =>
                        value === "tvoc" ? "tvoc" : "tvoc"
                      }
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}

      {!isLoading && data.length === 0 && (
        <CardContent
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h4" gutterBottom>
            表示するデータがありません
          </Typography>
        </CardContent>
      )}

      {isLoading && (
        <CardContent
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </CardContent>
      )}

      <Notification
        message={errorMessage}
        isOpen={errorMessageOpen}
        onClose={() => {
          setErrorMessageOpen(false);
        }}
      />
    </>
  );
};
export default withTheme(OfficeDashboard);
