import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  Link,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import { axiosInstance } from "../../utils/axios";
import { ErrorResponse } from "../../types/api";
import Notification from "../../components/Notification";
import {
  OfficeCategoryEditParams,
  OfficeCategoryEditResponse,
  OfficeCategoryEditURI,
  OfficeCategoryResponse,
  OfficeCategoryURI,
} from "../../types/api/officeCategory";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const FormControl = styled(MuiFormControl)<{ my?: number }>(spacing);
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}
const Button = styled(MuiButton)<ButtonProps>(spacing);

type Props = {
  id: string;
};

const OfficeCategoriesEditPageContent = ({ id }: Props) => {
  const navigate = useNavigate();
  const [errorMessageOpen, setErrorMessageOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [officeCategory, setOfficeCategory] =
    useState<OfficeCategoryResponse>();
  const [loadingOfficeCategory, setLoadingOfficeCategory] = useState<
    boolean | undefined
  >();

  useEffect(() => {
    const fetchData = async () => {
      setLoadingOfficeCategory(true);
      const officeCategoryResponse = await axiosInstance.get<
        OfficeCategoryResponse | ErrorResponse
      >(OfficeCategoryURI(id));
      if (officeCategoryResponse.status === 200) {
        setOfficeCategory(
          officeCategoryResponse.data as OfficeCategoryResponse
        );
      } else {
        setErrorMessage(
          (officeCategoryResponse.data as ErrorResponse).message!
        );
        setErrorMessageOpen(true);
        setTimeout(() => {
          setErrorMessageOpen(false);
        }, 5000);
      }
      setLoadingOfficeCategory(false);
    };

    fetchData();
  }, [id]);

  const submit = async () => {
    const res = await axiosInstance.patch<
      OfficeCategoryEditResponse | ErrorResponse
    >(OfficeCategoryEditURI(id), {
      name: officeCategory?.name,
    } as OfficeCategoryEditParams);

    if (res.status === 200) {
      navigate("/categories");
    } else {
      setErrorMessage((res.data as ErrorResponse).message!);
      setErrorMessageOpen(true);
      setTimeout(() => {
        setErrorMessageOpen(false);
      }, 5000);
    }
  };

  return (
    <>
      <Card mb={6}>
        {loadingOfficeCategory === false && (
          <CardContent>
            <FormControl fullWidth my={2} variant="outlined">
              <TextField
                id="name"
                label="事業所カテゴリ"
                required
                variant="outlined"
                defaultValue={officeCategory?.name}
                value={officeCategory?.name}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                my={2}
                onChange={(e) => {
                  if (officeCategory)
                    setOfficeCategory({
                      ...officeCategory,
                      name: e.target?.value,
                    });
                }}
              />
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              onClick={() => submit()}
            >
              変更を保存する
            </Button>
          </CardContent>
        )}
        {loadingOfficeCategory && (
          <CardContent
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </CardContent>
        )}
      </Card>
      <Notification
        message={errorMessage}
        isOpen={errorMessageOpen}
        onClose={() => {
          setErrorMessageOpen(false);
        }}
      />
    </>
  );
};

const OfficeCategoriesEditPage = () => {
  const location = useLocation();
  const pathNames = location.pathname.split("/").filter((x) => x);
  const id = pathNames[pathNames.length - 1];

  return (
    <React.Fragment>
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            事業所カテゴリー編集
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              ダッシュボード
            </Link>
            <Link component={NavLink} to="/categories">
              事業所カテゴリー一覧
            </Link>
            <Typography>事業所カテゴリー編集</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={6} />

      <OfficeCategoriesEditPageContent id={id} />
    </React.Fragment>
  );
};

export default OfficeCategoriesEditPage;
