import { CamelCasedPropertiesDeep } from "type-fest";
import { components } from "../../schema";

export type ObjectDeviceCategory = CamelCasedPropertiesDeep<
  components["schemas"]["Object_DeviceCategory"]
>;

export const InitialObjectDeviceCategory: ObjectDeviceCategory = {
  id: "",
  createdAt: 0,
  updatedAt: 0,
  name: "",
};
