import {
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { Fragment } from "react";
import { useRecoilState } from "recoil";
import { officeDeviceAtom, officeDevicesAtom } from "../../atoms/officeDevice";

function NavbarOfficeDevicesDropdown() {
  const [currentOfficeDevice, setCurrentOfficeDevice] =
    useRecoilState(officeDeviceAtom);
  const [currentOfficeDevices, setCurrentOfficeDevices] =
    useRecoilState(officeDevicesAtom);

  const handleChange = (
    event: SelectChangeEvent<String | null>,
    child: React.ReactNode
  ) => {
    const targetId = event.target.value as String;
    const targetOfficeDevice = currentOfficeDevices.find(
      (officeDevice) => officeDevice.id === targetId
    );
    if (targetOfficeDevice) {
      setCurrentOfficeDevice(targetOfficeDevice);
    }
  };

  if (currentOfficeDevice && currentOfficeDevices) {
    return (
      <Fragment>
        <FormControl fullWidth style={{ minWidth: "150px" }}>
          <InputLabel id="office-device-select-label">センサー機器</InputLabel>
          <Select
            labelId="office-device-select-label"
            id="office-device-select"
            value={currentOfficeDevice.id}
            label="OfficeDevice"
            onChange={handleChange}
            size="small"
          >
            {currentOfficeDevices.map((officeDevice, i) => {
              return (
                <MenuItem key={officeDevice.id} value={officeDevice.id}>
                  {officeDevice.placeName}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Fragment>
    );
  } else {
    return <></>;
  }
}

export default NavbarOfficeDevicesDropdown;
