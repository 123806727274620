import { CamelCasedPropertiesDeep } from "type-fest";
import { components } from "../../schema";

export const LoginAuthURI = "/admin/auth/login";
export const VerificationCodeNewURI = "/admin/auth/verification-code/new";
export const ResetPasswordURI = "/admin/auth/reset-password";
export const ChangePasswordURI = "/admin/auth/change-password";

export type LoginAuthParams = components["schemas"]["RequestBody_LoginAuth"];
export type VerificationCodeNewParams =
  components["schemas"]["RequestBody_MakeVerificationCode"];
export type ResetPasswordParams =
  components["schemas"]["RequestBody_ResetUserPassword"];
export type ChangePasswordParams =
  components["schemas"]["RequestBody_ChangeUserPassword"];

export type VerificationCodeNewResponse = CamelCasedPropertiesDeep<
  components["responses"]["API_Response_Success"]
>;
export type ResetPasswordResponse = CamelCasedPropertiesDeep<
  components["responses"]["API_Response_Success"]
>;
export type ChangePasswordResponse = CamelCasedPropertiesDeep<
  components["responses"]["API_Response_Success"]
>;
