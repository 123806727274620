import { ReactNode } from "react";
import { RecoilRoot } from "recoil";
import { RecoilSyncProvider } from "./recoilSyncProvider";

export const RecoilProvider = ({ children }: { children: ReactNode }) => {
  return (
    <RecoilRoot>
      <RecoilSyncProvider>{children}</RecoilSyncProvider>
    </RecoilRoot>
  );
};
