import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";

import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Grid,
  Button,
  List,
  ListItemButton,
  ListItemText,
  Collapse,
  CircularProgress,
  FormControl,
  TextField,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridValueFormatterParams,
  jaJP,
} from "@mui/x-data-grid";
import { spacing } from "@mui/system";
import {
  Add as AddIcon,
  Upload as UploadIcon,
  Download as DownloadIcon,
  KeyboardArrowRightRounded,
  KeyboardArrowDownRounded,
} from "@mui/icons-material";
import { formatPrefecture } from "../../utils/formatter";
import { axiosInstance } from "../../utils/axios";
import {
  OfficeDownloadCsvURI,
  OfficeListResponse,
  OfficeListURI,
  OfficeResponse,
  OfficesGenerateCsvURI,
  OfficesUploadCsvResponse,
  OfficesUploadCsvURI,
  OfficeURI,
} from "../../types/api/office";
import EditButton from "../../components/buttons/EditButton";
import DeleteButton from "../../components/buttons/DeleteButton";
import { ErrorResponse } from "../../types/api";
import useAuth from "../../hooks/useAuth";
import Notification, { NotificationProps } from "../../components/Notification";
import { CustomToolbar } from "../../components/tables/Toolbar";
import { downloadFile } from "../../utils/file";
import {
  FetchGroupListResponse,
  GroupListTopDownURI,
} from "../../types/api/group";
import { formatDate, formatDateFromTimestamp } from "../../utils/dateHelper";
import LogButton from "../../components/buttons/LogButton";
import { scroll2Top } from "../../utils/dataGridHelper";
import { LoadingButton } from "@mui/lab";
// import { useRecoilState } from "recoil";
// import { groupAtom } from "../../atoms/group";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

type Props = {
  refetch: boolean;
  selectedGroupIds: string[];
};

const OfficesPageContent = ({ refetch, selectedGroupIds }: Props) => {
  const { user } = useAuth();
  const [errorMessageOpen, setErrorMessageOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [data, setData] = useState<OfficeListResponse["objects"] | []>([]);
  const [deletedDataId, setDeletedDataId] = useState<string[]>([]);
  const handleDelete = async (id: string) => {
    const res = await axiosInstance.delete<OfficeResponse | ErrorResponse>(
      OfficeURI(id)
    );
    if (res.status === 200) {
      setDeletedDataId([...deletedDataId, id]);
      setLoading(false);
    } else {
      setErrorMessage((res.data as ErrorResponse).message!);
      setErrorMessageOpen(true);
      setTimeout(() => {
        setErrorMessageOpen(false);
      }, 5000);
      setLoading(false);
    }
  };

  const columns: GridColDef[] = [
    // 設置センサー機器一覧確認ボタン
    {
      field: "officeDevicesBtn",
      headerName: "センサー機器一覧",
      sortable: false,
      filterable: false,
      width: 100,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        const office = params.row as OfficeResponse;
        return (
          <LogButton
            href={`/office-devices?id=${office.id}&name=${office.name}`}
          />
        );
      },
    },
    {
      field: "id",
      headerName: "ID",
      width: 300,
      editable: false,
      filterable: true,
      sortable: false,
      hide: true,
    },
    {
      field: "name",
      headerName: "事業所",
      width: 200,
      editable: false,
    },
    {
      field: "group",
      headerName: "グループ",
      width: 200,
      editable: false,
      valueGetter: (params) => {
        const officeDevice = params.row as OfficeResponse;
        return officeDevice.group.name;
      },
    },
    {
      field: "prefecture",
      headerName: "都道府県",
      width: 200,
      editable: false,
      filterable: false,
      sortable: false,
      valueFormatter: (params: GridValueFormatterParams) => {
        return formatPrefecture(params.value as string);
      },
    },
    {
      field: "createdAt",
      headerName: "作成日時",
      type: "dateTime",
      width: 150,
      editable: false,
      filterable: true,
      sortable: true,
      valueFormatter: (params: GridValueFormatterParams) => {
        return formatDateFromTimestamp(params.value);
      },
    },
    {
      field: "updatedAt",
      headerName: "更新日時",
      type: "datetime",
      width: 150,
      editable: false,
      filterable: true,
      sortable: true,
      valueFormatter: (params: GridValueFormatterParams) => {
        return formatDateFromTimestamp(params.value);
      },
    },
  ];

  if (user?.role === "admin") {
    columns.unshift(
      // 削除ボタン
      {
        field: "deleteBtn",
        headerName: "削除",
        sortable: false,
        filterable: false,
        width: 60,
        align: "center",
        headerAlign: "center",
        renderCell: (params) => (
          <DeleteButton id={params.id as string} callback={handleDelete} />
        ),
      }
    );
    columns.unshift(
      // 削除ボタン
      {
        field: "editBtn",
        headerName: "編集",
        sortable: false,
        filterable: false,
        width: 60,
        align: "center",
        headerAlign: "center",
        renderCell: (params) => <EditButton href={`/offices/${params.id}`} />,
      }
    );
  }

  const [pageSize, setPageSize] = useState<number>(50);
  const [page, setPage] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const handlePageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
  };
  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const fetchData = async (pageSize: number, page: number) => {
    setLoading(true);
    const res = await axiosInstance.get<OfficeListResponse | ErrorResponse>(
      OfficeListURI,
      {
        params: {
          group_id:
            selectedGroupIds.length > 0
              ? selectedGroupIds[selectedGroupIds.length - 1]
              : null,
          offset: page * pageSize,
          limit: pageSize,
          sort_by: "created_at-desc",
        },
      }
    );

    if (res.status === 200) {
      const responseData = res.data as OfficeListResponse;
      setData(responseData.objects);
      setTotal(responseData.paging.total);
      setLoading(false);
    } else {
      setErrorMessage((res.data as ErrorResponse).message!);
      setErrorMessageOpen(true);
      setTimeout(() => {
        setErrorMessageOpen(false);
      }, 5000);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!loading) {
      scroll2Top();
    }
  }, [loading]);

  // useEffect(() => {
  //   fetchData(pageSize, page);
  // }, [currentGroup, pageSize, page, refetch]);

  useEffect(() => {
    fetchData(pageSize, page);
  }, [selectedGroupIds, pageSize, page, refetch]);

  useEffect(() => {
    if (deletedDataId) {
      setData(data.filter((element) => !deletedDataId.includes(element.id)));
    }
  }, [deletedDataId]);

  return (
    <>
      <Card mb={6}>
        <Paper>
          <div style={{ height: 800, width: "100%" }}>
            <DataGrid
              rowsPerPageOptions={[50, 100]}
              rows={data}
              columns={columns}
              pageSize={pageSize}
              rowCount={total}
              page={page}
              checkboxSelection={false}
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
              components={{ Toolbar: CustomToolbar }}
              initialState={{
                sorting: {
                  sortModel: [
                    {
                      field: "createdAt",
                      sort: "desc",
                    },
                  ],
                },
              }}
              loading={loading}
              pagination
              paginationMode="server"
              onPageSizeChange={handlePageSizeChange}
              onPageChange={handlePageChange}
            />
          </div>
        </Paper>
      </Card>
      <Notification
        message={errorMessage}
        isOpen={errorMessageOpen}
        onClose={() => {
          setErrorMessageOpen(false);
        }}
      />
    </>
  );
};

const CustomizedListItemButton = styled(ListItemButton)`
  &.Mui-selected {
    color: #0072e5;
    background-color: #f0f7ff;
  }
`;

type OfficeGroupListItemProps = {
  item: any;
  selectedGroupIds: string[];
  depth: number;
  setSelectedGroupIds: (ids: string[]) => void;
};

const OfficeGroupListItem = ({
  item,
  selectedGroupIds,
  depth,
  setSelectedGroupIds,
}: OfficeGroupListItemProps) => {
  const [errorMessageOpen, setErrorMessageOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [data, setData] = useState<FetchGroupListResponse["objects"] | []>([]);
  const [loading, setLoading] = useState<boolean | undefined>(undefined);

  const fetchData = async () => {
    setLoading(true);
    const res = await axiosInstance.get<FetchGroupListResponse | ErrorResponse>(
      GroupListTopDownURI,
      {
        params: {
          offset: 0,
          limit: 100,
          parent_group_id: item?.id,
        },
      }
    );
    if (res.status === 200) {
      setData((res.data as FetchGroupListResponse).objects);
    } else {
      setErrorMessage((res.data as ErrorResponse).message!);
      setErrorMessageOpen(true);
      setTimeout(() => {
        setErrorMessageOpen(false);
      }, 5000);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (
      loading === undefined &&
      selectedGroupIds.length > depth &&
      item.id === selectedGroupIds[depth]
    ) {
      fetchData();
    }
  }, [loading, item, selectedGroupIds, depth]);

  return (
    <>
      <CustomizedListItemButton
        selected={
          selectedGroupIds.length > depth && item.id === selectedGroupIds[depth]
        }
        onClick={() => {
          let cloneIds = [...selectedGroupIds];
          if (cloneIds.includes(item.id)) {
            if (cloneIds.length === 1) cloneIds = [];
            else cloneIds.splice(depth, cloneIds.length - 1);
          } else {
            cloneIds.splice(depth, cloneIds.length);
            if (cloneIds.length === depth) {
              cloneIds.push(item.id);
            } else {
              cloneIds[depth] = item.id;
            }
          }
          setSelectedGroupIds(cloneIds);
        }}
      >
        <ListItemText primary={`${item.name}`} />
        {item.id === selectedGroupIds[depth] ? (
          <KeyboardArrowDownRounded />
        ) : (
          <KeyboardArrowRightRounded />
        )}
      </CustomizedListItemButton>
      <Collapse
        in={
          selectedGroupIds.length > depth && item.id === selectedGroupIds[depth]
        }
        timeout="auto"
        unmountOnExit
      >
        <List component="nav" style={{ paddingLeft: 10 }}>
          {selectedGroupIds.length === depth + 1 &&
            item.id === selectedGroupIds[depth] &&
            loading && (
              <div style={{ paddingLeft: 16, paddingRight: 16 }}>
                <CircularProgress size={24} />
              </div>
            )}
          {selectedGroupIds.length === depth + 1 &&
            item.id === selectedGroupIds[depth] &&
            loading === false &&
            data.length === 0 && (
              <div style={{ paddingLeft: 16, paddingRight: 16 }}>
                子グループなし
              </div>
            )}
          {data.map((item: any) => (
            <OfficeGroupListItem
              key={`filter-group-${item.id}`}
              item={item}
              selectedGroupIds={selectedGroupIds}
              depth={depth + 1}
              setSelectedGroupIds={setSelectedGroupIds}
            />
          ))}
        </List>
      </Collapse>
      <Notification
        message={errorMessage}
        isOpen={errorMessageOpen}
        onClose={() => {
          setErrorMessageOpen(false);
        }}
      />
    </>
  );
};

type OfficesSidePageContentProps = {
  selectedGroupIds: string[];
  setSelectedGroupIds: (ids: string[]) => void;
};

const OfficesSidePageContent = ({
  selectedGroupIds,
  setSelectedGroupIds,
}: OfficesSidePageContentProps) => {
  const { signOut } = useAuth();
  const [errorMessageOpen, setErrorMessageOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [data, setData] = useState<FetchGroupListResponse["objects"] | []>([]);
  const [loading, setLoading] = useState<boolean | undefined>(false);
  const [pageSize, setPageSize] = useState<number>(50);
  const [page, setPage] = useState<number>(0);
  const [filterGroupName, setFilterGroupName] = useState("");

  const fetchData = async () => {
    setLoading(true);
    const res = await axiosInstance.get<FetchGroupListResponse | ErrorResponse>(
      GroupListTopDownURI,
      {
        params: {
          offset: page * pageSize,
          limit: pageSize,
          keyword: filterGroupName,
        },
      }
    );
    if (res.status === 200) {
      setData((res.data as FetchGroupListResponse).objects);
    } else if (res.status === 422) {
      signOut();
    } else {
      setErrorMessage((res.data as ErrorResponse).message!);
      setErrorMessageOpen(true);
      setTimeout(() => {
        setErrorMessageOpen(false);
      }, 5000);
    }
    setLoading(false);
  };

  let filterTimeout: NodeJS.Timeout | undefined = undefined;
  useEffect(() => {
    if (filterTimeout) clearTimeout(filterTimeout);
    filterTimeout = setTimeout(() => {
      fetchData();
    });
  }, [filterGroupName, pageSize, page]);

  return (
    <>
      <Card mb={6}>
        <Paper>
          <Grid
            container
            alignItems="start"
            style={{ height: 800, overflow: "scroll" }}
          >
            <Grid item md={12} alignItems="flex-start">
              <FormControl fullWidth style={{ padding: 6 }}>
                <TextField
                  id="name"
                  label="グループ名"
                  placeholder="グループ名で検索"
                  variant="outlined"
                  value={filterGroupName}
                  fullWidth
                  onChange={(e) => {
                    if (e.target.value) {
                      setFilterGroupName(e.target?.value.toString());
                    } else {
                      setFilterGroupName("");
                    }
                  }}
                  size="small"
                />
              </FormControl>
              <List component="nav">
                {loading && (
                  <div
                    style={{
                      paddingLeft: 16,
                      paddingRight: 16,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress size={32} />
                  </div>
                )}
                {data.map((item) => (
                  <OfficeGroupListItem
                    key={`filter-group-${item.id}`}
                    item={item}
                    selectedGroupIds={selectedGroupIds}
                    depth={0}
                    setSelectedGroupIds={setSelectedGroupIds}
                  />
                ))}
              </List>
            </Grid>
          </Grid>
        </Paper>
      </Card>
      <Notification
        message={errorMessage}
        isOpen={errorMessageOpen}
        onClose={() => {
          setErrorMessageOpen(false);
        }}
      />
    </>
  );
};

const OfficesPage = () => {
  const { user } = useAuth();
  const [refetch, setRefetch] = useState(false);
  const [selectedGroupIds, setSelectedGroupIds] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [notification, setNotification] = useState<NotificationProps>();

  const changeUploadFile = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { files } = event.target;

    if (files && files.length > 0) {
      const data = new FormData();
      data.append("csv_file", files[0]);
      const headers = { "content-type": "multipart/form-data" };

      const res = await axiosInstance.post<OfficesUploadCsvResponse>(
        OfficesUploadCsvURI,
        data,
        { headers }
      );
      if (res.status === 200) {
        setRefetch(true);
      }
    }

    event.target.value = "";
  };

  const downloadCsvTemplateFile = () => {
    setLoading(true);
    axiosInstance.post(OfficesGenerateCsvURI, {}).then((response) => {
      setLoading(false);
      if (response.status === 200) {
        setNotification({
          message: "CSVダウンロードのリクエストを送信しました",
          isOpen: true,
          type: "success",
        });
      } else {
        setNotification({
          message: "CSVダウンロードのリクエストを送信に失敗しました",
          isOpen: true,
          type: "error",
        });
      }
    });
  };

  useEffect(() => {
    setRefetch(false);
  }, []);

  return (
    <React.Fragment>
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            事業所一覧
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              ダッシュボード
            </Link>
            <Typography>事業所一覧</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Grid container>
            {user && ["admin"].includes(user.role) && (
              <Grid item pl={2}>
                <Button
                  component={NavLink}
                  to="/offices/new"
                  variant="contained"
                  color="primary"
                >
                  <AddIcon />
                  新規追加
                </Button>
              </Grid>
            )}

            {user?.role === "admin" && (
              <Grid item pl={2}>
                <Button variant="contained" component="label" color="primary">
                  <UploadIcon />
                  CSVアップロード
                  <input type="file" hidden onChange={changeUploadFile} />
                </Button>
              </Grid>
            )}

            {user?.role === "admin" && (
              <Grid item pl={2}>
                <LoadingButton
                  variant="contained"
                  component="label"
                  color="primary"
                  onClick={downloadCsvTemplateFile}
                  loading={loading}
                >
                  <DownloadIcon />
                  CSV一括ダウンロード
                </LoadingButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container>
        <Grid item md={2}>
          <OfficesSidePageContent
            selectedGroupIds={selectedGroupIds}
            setSelectedGroupIds={setSelectedGroupIds}
          />
        </Grid>
        <Grid item md={10} pl={8}>
          <OfficesPageContent
            selectedGroupIds={selectedGroupIds}
            refetch={refetch}
          />
        </Grid>
      </Grid>

      {notification && (
        <Notification
          message={notification.message || ""}
          type={notification.type}
          isOpen={notification.isOpen}
          onClose={() =>
            setNotification({
              message: "",
              isOpen: false,
              type: "info",
            })
          }
        />
      )}
    </React.Fragment>
  );
};

export default OfficesPage;
