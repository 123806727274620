import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { useLocation } from "react-router-dom";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Grid,
  Chip as MuiChip,
  CardContent,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridSortItem,
  GridSortModel,
  GridValueFormatterParams,
  jaJP,
  getGridNumericColumnOperators,
} from "@mui/x-data-grid";
import { axiosInstance } from "../../../utils/axios";
import {
  OfficeDeviceListResponse,
  OfficeDeviceListURI,
  OfficeDeviceResponse,
} from "../../../types/api/officeDevice";
import Notification from "../../../components/Notification";
import { ErrorResponse } from "../../../types/api";
import useAuth from "../../../hooks/useAuth";
import { formatDateFromTimestamp } from "../../../utils/dateHelper";
import { InitialMessageData, MessageType } from "../../../types/message";
import { officeAtom } from "../../../atoms/office";
import { useRecoilState } from "recoil";
import { groupAtom } from "../../../atoms/group";
import { ObjectIdName } from "../../../types/api/idNameList";
import {
  ArrayGroup,
  FetchGroupListResponse,
  GroupListURI,
} from "../../../types/api/group";
import {
  ArrayOffice,
  OfficeListResponse,
  OfficeListURI,
} from "../../../types/api/office";
import { spacing, SpacingProps } from "@mui/system";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import { getDeviceSensorStatus } from "../../../utils/deviceHelper";
import CustomMarker from "../../components/CustomMarker";

const Card = styled(MuiCard)(spacing);
const Paper = styled(MuiPaper)(spacing);

const GoogleMapReactWrapper = styled.div`
  height: 300px;
  width: 100%;
  overflow: hidden;
`;
interface ChipProps extends SpacingProps {
  component?: React.ElementType;
  href?: string;
  icon?: Element | null;
}
const Chip = styled(MuiChip)<ChipProps>(spacing);

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
    width: 300,
    hide: true,
    sortable: false,
    filterable: false,
  },
  {
    field: "macAddress",
    headerName: "MACアドレス",
    width: 300,
    hide: true,
    sortable: true,
    filterable: true,
    filterOperators: getGridNumericColumnOperators().filter(
      (operator) => operator.value === "include"
    ),
  },
  {
    field: "group",
    headerName: "グループ",
    width: 200,
    hide: true,
    editable: false,
    sortable: false,
    filterable: false,
    valueGetter: (params) => {
      const officeDevice = params.row as OfficeDeviceResponse;
      return officeDevice.office.group.name;
    },
  },
  {
    field: "officeName",
    headerName: "事業所",
    width: 200,
    editable: false,
    sortable: true,
    filterable: false,
    valueGetter(params) {
      const officeDevice = params.row as OfficeDeviceResponse;
      return officeDevice.office.name;
    },
  },
  {
    field: "placeName",
    headerName: "設置場所",
    width: 200,
    hide: true,
    editable: false,
    sortable: false,
    filterable: false,
    valueGetter(params) {
      const officeDevice = params.row as OfficeDeviceResponse;
      return officeDevice.placeName;
    },
  },
  {
    field: "co2Value",
    headerName: "CO2",
    width: 100,
    editable: false,
    sortable: false,
    filterable: false,
    valueGetter(params) {
      const officeDevice = params.row as OfficeDeviceResponse;
      return `${officeDevice.latestDeviceLog?.co2Value || ""} PPM`;
    },
  },
  {
    field: "pm25Value",
    headerName: "PM2.5",
    width: 100,
    editable: false,
    sortable: false,
    filterable: false,
    valueGetter(params) {
      const officeDevice = params.row as OfficeDeviceResponse;
      return `${officeDevice.latestDeviceLog?.pm25Value || ""} μg/m3`;
    },
  },
  {
    field: "tvocValue",
    headerName: "TVOC",
    width: 100,
    editable: false,
    sortable: false,
    filterable: false,
    valueGetter(params) {
      const officeDevice = params.row as OfficeDeviceResponse;
      return `${officeDevice.latestDeviceLog?.tvocValue || ""} ppb`;
    },
  },
  {
    field: "temperatureValue",
    headerName: "温度",
    width: 100,
    editable: false,
    sortable: false,
    filterable: false,
    valueGetter(params) {
      const officeDevice = params.row as OfficeDeviceResponse;
      return `${officeDevice.latestDeviceLog?.temperatureValue || ""} ℃`;
    },
  },
  {
    field: "humidityValue",
    headerName: "湿度",
    width: 100,
    editable: false,
    sortable: false,
    filterable: false,
    valueGetter(params) {
      const officeDevice = params.row as OfficeDeviceResponse;
      return `${officeDevice.latestDeviceLog?.humidityValue || ""} %`;
    },
  },
  {
    field: "status",
    headerName: "センサー状態",
    width: 150,
    editable: false,
    sortable: false,
    filterable: false,
    valueGetter(params) {
      const officeDevice = params.row as OfficeDeviceResponse;
      const deviceSensorStatus = getDeviceSensorStatus(officeDevice);
      return deviceSensorStatus ? "正常" : "停止";
    },
    renderCell: (params) => {
      return (
        <Chip
          label={params.value}
          color={params.value === "停止" ? "error" : "info"}
          size="small"
        />
      );
    },
  },
  {
    field: "setupDate",
    headerName: "設置日",
    width: 200,
    editable: false,
    sortable: false,
    filterable: false,
    valueGetter(params) {
      const officeDevice = params.row as OfficeDeviceResponse;
      return officeDevice.setupDate;
    },
  },
  {
    field: "createdAt",
    headerName: "作成日時",
    type: "dateTime",
    width: 150,
    hide: true,
    editable: false,
    sortable: true,
    filterable: false,
    valueFormatter: (params: GridValueFormatterParams) => {
      return formatDateFromTimestamp(params.value);
    },
  },
  {
    field: "updatedAt",
    headerName: "更新日時",
    type: "datetime",
    width: 150,
    hide: true,
    editable: false,
    sortable: true,
    filterable: false,
    valueFormatter: (params: GridValueFormatterParams) => {
      return formatDateFromTimestamp(params.value);
    },
  },
];

type Props = {
  data: OfficeDeviceListResponse["objects"] | [];
  page: number;
  pageSize: number;
  total: number;
  loading: boolean;
  handlePageSizeChange: (pageSize: number) => void;
  handlePageChange: (page: number) => void;
};

const OfficeDevicesPageContent = ({
  data,
  page,
  pageSize,
  total,
  loading,
  handlePageSizeChange,
  handlePageChange,
}: Props) => {
  const { user } = useAuth();
  const [message, setMessage] = useState<MessageType>(InitialMessageData);
  const [gridSortItem, setGridSortItem] = useState<GridSortItem>({
    field: "createdAt",
    sort: "desc",
  });

  return (
    <>
      <Card>
        <Paper>
          <div style={{ height: 300, width: "100%" }}>
            {user && (
              <DataGrid
                rowsPerPageOptions={[50, 100]}
                rows={data}
                columns={columns}
                pageSize={pageSize}
                rowCount={total}
                page={page}
                checkboxSelection={false}
                localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
                components={{ Toolbar: undefined }}
                initialState={{
                  sorting: {
                    sortModel: [gridSortItem],
                  },
                }}
                loading={loading}
                pagination
                paginationMode="server"
                onPageSizeChange={handlePageSizeChange}
                onPageChange={handlePageChange}
                sortingMode="server"
                sortingOrder={["desc", "asc"]}
              />
            )}
          </div>
        </Paper>
      </Card>
      <Notification
        message={message.body}
        isOpen={message.isOpen}
        type={message.type}
        onClose={() => {
          setMessage({
            ...message,
            isOpen: false,
          });
        }}
      />
    </>
  );
};

const Offices = () => {
  const [currentOffice, setCurrentOffice] = useRecoilState(officeAtom);
  const [currentGroup, setCurrentGroup] = useRecoilState(groupAtom);

  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const defaultOfficeId = query.get("id");
  const defaultOfficeName = query.get("name");
  const defaultOfficeIdName =
    defaultOfficeId && defaultOfficeName
      ? { id: defaultOfficeId, name: defaultOfficeName }
      : undefined;

  const { user } = useAuth();
  const [groupList, setGroupList] = useState<ArrayGroup>([]);
  // const [groupIdNameList, setGroupIdNameList] = useState<ArrayIdName>([]);
  const [selectedGroupIdName, setSelectedGroupIdName] =
    useState<ObjectIdName>();
  const [officeList, setOfficeList] = useState<ArrayOffice>([]);
  const [selectedOfficeIdName, setSelectedOfficeIdName] = useState<
    ObjectIdName | undefined
  >(defaultOfficeIdName);
  const [data, setData] = useState<OfficeDeviceListResponse["objects"] | []>(
    []
  );
  const [gridSortItem, setGridSortItem] = useState<GridSortItem>({
    field: "createdAt",
    sort: "desc",
  });

  const [pageSize, setPageSize] = useState<number>(50);
  const [page, setPage] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchGroupIdNameList = async () => {
    // const res = await axiosInstance.get<FetchGroupIdNameListResponse | ErrorResponse>(GroupIdNameListURI, {
    //   params: {
    //     sort_by: "created_at-desc",
    //   }
    // });
    // if (res.status === 200) {
    //   setGroupIdNameList((res.data as FetchGroupIdNameListResponse).objects);
    // }

    const res = await axiosInstance.get<FetchGroupListResponse | ErrorResponse>(
      GroupListURI,
      {
        params: {
          offset: 0,
          limit: 5000,
          sort_by: "created_at-desc",
        },
      }
    );
    if (res.status === 200) {
      setGroupList((res.data as FetchGroupListResponse).objects);
    }
  };

  const fetchOfficeIdNameList = async (groupId: string | undefined) => {
    const res = await axiosInstance.get<OfficeListResponse | ErrorResponse>(
      OfficeListURI,
      {
        params: {
          offset: 0,
          limit: 5000,
          sort_by: "created_at-desc",
          group_id: groupId,
        },
      }
    );
    if (res.status === 200) {
      setOfficeList((res.data as OfficeListResponse).objects);
    }
  };

  useEffect(() => {
    fetchGroupIdNameList();
  }, []);

  useEffect(() => {
    fetchOfficeIdNameList(selectedGroupIdName?.id);
  }, [selectedGroupIdName]);

  const containerStyle = {
    height: "100%",
    width: "100%",
  };

  const fetchData = async (
    groupId: string | undefined,
    officeId: string | undefined,
    gridSortItem: GridSortItem,
    macAddress: string | undefined,
    pageSize: number,
    page: number
  ) => {
    setLoading(true);
    const res = await axiosInstance.get<
      OfficeDeviceListResponse | ErrorResponse
    >(OfficeDeviceListURI, {
      params: {
        group_id: groupId,
        office_id: officeId,
        offset: page * pageSize,
        limit: pageSize,
        sort_by: "created_at-desc",
        keyword: macAddress,
      },
    });

    if (res.status === 200) {
      const responseData = res.data as OfficeDeviceListResponse;
      setData(responseData.objects);
      setTotal(responseData.paging.total);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user?.role === "admin") {
      fetchData(
        selectedGroupIdName?.id,
        selectedOfficeIdName?.id,
        gridSortItem,
        undefined,
        pageSize,
        page
      );
    } else if (user?.role === "manager") {
      !selectedOfficeIdName &&
        currentGroup.id.length > 0 &&
        fetchData(
          currentGroup.id,
          undefined,
          gridSortItem,
          undefined,
          pageSize,
          page
        );
      selectedOfficeIdName &&
        fetchData(
          undefined,
          selectedOfficeIdName.id,
          gridSortItem,
          undefined,
          pageSize,
          page
        );
    } else if (user?.role === "staff") {
      currentOffice.id.length > 0 &&
        fetchData(
          undefined,
          currentOffice.id,
          gridSortItem,
          undefined,
          pageSize,
          page
        );
    }
  }, [
    user,
    currentGroup,
    currentOffice,
    gridSortItem,
    pageSize,
    page,
    selectedGroupIdName,
    selectedOfficeIdName,
  ]);

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const onLoad = useCallback((map: google.maps.Map) => setMap(map), []);

  useEffect(() => {
    if (map && data && data.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      data.forEach((item) => {
        const latLngSplit = item.office.latLng?.split(",");
        bounds.extend({
          lat: parseFloat(
            latLngSplit && latLngSplit.length > 0 ? latLngSplit[0] : ""
          ),
          lng: parseFloat(
            latLngSplit && latLngSplit.length > 0 ? latLngSplit[1] : ""
          ),
        });
      });
      map.fitBounds(bounds);
    }
  }, [map, data]);

  const handlePageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  return (
    <React.Fragment>
      {data && (
        <Grid container spacing={6}>
          <Grid item xs={12} lg={6}>
            <Card mb={1}>
              <CardContent>
                <Typography variant="h6" mb="16px" fontWeight="bold">
                  センサー設置機器一覧
                </Typography>
                <GoogleMapReactWrapper>
                  <LoadScript
                    googleMapsApiKey={
                      process.env.REACT_APP_GOOGLE_MAP_API_KEY || ""
                    }
                  >
                    <GoogleMap
                      onLoad={onLoad}
                      mapContainerStyle={containerStyle}
                      options={{ streetViewControl: false, zoomControl: true }}
                      zoom={15}
                    >
                      {data?.map((officeDevice, i) => {
                        const latLngSplit =
                          officeDevice.office.latLng?.split(",");
                        return (
                          <CustomMarker
                            key={officeDevice.id}
                            id={officeDevice.id}
                            lat={parseFloat(
                              latLngSplit && latLngSplit.length > 0
                                ? latLngSplit[0]
                                : ""
                            )}
                            lng={parseFloat(
                              latLngSplit && latLngSplit.length > 0
                                ? latLngSplit[1]
                                : ""
                            )}
                          />
                        );
                      })}
                    </GoogleMap>
                  </LoadScript>
                </GoogleMapReactWrapper>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Card mb={1}>
              <CardContent>
                <Typography variant="h6" mb="16px" fontWeight="bold">
                  最近追加された事業所一覧
                </Typography>
                <OfficeDevicesPageContent
                  data={data}
                  page={page}
                  pageSize={pageSize}
                  total={total}
                  loading={loading}
                  handlePageSizeChange={handlePageSizeChange}
                  handlePageChange={handlePageChange}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default Offices;
