import {
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { Fragment } from "react";
import { useRecoilState } from "recoil";
import { officeAtom, officesAtom } from "../../atoms/office";

function NavbarOfficesDropdown() {
  const [currentOffice, setCurrentOffice] = useRecoilState(officeAtom);
  const [currentOffices, setCurrentOffices] = useRecoilState(officesAtom);

  const handleChange = (
    event: SelectChangeEvent<String | null>,
    child: React.ReactNode
  ) => {
    const targetId = event.target.value as String;
    const targetOffice = currentOffices.find(
      (office) => office.id === targetId
    );
    if (targetOffice) {
      setCurrentOffice(targetOffice);
    }
  };

  if (currentOffice) {
    return (
      <Fragment>
        <FormControl fullWidth style={{ minWidth: "150px" }}>
          <InputLabel id="office-select-label">事業所</InputLabel>
          <Select
            labelId="office-select-label"
            id="office-select"
            value={currentOffice.id}
            label="Office"
            onChange={handleChange}
            size="small"
          >
            {currentOffices.map((office, i) => {
              return (
                <MenuItem key={office.id} value={office.id}>
                  {office.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Fragment>
    );
  } else {
    return <></>;
  }
}

export default NavbarOfficesDropdown;
