import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import {
  ComposedChart,
  YAxis,
  XAxis,
  CartesianGrid,
  Legend,
  Line,
  ResponsiveContainer,
} from "recharts";
import { MinMaxDataType } from "../pages/pages/Reports";
import { formatDate, formatDateFromTimestamp } from "../utils/dateHelper";

export type ChartProps = {
  date: Date;
  groupedData: any;
  selectedDate: Date;
  minMaxData: MinMaxDataType;
};

export const FirstChart = ({
  date,
  groupedData,
  selectedDate,
  minMaxData,
}: ChartProps) => {
  const formattedDate = formatDate(date, "yyyy-MM-dd");
  const theDateData =
    date.getMonth() === selectedDate.getMonth()
      ? groupedData[formattedDate]
      : [];
  const isSunday = date.getDay() === 0;
  const isSaturday = date.getDay() === 6;
  const filledColor = date.getDay() % 2 !== 0 ? "#F7F9FC" : "";
  const DateLegend = () => (
    <div
      style={{
        width: 10,
        left: isSunday ? 35 : 0,
      }}
    >
      {date.getDate()}
    </div>
  );
  const HEIGHT = 140;
  const AXIS_WIDTH = 32;
  const w = isSunday || isSaturday ? HEIGHT + AXIS_WIDTH : HEIGHT;
  const verticalPoints = isSunday
    ? [0, HEIGHT / 4, (HEIGHT / 4) * 2, (HEIGHT / 4) * 3].map((v) => v + 32)
    : [0, HEIGHT / 4, (HEIGHT / 4) * 2, (HEIGHT / 4) * 3];

  return (
    <Box width={w} height={HEIGHT}>
      <ResponsiveContainer width={w} height={HEIGHT}>
        <ComposedChart
          data={theDateData}
          margin={{ top: 1, right: 0, left: 0, bottom: 0 }}
        >
          <CartesianGrid
            fill={filledColor}
            strokeDasharray="4"
            horizontalPoints={[0, HEIGHT]}
            verticalPoints={verticalPoints}
          />
          <YAxis
            yAxisId={1}
            tick={isSunday ? { fontSize: 8 } : false}
            width={isSunday ? AXIS_WIDTH : 0}
            domain={[400, minMaxData.co2Max]}
            ticks={[
              400,
              Math.floor((minMaxData.co2Max - 400) / 3 + 400),
              Math.floor(((minMaxData.co2Max - 400) * 2) / 3 + 400),
              minMaxData.co2Max,
            ]}
            tickCount={5}
          />
          <YAxis
            yAxisId={2}
            tick={isSaturday ? { fontSize: 8 } : false}
            width={isSaturday ? AXIS_WIDTH : 0}
            domain={[minMaxData.pm25Min, minMaxData.pm25Max]}
            ticks={[0, 5, 10, Math.max(15, minMaxData.pm25Max)]}
            orientation="right"
          />
          <XAxis
            tick={{ fontSize: 9 }}
            dataKey="timestamp"
            tickFormatter={(tickItem) =>
              formatDateFromTimestamp(tickItem, "HH:mm")
            }
            interval={2}
            hide={true}
          />
          <Legend
            content={<DateLegend />}
            align="left"
            verticalAlign="middle"
            height={0}
            wrapperStyle={{
              position: "absolute",
              top: 10,
              left: isSunday ? 35 : 4,
            }}
          />
          <Line
            dataKey="co2"
            stroke="#ff9800"
            yAxisId={1}
            dot={false}
            isAnimationActive={false}
            width={w}
            height={HEIGHT}
          />
          <Line
            dataKey="pm25"
            stroke="#4caf50"
            yAxisId={2}
            dot={false}
            isAnimationActive={false}
            width={w}
            height={HEIGHT}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  );
};
