export const UriType = {
  MAIN: "main",
  SUB: "sub",
} as const;
export type UriType = typeof UriType[keyof typeof UriType];
export const AllUriType = Object.values(UriType);

export const getBffUri = (uriType: UriType = UriType.MAIN) => {
  const uri =
    uriType === UriType.MAIN
      ? process.env.REACT_APP_API_URI
      : process.env.REACT_APP_SUB_API_URI;
  return uri;
};

export const role2path = (role: string) => {
  return role === "admin" ? "/admin" : "/app";
};
