import { CamelCasedPropertiesDeep } from "type-fest";
import { components, operations } from "../../schema";

export const AlertSettingListURI = "/admin/alert-setting/list";
export const AlertSettingNewURI = "/admin/alert-setting/new";
export const AlertSettingDeleteURI = (id: string) =>
  `/admin/alert-setting/${id}`;
export const AlertSettingEditURI = (id: string) => `/admin/alert-setting/${id}`;
export const AlertSettingURI = (id: string) => `/admin/alert-setting/${id}`;
export const AlertSettingUploadCsvURI = "/admin/alert-setting/csv";
export const AlertSettingDownloadCsvURI = "/admin/alert-setting/csv";

export type FetchAlertSettingListParams =
  operations["admin__get_alert_setting_list"]["parameters"]["query"];
export type AlertSettingNewParams =
  components["schemas"]["RequestBody_AddNewAlertSetting"];
export type AlertSettingEditParams =
  components["schemas"]["RequestBody_UpdateAlertSetting"];

export type FetchAlertSettingListResponse = CamelCasedPropertiesDeep<
  components["schemas"]["Response_AlertSettingList"]
>;
export type AlertSettingDeleteResponse = CamelCasedPropertiesDeep<
  components["schemas"]["Response_AlertSetting"]
>;
export type AlertSettingNewResponse = CamelCasedPropertiesDeep<
  components["schemas"]["Response_AlertSetting"]
>;
export type AlertSettingEditResponse = CamelCasedPropertiesDeep<
  components["schemas"]["Response_AlertSetting"]
>;
export type AlertSettingResponse = CamelCasedPropertiesDeep<
  components["schemas"]["Response_AlertSetting"]
>;
export type AlertSettingUploadCsvResponse = CamelCasedPropertiesDeep<
  components["schemas"]["Response_UploadCSV"]
>;
