import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";

import {
  Autocomplete,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  Link,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import {
  DeviceCategoryListResponse,
  DeviceCategoryListURI,
  DeviceNewResponse,
  DeviceNewURI,
  ErrorResponse,
} from "../../types/api";
import { axiosInstance } from "../../utils/axios";
import Notification from "../../components/Notification";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)<{ m?: number }>`
  min-width: 240px;
  width: 100%;
`;

interface ButtonProps extends SpacingProps {
  component?: string;
}
const Button = styled(MuiButton)<ButtonProps>(spacing);

function DeviceNewPageContent() {
  const [errorMessageOpen, setErrorMessageOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [deviceCategoryList, setDeviceCategoryList] = useState<
    DeviceCategoryListResponse["objects"]
  >([]);
  const [selectedDeviceCategoryId, setSelectedDeviceCategoryId] =
    useState<string>();
  const [nameValue, setNameValue] = React.useState<String | null>(null);
  const [makerValue, setMakerValue] = React.useState<String | null>(null);
  const [maintenanceFrequencyValue, setMaintenanceFrequencyValue] =
    React.useState<number | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const deviceCategoryListResponse =
        await axiosInstance.get<DeviceCategoryListResponse>(
          DeviceCategoryListURI,
          {}
        );
      setDeviceCategoryList(deviceCategoryListResponse.data.objects);
    };

    fetchData();
  }, []);

  const submit = async () => {
    const res = await axiosInstance.post<DeviceNewResponse | ErrorResponse>(
      DeviceNewURI,
      {
        device_category_id: selectedDeviceCategoryId,
        name: nameValue,
        maker: makerValue,
        maintenance_frequency: maintenanceFrequencyValue,
      }
    );

    if (res.status === 200) {
      navigate("/devices");
    } else {
      setErrorMessage((res.data as ErrorResponse).message!);
      setErrorMessageOpen(true);
      setTimeout(() => {
        setErrorMessageOpen(false);
      }, 5000);
    }
  };

  return (
    <>
      <Card mb={6}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item md={3}>
              <FormControl my={2}>
                <Autocomplete
                  disablePortal
                  id="device-category-list-select"
                  fullWidth
                  renderInput={(params) => (
                    <TextField {...params} label="デバイスカテゴリ" required />
                  )}
                  options={deviceCategoryList.map((item) => {
                    return { id: item.id, label: item.name };
                  })}
                  onChange={(event, target) => {
                    if (target) {
                      setSelectedDeviceCategoryId(target.id);
                    }
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <FormControl fullWidth my={2} variant="outlined">
            <TextField
              id="name"
              label="デバイス名"
              required
              variant="outlined"
              defaultValue={nameValue}
              fullWidth
              my={2}
              onChange={(e) => {
                setNameValue(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth my={2} variant="outlined">
            <TextField
              id="maker"
              label="メーカー"
              required
              variant="outlined"
              defaultValue={makerValue}
              fullWidth
              my={2}
              onChange={(e) => {
                setMakerValue(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth my={2} variant="outlined">
            <TextField
              id="maintenance_frequency"
              label="メンテナンス頻度"
              variant="outlined"
              inputMode="numeric"
              defaultValue={maintenanceFrequencyValue}
              fullWidth
              my={2}
              onChange={(e) => {
                setMaintenanceFrequencyValue(Number(e.target.value));
              }}
            />
          </FormControl>

          <Button variant="contained" color="primary" onClick={() => submit()}>
            作成する
          </Button>
        </CardContent>
      </Card>
      <Notification
        message={errorMessage}
        isOpen={errorMessageOpen}
        onClose={() => {
          setErrorMessageOpen(false);
        }}
      />
    </>
  );
}

function DeviceNewPage() {
  return (
    <React.Fragment>
      <Typography variant="h3" gutterBottom display="inline">
        新規デバイス追加
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          ダッシュボード
        </Link>
        <Link component={NavLink} to="/devices">
          デバイス一覧
        </Link>
        <Typography>新規デバイス追加</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <DeviceNewPageContent />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default DeviceNewPage;
