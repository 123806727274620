import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";

import {
  Autocomplete,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  FormControlLabel,
  Grid,
  Link,
  Stack,
  Switch,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import { ErrorResponse } from "../../types/api";
import { axiosInstance } from "../../utils/axios";
import Notification from "../../components/Notification";
import {
  AlertSettingNewParams,
  AlertSettingNewResponse,
  AlertSettingNewURI,
} from "../../types/api/alertSetting";
import { useRecoilState } from "recoil";
import { groupAtom } from "../../atoms/group";
import { officeAtom } from "../../atoms/office";
import useAuth from "../../hooks/useAuth";
import { OfficeListResponse, OfficeListURI } from "../../types/api/office";
import { TimePicker } from "@mui/lab";
import { formatDate, getTimeObject } from "../../utils/dateHelper";
import { AntSwitch } from "../components/AntSwitch";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)<{ m?: number }>`
  width: 100%;
`;

interface ButtonProps extends SpacingProps {
  component?: string;
}
const Button = styled(MuiButton)<ButtonProps>(spacing);

const AlertSettingsNewPageContent = () => {
  const { user } = useAuth();
  const [currentGroup, setCurrentGroup] = useRecoilState(groupAtom);
  const [currentOffice, setCurrentOffice] = useRecoilState(officeAtom);
  const [officeList, setOfficeList] = useState<OfficeListResponse["objects"]>(
    []
  );
  const [selectedOffice, setSelectedOffice] =
    useState<{ id: string; label: string }>();

  type MessageType = {
    body: string;
    isOpen: boolean;
    type?: "success" | "info" | "warning" | "error";
  };
  type ParamsType = {
    title: string;
    message: string;
    enabled: boolean;
    enabled_manager_notification: boolean;
    pm25LowerLimit: number | null;
    pm25UpperLimit: number | null;
    co2LowerLimit: number | null;
    co2UpperLimit: number | null;
    temperatureLowerLimit: number | null;
    temperatureUpperLimit: number | null;
    humidityLowerLimit: number | null;
    humidityUpperLimit: number | null;
    startTime: string | null;
    endTime: string | null;
  };
  const initialParams = {
    title: "",
    message: "",
    enabled: false,
    enabled_manager_notification: false,
    pm25LowerLimit: null,
    pm25UpperLimit: null,
    co2LowerLimit: null,
    co2UpperLimit: null,
    temperatureLowerLimit: null,
    temperatureUpperLimit: null,
    humidityLowerLimit: null,
    humidityUpperLimit: null,
    startTime: null,
    endTime: null,
  };

  const [message, setMessage] = useState<MessageType>({
    body: "",
    isOpen: false,
    type: "error",
  });
  const [params, setParams] = useState<ParamsType>(initialParams);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const submit = async (officeId: string | undefined) => {
    console.log(params.enabled_manager_notification);
    const res = await axiosInstance.post<
      AlertSettingNewResponse | ErrorResponse
    >(AlertSettingNewURI, {
      office_id: officeId,
      title: params.title,
      message: params.message,
      enabled: params.enabled,
      enabled_manager_notification: params.enabled_manager_notification,
      pm25_lower_limit: params.pm25LowerLimit,
      pm25_upper_limit: params.pm25UpperLimit,
      co2_lower_limit: params.co2LowerLimit,
      co2_upper_limit: params.co2UpperLimit,
      temperature_lower_limit: params.temperatureLowerLimit,
      temperature_upper_limit: params.temperatureUpperLimit,
      humidity_lower_limit: params.humidityLowerLimit,
      humidity_upper_limit: params.humidityUpperLimit,
      start_time: params.startTime,
      end_time: params.endTime,
    } as AlertSettingNewParams);

    if (res.status === 200) {
      navigate("/alert-setting");
    } else {
      setMessage({
        body: (res.data as ErrorResponse).message!,
        isOpen: true,
        type: "error",
      });
      setTimeout(() => {
        setMessage({
          ...message,
          isOpen: false,
        });
      }, 5000);
    }
  };

  const fetchOfficeList = async (groupId: string | undefined) => {
    setIsLoading(true);
    const res = await axiosInstance.get<OfficeListResponse | ErrorResponse>(
      OfficeListURI,
      {
        params: {
          group_id: groupId,
          offset: 0,
          limit: 5000,
          sort_by: "created_at-desc",
        },
      }
    );
    if (res.status === 200) {
      setOfficeList((res.data as OfficeListResponse).objects);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      setMessage({
        body: (res.data as ErrorResponse).message!,
        isOpen: true,
        type: "error",
      });
      setTimeout(() => {
        setMessage({
          ...message,
          isOpen: false,
        });
      }, 5000);
    }
  };

  useEffect(() => {
    if (currentGroup && currentGroup.id.length > 0) {
      fetchOfficeList(currentGroup.id);
    }
  }, [currentGroup]);

  useEffect(() => {
    if (user?.role === "staff") {
      setSelectedOffice({
        id: currentOffice.id,
        label: currentOffice.name,
      });
    }
  }, [currentOffice]);

  useEffect(() => {
    if (officeList && officeList.length > 0) {
      const defaultOffice = officeList[0];
      setSelectedOffice({
        id: defaultOffice.id,
        label: defaultOffice.name,
      });
    }
  }, [officeList]);

  useEffect(() => {
    if (user?.role === "admin") {
      fetchOfficeList(undefined);
    }
  }, []);

  return (
    <>
      <Card mb={6} style={{ maxWidth: 960 }}>
        {!isLoading && (
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <FormControl fullWidth my={2} variant="outlined">
                  <TextField
                    id="title"
                    label="タイトル"
                    variant="outlined"
                    inputMode="text"
                    defaultValue={params.title}
                    value={params.title}
                    fullWidth
                    onChange={(e) => {
                      setParams({
                        ...params,
                        title: e.target.value,
                      });
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                {selectedOffice && officeList && (
                  <FormControl my={2}>
                    <Autocomplete
                      disablePortal
                      id="office-list-select"
                      fullWidth
                      defaultValue={{
                        id: selectedOffice.id,
                        label: selectedOffice.label,
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="事業所" required />
                      )}
                      options={officeList.map((item) => {
                        return { id: item.id, label: item.name };
                      })}
                      onChange={(event, target) => {
                        if (target) {
                          setSelectedOffice({
                            id: target.id,
                            label: target.label,
                          });
                        }
                      }}
                    />
                  </FormControl>
                )}
              </Grid>
            </Grid>
            {/**
            <Grid container spacing={6}>
              <Grid item md={12}>
                <FormControl fullWidth my={2} variant="outlined">
                  <TextField
                    id="message"
                    label="メッセージ"
                    variant="outlined"
                    inputMode="numeric"
                    defaultValue={params.message}
                    value={params.message}
                    fullWidth
                    multiline
                    rows={10}
                    onChange={(e) => {
                      setParams({
                        ...params,
                        message: e.target.value,
                      });
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            */}
            <Grid container spacing={2}>
              <Grid item md={3} my={2}>
                <FormControl variant="outlined">
                  <TextField
                    id="pm25UpperLimit"
                    label="PM2.5 以上"
                    variant="outlined"
                    inputMode="numeric"
                    defaultValue={params.pm25UpperLimit}
                    value={params.pm25UpperLimit}
                    onChange={(e) => {
                      setParams({
                        ...params,
                        pm25UpperLimit: Number(e.target.value) || null,
                      });
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item md={3} my={2}>
                <FormControl variant="outlined">
                  <TextField
                    id="pm25LowerLimit"
                    label="PM2.5 以下"
                    variant="outlined"
                    inputMode="numeric"
                    defaultValue={params.pm25LowerLimit}
                    value={params.pm25LowerLimit}
                    onChange={(e) => {
                      setParams({
                        ...params,
                        pm25LowerLimit: Number(e.target.value) || null,
                      });
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item md={3} my={2}>
                <FormControl variant="outlined">
                  <TextField
                    id="co2UpperLimit"
                    label="CO2 以上"
                    variant="outlined"
                    inputMode="numeric"
                    defaultValue={params.co2UpperLimit}
                    value={params.co2UpperLimit}
                    onChange={(e) => {
                      setParams({
                        ...params,
                        co2UpperLimit: Number(e.target.value) || null,
                      });
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item md={3} my={2}>
                <FormControl variant="outlined">
                  <TextField
                    id="co2LowerLimit"
                    label="CO2 以下"
                    variant="outlined"
                    inputMode="numeric"
                    defaultValue={params.co2LowerLimit}
                    value={params.co2LowerLimit}
                    onChange={(e) => {
                      setParams({
                        ...params,
                        co2LowerLimit: Number(e.target.value) || null,
                      });
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={3} my={2}>
                <FormControl variant="outlined">
                  <TextField
                    id="temperatureUpperLimit"
                    label="温度 以上"
                    variant="outlined"
                    inputMode="numeric"
                    defaultValue={params.temperatureUpperLimit}
                    value={params.temperatureUpperLimit}
                    onChange={(e) => {
                      setParams({
                        ...params,
                        temperatureUpperLimit: Number(e.target.value) || null,
                      });
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item md={3} my={2}>
                <FormControl variant="outlined">
                  <TextField
                    id="temperatureLowerLimit"
                    label="温度 以下"
                    variant="outlined"
                    inputMode="numeric"
                    defaultValue={params.temperatureLowerLimit}
                    value={params.temperatureLowerLimit}
                    onChange={(e) => {
                      setParams({
                        ...params,
                        temperatureLowerLimit: Number(e.target.value) || null,
                      });
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item md={3} my={2}>
                <FormControl variant="outlined">
                  <TextField
                    id="humidityUpperLimit"
                    label="湿度 以上"
                    variant="outlined"
                    inputMode="numeric"
                    defaultValue={params.humidityUpperLimit}
                    value={params.humidityUpperLimit}
                    onChange={(e) => {
                      setParams({
                        ...params,
                        humidityUpperLimit: Number(e.target.value) || null,
                      });
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item md={3} my={2}>
                <FormControl variant="outlined">
                  <TextField
                    id="humidityLowerLimit"
                    label="湿度 以下"
                    variant="outlined"
                    inputMode="numeric"
                    defaultValue={params.humidityLowerLimit}
                    value={params.humidityLowerLimit}
                    onChange={(e) => {
                      setParams({
                        ...params,
                        humidityLowerLimit: Number(e.target.value) || null,
                      });
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <FormControl my={2} variant="outlined">
                  <TimePicker
                    label="通知時間（開始）"
                    value={params.startTime && getTimeObject(params.startTime)}
                    onChange={(newValue) => {
                      setParams({
                        ...params,
                        startTime: formatDate(
                          new Date(newValue || ""),
                          "HH:mm"
                        ),
                      });
                    }}
                    renderInput={(inputParams) => (
                      <TextField variant="outlined" {...inputParams} />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl my={2} variant="outlined">
                  <TimePicker
                    label="通知時間（終了）"
                    value={params.endTime && getTimeObject(params.endTime)}
                    onChange={(newValue) => {
                      setParams({
                        ...params,
                        endTime: formatDate(new Date(newValue || ""), "HH:mm"),
                      });
                    }}
                    renderInput={(inputParams) => (
                      <TextField variant="outlined" {...inputParams} />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Box display="flex" gap={8} justifyContent="flex-end">
              <Box gap={9} padding={2}>
                <Box
                  display="flex"
                  gap={8}
                  paddingBottom={2}
                  justifyContent="flex-end"
                >
                  <Typography fontSize={14}>アラート通知</Typography>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Typography fontSize={14}>無効</Typography>
                    <AntSwitch
                      defaultChecked
                      inputProps={{ "aria-label": "ant design" }}
                      checked={params.enabled}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setParams({
                          ...params,
                          enabled: e.target.checked,
                        });
                      }}
                      name="enabled"
                    />
                    <Typography fontSize={14}>有効</Typography>
                  </Stack>
                </Box>
                <Box display="flex" gap={8} justifyContent="flex-end">
                  <Typography fontSize={14}>グループ管理者への通知</Typography>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Typography fontSize={14}>無効</Typography>
                    <AntSwitch
                      defaultChecked
                      inputProps={{ "aria-label": "ant design" }}
                      checked={params.enabled_manager_notification}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setParams({
                          ...params,
                          enabled_manager_notification: e.target.checked,
                        });
                      }}
                      name="enabled_manager_notification"
                    />
                    <Typography fontSize={14}>有効</Typography>
                  </Stack>
                </Box>
              </Box>
              <Button
                my={2}
                variant="contained"
                color="primary"
                onClick={() => submit(selectedOffice?.id)}
              >
                作成する
              </Button>
            </Box>
          </CardContent>
        )}
        {isLoading && (
          <CardContent
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </CardContent>
        )}
      </Card>
      <Notification
        message={message.body}
        isOpen={message.isOpen}
        type={message.type}
        onClose={() => {
          setMessage({
            ...message,
            isOpen: false,
          });
        }}
      />
    </>
  );
};

function AlertSettingsNewPage() {
  return (
    <React.Fragment>
      <Typography variant="h3" gutterBottom display="inline">
        新規アラート設定追加
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          ダッシュボード
        </Link>
        <Link component={NavLink} to="/alert-setting">
          アラート設定一覧
        </Link>
        <Typography>新規アラート設定追加</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <AlertSettingsNewPageContent />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default AlertSettingsNewPage;
