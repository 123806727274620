import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useLocation } from "react-router-dom";

import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Grid,
  FormControl as MuiFormControl,
  TextField,
  Alert,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridValueFormatterParams,
  jaJP,
} from "@mui/x-data-grid";
import { spacing } from "@mui/system";
import {
  ErrorResponse,
  FetchOfficeDeviceLogListResponse,
  OfficeDeviceLogListURI,
} from "../../types/api";
import { axiosInstance } from "../../utils/axios";
import Notification from "../../components/Notification";
import { CustomToolbar } from "../../components/tables/Toolbar";
import { formatDate, formatDateFromTimestamp } from "../../utils/dateHelper";
import { DatePicker } from "@mui/lab";
import { getBffUri, UriType } from "../../utils/uriHelper";
import { scroll2Top } from "../../utils/dataGridHelper";

const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)<{ m?: number }>`
  min-width: 240px;
  width: 100%;
`;

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

const columns: GridColDef[] = [
  {
    field: "macAddress",
    headerName: "MAC Address",
    width: 150,
    editable: false,
  },
  {
    field: "timestampValue",
    headerName: "記録日時",
    type: "dateTime",
    width: 150,
    editable: false,
    valueFormatter: (params: GridValueFormatterParams) => {
      return formatDateFromTimestamp(params.value);
    },
  },
  {
    field: "timestampValueDate",
    valueGetter: (params) => {
      return params.row.timestampValue;
    },
    headerName: "記録日",
    type: "dateTime",
    width: 150,
    editable: false,
    valueFormatter: (params: GridValueFormatterParams) => {
      return formatDateFromTimestamp(params.value, "yyyy/MM/dd");
    },
  },
  {
    field: "timestampValueTime",
    valueGetter: (params) => {
      return params.row.timestampValue;
    },
    headerName: "記録時間",
    type: "dateTime",
    width: 150,
    editable: false,
    valueFormatter: (params: GridValueFormatterParams) => {
      return formatDateFromTimestamp(params.value, "HH:mm");
    },
  },
  {
    field: "co2Value",
    headerName: "CO2 [ppm]",
    width: 200,
    editable: false,
  },
  {
    field: "humidityValue",
    headerName: "湿度 [%]",
    width: 200,
    editable: false,
  },
  {
    field: "pm25Value",
    headerName: "PM2.5 [μg/m3]",
    width: 200,
    editable: false,
  },
  {
    field: "temperatureValue",
    headerName: "温度 [℃]",
    width: 200,
    editable: false,
  },
  {
    field: "tvocValue",
    headerName: "TVOC [ppb]",
    width: 200,
    editable: false,
  },
  {
    field: "officeName",
    headerName: "オフィス",
    width: 200,
    editable: false,
  },
  {
    field: "placeName",
    headerName: "設置場所",
    width: 200,
    editable: false,
  },
];

type Props = {
  id: string;
  selectedMonth: string;
  loading: boolean;
  setLoading: (value: boolean) => void;
};

const OfficeDeviceLogListPageContent = ({
  id,
  selectedMonth,
  loading,
  setLoading,
}: Props) => {
  const [errorMessageOpen, setErrorMessageOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [data, setData] = useState<
    FetchOfficeDeviceLogListResponse["logs"] | any[]
  >([]);
  const [targetMonth, setTargetMonth] = useState<string>(
    formatDate(new Date(), "yyyy-MM")
  );
  // const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(0);

  const fetchData = async (id: string, targetYearMonth: string) => {
    setLoading(true);
    const res = await axiosInstance.request<
      FetchOfficeDeviceLogListResponse | ErrorResponse
    >({
      method: "GET",
      url: OfficeDeviceLogListURI(id),
      baseURL: getBffUri(UriType.SUB),
      params: {
        target_year_month: targetYearMonth,
        type: "list",
      },
    });

    if (res.status === 200) {
      let newLogList: any[] = [];
      (res.data as FetchOfficeDeviceLogListResponse).logs.forEach(
        (log, index) => {
          newLogList.push({ id: index, ...log });
        }
      );
      setData(newLogList);
      setLoading(false);
    } else {
      setErrorMessage((res.data as ErrorResponse).message!);
      setErrorMessageOpen(true);
      setTimeout(() => {
        setErrorMessageOpen(false);
      }, 5000);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!loading) {
      scroll2Top();
    }
  }, [loading]);

  useEffect(() => {
    if (id && selectedMonth) {
      fetchData(id, selectedMonth);
      setCurrentPage(0);
    }
  }, [id, selectedMonth]);

  return (
    <>
      <Card mb={6}>
        <Paper>
          <div style={{ height: 800, width: "100%" }}>
            <DataGrid
              rowsPerPageOptions={[50, 100]}
              rows={data}
              columns={columns}
              pageSize={50}
              components={{
                Toolbar: CustomToolbar,
              }}
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
              initialState={{
                sorting: {
                  sortModel: [
                    {
                      field: "timestampValue",
                      sort: "desc",
                    },
                  ],
                },
              }}
              loading={loading}
              page={currentPage}
              onPageChange={(pageNum) => {
                setCurrentPage(pageNum);
                scroll2Top();
              }}
            />
          </div>
        </Paper>
      </Card>
      <Notification
        message={errorMessage}
        isOpen={errorMessageOpen}
        onClose={() => {
          setErrorMessageOpen(false);
        }}
      />
    </>
  );
};

const OfficeDeviceLogListPage = () => {
  const location = useLocation();
  const pathNames = location.pathname.split("/").filter((x) => x);
  const id = pathNames[pathNames.length - 2];
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessageOpen, setErrorMessageOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [selectedMonth, setSelectedMonth] = useState<string>(
    formatDate(new Date(), "yyyy-MM")
  );

  return (
    <React.Fragment>
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            設置センサー機器計測データ
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              ダッシュボード
            </Link>
            <Link component={NavLink} to="/office-devices">
              設置センサー機器一覧
            </Link>
            <Typography>設置センサー機器計測データ</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item pl={2}>
              <FormControl fullWidth my={2} variant="outlined">
                <DatePicker
                  label="対象月"
                  value={selectedMonth}
                  inputFormat={"yyyy-MM"}
                  views={["year", "month"]}
                  onChange={() => {}}
                  onMonthChange={(date: Date | null) => {
                    if (loading) {
                      setErrorMessage(
                        "loading中です。しばらく経ってから操作してください。"
                      );
                      setErrorMessageOpen(true);
                    } else {
                      setErrorMessageOpen(false);
                      if (date) {
                        setSelectedMonth(formatDate(date, "yyyy-MM"));
                      }
                    }
                  }}
                  renderInput={(params) => (
                    <TextField size="small" {...params} />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Divider my={6} />

      <OfficeDeviceLogListPageContent
        id={id}
        selectedMonth={selectedMonth}
        loading={loading}
        setLoading={setLoading}
      />
      <Notification
        message={errorMessage}
        isOpen={errorMessageOpen}
        onClose={() => {
          setErrorMessageOpen(false);
        }}
      />
    </React.Fragment>
  );
};

export default OfficeDeviceLogListPage;
