import React, { useState } from "react";
import styled from "@emotion/styled";
import { Alert } from "@mui/lab";
import { QuestionMark as QuestionMarkIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  Drawer,
  Fab as MuiFab,
  ListItemButton,
} from "@mui/material";
import useAuth from "../hooks/useAuth";

const Fab = styled(MuiFab)`
  position: fixed;
  right: ${(props) => props.theme.spacing(8)};
  bottom: ${(props) => props.theme.spacing(8)};
  z-index: 1;
`;

const Wrapper = styled.div`
  width: 258px;
  overflow-x: hidden;
`;

const Heading = styled(ListItemButton)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }
`;

function Demos() {
  const { user } = useAuth();

  return (
    <Wrapper>
      <Heading>マニュアル</Heading>

      <Box px={4} my={3}>
        <Alert icon={false} severity="info">
          <strong>ダッシュボード</strong> の使い方および{" "}
          <strong>センサー機器</strong>{" "}
          に関しては以下のマニュアルを御覧ください。
        </Alert>
      </Box>

      {user && user.role === "manager" && (
        <Box my={5} mx={4}>
          <Button
            variant="contained"
            size="large"
            fullWidth={true}
            href="/manual_01__manager.pdf"
            target="_blank"
          >
            管理画面操作マニュアル
          </Button>
        </Box>
      )}

      {user && user.role === "staff" && (
        <Box my={5} mx={4}>
          <Button
            variant="contained"
            size="large"
            fullWidth={true}
            href="/manual_01__staff.pdf"
            target="_blank"
          >
            管理画面操作マニュアル
          </Button>
        </Box>
      )}

      <Box my={5} mx={4}>
        <Button
          variant="contained"
          size="large"
          fullWidth={true}
          href="/manual_02.pdf"
          target="_blank"
        >
          機器使用マニュアル
        </Button>
      </Box>
    </Wrapper>
  );
}

function Settings() {
  const [state, setState] = useState({
    isOpen: false,
  });

  const toggleDrawer = (open: boolean) => () => {
    setState({ ...state, isOpen: open });
  };

  return (
    <React.Fragment>
      <Fab color="primary" aria-label="Edit" onClick={toggleDrawer(true)}>
        <QuestionMarkIcon />
      </Fab>
      <Drawer anchor="right" open={state.isOpen} onClose={toggleDrawer(false)}>
        <Demos />
      </Drawer>
    </React.Fragment>
  );
}

export default Settings;
