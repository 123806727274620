import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";

import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridValueFormatterParams,
  jaJP,
} from "@mui/x-data-grid";
import { spacing } from "@mui/system";
import { Add as AddIcon } from "@mui/icons-material";
import { axiosInstance } from "../../utils/axios";
import { ErrorResponse } from "../../types/api";
import EditButton from "../../components/buttons/EditButton";
import DeleteButton from "../../components/buttons/DeleteButton";
import Notification from "../../components/Notification";
import useAuth from "../../hooks/useAuth";
import { CustomToolbar } from "../../components/tables/Toolbar";
import {
  FetchOfficeCategoryListResponse,
  OfficeCategoryDeleteResponse,
  OfficeCategoryDeleteURI,
  OfficeCategoryListURI,
} from "../../types/api/officeCategory";
import { formatDateFromTimestamp } from "../../utils/dateHelper";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

const CategoriesPageContent = () => {
  const { signOut } = useAuth();
  const [errorMessageOpen, setErrorMessageOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [data, setData] = useState<
    FetchOfficeCategoryListResponse["objects"] | []
  >([]);

  const [deletedDataId, setDeletedDataId] = useState<string[]>([]);
  const handleDelete = async (id: string) => {
    const res = await axiosInstance.delete<
      OfficeCategoryDeleteResponse | ErrorResponse
    >(OfficeCategoryDeleteURI(id));

    if (res.status === 200) {
      setDeletedDataId([...deletedDataId, id]);
    } else {
      setErrorMessage((res.data as ErrorResponse).message!);
      setErrorMessageOpen(true);
      setTimeout(() => {
        setErrorMessageOpen(false);
      }, 5000);
    }
  };

  const columns: GridColDef[] = [
    // 編集ボタン
    {
      field: "editBtn",
      headerName: "編集",
      sortable: false,
      filterable: false,
      width: 60,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => <EditButton href={`/categories/${params.id}`} />,
    },
    // 削除ボタン
    {
      field: "deleteBtn",
      headerName: "削除",
      sortable: false,
      filterable: false,
      width: 60,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <DeleteButton id={params.id as string} callback={handleDelete} />
      ),
    },
    {
      field: "id",
      headerName: "ID",
      width: 300,
      editable: false,
      filterable: true,
      sortable: false,
      hide: true,
    },
    {
      field: "name",
      headerName: "事業所カテゴリー",
      width: 200,
      editable: false,
      filterable: true,
      sortable: true,
    },
    {
      field: "createdAt",
      headerName: "作成日時",
      type: "dateTime",
      width: 150,
      editable: false,
      filterable: true,
      sortable: true,
      valueFormatter: (params: GridValueFormatterParams) => {
        return formatDateFromTimestamp(params.value);
      },
    },
    {
      field: "updatedAt",
      headerName: "更新日時",
      type: "datetime",
      width: 150,
      editable: false,
      filterable: true,
      sortable: true,
      valueFormatter: (params: GridValueFormatterParams) => {
        return formatDateFromTimestamp(params.value);
      },
    },
  ];

  const [pageSize, setPageSize] = useState<number>(50);
  const [page, setPage] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const handlePageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
  };
  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const fetchData = async (pageSize: number, page: number) => {
    setLoading(true);
    const res = await axiosInstance.get<
      FetchOfficeCategoryListResponse | ErrorResponse
    >(OfficeCategoryListURI, {
      params: {
        offset: page * pageSize,
        limit: pageSize,
        sort_by: "created_at-desc",
      },
    });

    if (res.status === 200) {
      const responseData = res.data as FetchOfficeCategoryListResponse;
      setData(responseData.objects);
      setTotal(responseData.paging.total);
      setLoading(false);
    } else {
      setErrorMessage((res.data as ErrorResponse).message!);
      setErrorMessageOpen(true);
      setTimeout(() => {
        setErrorMessageOpen(false);
      }, 5000);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(pageSize, page);
  }, []);

  useEffect(() => {
    fetchData(pageSize, page);
  }, [pageSize, page]);

  useEffect(() => {
    if (deletedDataId) {
      setData(data.filter((element) => !deletedDataId.includes(element.id)));
    }
  }, [deletedDataId]);

  return (
    <>
      <Card mb={6}>
        <Paper>
          <div style={{ height: 800, width: "100%" }}>
            <DataGrid
              rowsPerPageOptions={[50, 100]}
              rows={data}
              columns={columns}
              pageSize={pageSize}
              rowCount={total}
              page={page}
              checkboxSelection={false}
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
              components={{ Toolbar: CustomToolbar }}
              initialState={{
                sorting: {
                  sortModel: [
                    {
                      field: "createdAt",
                      sort: "desc",
                    },
                  ],
                },
              }}
              loading={loading}
              pagination
              paginationMode="server"
              onPageSizeChange={handlePageSizeChange}
              onPageChange={handlePageChange}
            />
          </div>
        </Paper>
      </Card>
      <Notification
        message={errorMessage}
        isOpen={errorMessageOpen}
        onClose={() => {
          setErrorMessageOpen(false);
        }}
      />
    </>
  );
};

const CategoriesPage = () => {
  return (
    <>
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            事業所カテゴリー一覧
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              ダッシュボード
            </Link>
            <Typography>事業所カテゴリー一覧</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            <Button
              component={NavLink}
              to="/categories/new"
              variant="contained"
              color="primary"
            >
              <AddIcon />
              新規追加
            </Button>
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />

      <CategoriesPageContent />
    </>
  );
};

export default CategoriesPage;
