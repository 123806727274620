export const getFirstDayOfMonth = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth(), 1);
};

export const getLastDayOfMonth = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
};

export const getYesterday = (date: Date) => {
  return getBeforeDate(date, 1);
};

export const getBeforeDate = (date: Date, offset: number) => {
  const beforeDate = new Date();
  beforeDate.setDate(date.getDate() - offset);
  return beforeDate;
};

export const formatDate = (date: Date, format: string = "yyyy/MM/dd HH:mm") => {
  format = format.replace(/yyyy/g, date.getFullYear().toString());
  format = format.replace(/MM/g, ("0" + (date.getMonth() + 1)).slice(-2));
  format = format.replace(/dd/g, ("0" + date.getDate()).slice(-2));
  format = format.replace(/HH/g, ("0" + date.getHours()).slice(-2));
  format = format.replace(/mm/g, ("0" + date.getMinutes()).slice(-2));
  format = format.replace(/ss/g, ("0" + date.getSeconds()).slice(-2));
  format = format.replace(/SSS/g, ("00" + date.getMilliseconds()).slice(-3));
  return format;
};

export const formatDateFromTimestamp = (
  timestamp: any,
  format: string = "yyyy/MM/dd HH:mm"
) => {
  if (!timestamp) return "";
  const date = new Date(timestamp * 1000);

  return formatDate(date, format);
};

export const sortByTimestamp = (list: any[]) => {
  return list.sort((a, b) => (a.timestamp < b.timestamp ? -1 : 1));
};

export const getTimeObject = (strTime: string) => {
  const timeArr = strTime.split(":");
  const currentDate = new Date();
  currentDate.setHours(Number(timeArr[0]));
  currentDate.setMinutes(Number(timeArr[1]));
  return currentDate;
};

export const changeTimestampDateToTargetDate = (
  targetDate: string,
  timestamp: number
) => {
  const targetDateObj = new Date(targetDate);

  const date = new Date(timestamp * 1000);

  const year = targetDateObj.getFullYear();
  const month = targetDateObj.getMonth();
  const day = targetDateObj.getDate();

  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds();

  const newDate = new Date(Date.UTC(year, month, day, hours, minutes, seconds));

  return newDate;
};
