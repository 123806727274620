import {
  ComposedChart,
  YAxis,
  XAxis,
  CartesianGrid,
  Legend,
  Line,
  ResponsiveContainer,
} from "recharts";
import { MinMaxDataType } from "../pages/pages/Reports";
import { formatDate, formatDateFromTimestamp } from "../utils/dateHelper";

type Props = {
  date: Date;
  groupedData: any;
  selectedDate: Date;
  minMaxData: MinMaxDataType;
};

export const SecondChart = ({
  date,
  groupedData,
  selectedDate,
  minMaxData,
}: Props) => {
  const formattedDate = formatDate(date, "yyyy-MM-dd");
  const theDateData =
    date.getMonth() === selectedDate.getMonth()
      ? groupedData[formattedDate]
      : [];
  const isSunday = date.getDay() === 0;
  const isSaturday = date.getDay() === 6;
  const filledColor = date.getDay() % 2 !== 0 ? "#F7F9FC" : "";
  const DateLegend = () => (
    <div
      style={{
        width: 10,
        left: isSunday ? 35 : 0,
      }}
    >
      {date.getDate()}
    </div>
  );
  const HEIGHT = 140;
  const AXIS_WIDTH = 32;
  const w = isSunday || isSaturday ? HEIGHT + AXIS_WIDTH : HEIGHT;
  const verticalPoints = isSunday
    ? [0, HEIGHT / 4, (HEIGHT / 4) * 2, (HEIGHT / 4) * 3].map((v) => v + 32)
    : [0, HEIGHT / 4, (HEIGHT / 4) * 2, (HEIGHT / 4) * 3];

  return (
    <ResponsiveContainer width={w} height={HEIGHT}>
      <ComposedChart
        data={theDateData}
        margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
      >
        <YAxis
          yAxisId={1}
          tick={isSunday ? { fontSize: 8 } : false}
          width={isSunday ? 32 : 0}
          domain={[0, 50]}
          ticks={[0, 25, 50]}
        />
        <YAxis
          yAxisId={2}
          tick={isSaturday ? { fontSize: 8 } : false}
          width={isSaturday ? 32 : 0}
          domain={[0, 100]}
          orientation="right"
          ticks={[0, 50, 100]}
        />
        <XAxis
          tick={{ fontSize: 9 }}
          dataKey="timestamp"
          interval={2}
          tickFormatter={(tickItem) =>
            formatDateFromTimestamp(tickItem, "HH:mm")
          }
          hide={true}
        />
        <CartesianGrid
          fill={filledColor}
          strokeDasharray="4"
          horizontalPoints={[0, HEIGHT]}
          verticalPoints={verticalPoints}
        />
        <Legend
          content={<DateLegend />}
          align="left"
          verticalAlign="middle"
          height={0}
          wrapperStyle={{
            position: "absolute",
            top: 10,
            left: isSunday ? 35 : 4,
          }}
        />
        <Line
          dataKey="temp"
          stroke="#ff9800"
          yAxisId={1}
          dot={false}
          isAnimationActive={false}
        />
        <Line
          dataKey="rh"
          stroke="#4caf50"
          yAxisId={2}
          dot={false}
          isAnimationActive={false}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};
