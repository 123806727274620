import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import {
  Autocomplete,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import { axiosInstance } from "../../utils/axios";
import { ErrorResponse } from "../../types/api";
import Notification from "../../components/Notification";
import {
  FetchGroupListResponse,
  GroupEditParams,
  GroupEditResponse,
  GroupEditURI,
  GroupListURI,
  GroupResponse,
  GroupURI,
} from "../../types/api/group";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const FormControl = styled(MuiFormControl)<{ my?: number }>(spacing);
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}
const Button = styled(MuiButton)<ButtonProps>(spacing);

type Props = {
  id: string;
};

const GroupsEditPageContent = ({ id }: Props) => {
  const navigate = useNavigate();
  const [errorMessageOpen, setErrorMessageOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [group, setGroup] = useState<GroupResponse>();
  const [parentGroupList, setParentGroupList] = useState<
    FetchGroupListResponse["objects"]
  >([]);
  const [selectedParentGroup, setSelectedParentGroup] =
    useState<{ id: string; label: string }>();

  useEffect(() => {
    const fetchData = async () => {
      const groupListResponse = await axiosInstance.get<
        FetchGroupListResponse | ErrorResponse
      >(GroupListURI, {
        params: {
          offset: 0,
          limit: 5000,
          sort_by: "created_at-desc",
        },
      });
      if (groupListResponse.status === 200) {
        setParentGroupList(
          (groupListResponse.data as FetchGroupListResponse).objects.filter(
            (group) => group.id !== id
          )
        );
      } else {
        setErrorMessage((groupListResponse.data as ErrorResponse).message!);
        setErrorMessageOpen(true);
        setTimeout(() => {
          setErrorMessageOpen(false);
        }, 5000);
      }

      const groupResponse = await axiosInstance.get<
        GroupResponse | ErrorResponse
      >(GroupURI(id));
      if (groupResponse.status === 200) {
        setGroup(groupResponse.data as GroupResponse);
      } else {
        setErrorMessage((groupResponse.data as ErrorResponse).message!);
        setErrorMessageOpen(true);
        setTimeout(() => {
          setErrorMessageOpen(false);
        }, 5000);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    if (group?.parentGroup) {
      setSelectedParentGroup({
        id: group.parentGroup.id,
        label: group.parentGroup.name,
      });
    }
  }, [group]);

  const submit = async () => {
    const res = await axiosInstance.patch<GroupEditResponse | ErrorResponse>(
      GroupEditURI(id),
      {
        parent_group_id: selectedParentGroup?.id,
        name: group?.name,
      } as GroupEditParams
    );

    if (res.status === 200) {
      navigate("/groups");
    } else {
      setErrorMessage((res.data as ErrorResponse).message!);
      setErrorMessageOpen(true);
      setTimeout(() => {
        setErrorMessageOpen(false);
      }, 5000);
    }
  };

  return (
    <>
      <Card mb={6}>
        {parentGroupList.length > 0 && (
          <CardContent>
            {selectedParentGroup && (
              <FormControl fullWidth my={2}>
                <Autocomplete
                  disablePortal
                  disableClearable
                  id="group-list-select"
                  fullWidth
                  defaultValue={{
                    id: selectedParentGroup.id,
                    label: selectedParentGroup.label,
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="親グループ" />
                  )}
                  options={parentGroupList.map((item) => {
                    return { id: item.id, label: item.name };
                  })}
                  onChange={(event, target) => {
                    if (target) {
                      setSelectedParentGroup({
                        id: target.id,
                        label: target.label,
                      });
                    }
                  }}
                />
              </FormControl>
            )}
            <FormControl fullWidth my={2} variant="outlined">
              <TextField
                id="name"
                label="グループ名"
                required
                variant="outlined"
                defaultValue={group?.name}
                value={group?.name}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                my={2}
                onChange={(e) => {
                  if (group) setGroup({ ...group, name: e.target?.value });
                }}
              />
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              onClick={() => submit()}
            >
              変更を保存する
            </Button>
          </CardContent>
        )}
        {!(parentGroupList.length > 0) && (
          <CardContent
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </CardContent>
        )}
      </Card>
      <Notification
        message={errorMessage}
        isOpen={errorMessageOpen}
        onClose={() => {
          setErrorMessageOpen(false);
        }}
      />
    </>
  );
};

const GroupsEditPage = () => {
  const location = useLocation();
  const pathNames = location.pathname.split("/").filter((x) => x);
  const id = pathNames[pathNames.length - 1];

  return (
    <React.Fragment>
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            事業所グループ編集
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              ダッシュボード
            </Link>
            <Link component={NavLink} to="/groups">
              グループ一覧
            </Link>
            <Typography>事業所グループ編集</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={6} />

      <GroupsEditPageContent id={id} />
    </React.Fragment>
  );
};

export default GroupsEditPage;
