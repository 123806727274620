import { AxiosResponse } from "axios";
import { saveAs } from "file-saver";
import encoding from "encoding-japanese";

export const downloadFile = (filename: string, response: AxiosResponse) => {
  const uint8Array = new Uint8Array(
    encoding.convert(Buffer.from(response.data), {
      from: "UTF8",
      to: "SJIS",
    })
  );

  const blob = new Blob([uint8Array], {
    type: "application/octet-binary",
  });

  saveAs(blob, `${filename}.csv`);
};
