import React, { useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";

import {
  Alert,
  Autocomplete,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Snackbar,
  TextField as MuiTextField,
  Typography,
  Stack,
  Box,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import { axiosInstance } from "../../utils/axios";
import {
  ErrorResponse,
  OfficeCategoryListResponse,
  OfficeCategoryListURI,
} from "../../types/api";
import { PrefectureList } from "../../utils/prefectureList";
import {
  AddNewOfficeParams,
  AddNewOfficeURI,
  OfficeResponse,
} from "../../types/api/office";
import { FetchGroupListResponse, GroupListURI } from "../../types/api/group";
import { AntSwitch } from "../components/AntSwitch";
import useAuth from "../../hooks/useAuth";
import { getAddressInfosByPoseCode } from "../../apis/get-address-by-post-code";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import CustomMarker from "../components/CustomMarker";

interface ILatLng {
  lat: number;
  lng: number;
}

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}
const Button = styled(MuiButton)<ButtonProps>(spacing);

const FormControlSpacing = styled(MuiFormControl)(spacing);

const FormControl = styled(FormControlSpacing)<{ m?: number }>`
  min-width: 240px;
  width: 100%;
`;

const OfficesNewPageContent = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [officeCategoryList, setOfficeCategoryList] = useState<
    OfficeCategoryListResponse["objects"]
  >([]);
  const [selectedOfficeCategory, setSelectedOfficeCategory] =
    useState<{ id: string; label: string }>();
  const [groupList, setGroupList] = useState<FetchGroupListResponse["objects"]>(
    []
  );
  const [selectedGroup, setSelectedGroup] =
    useState<{ id: string; label: string }>();
  const [nameValue, setNameValue] = useState<String | null>(null);
  const [postalCodeValue, setPostalCodeValue] = useState<String | null>(null);
  const [selectedPrefectureId, setSelectedPrefectureId] =
    React.useState<String | null>(null);
  const [districtValue, setDistrictValue] = useState<String | null>(null);
  const [addressValue, setAddressValue] = useState<String | null>(null);
  const [buildingValue, setBuildingValue] = useState<String | null>(null);
  const [isParticipatedToMinnaMap, setIsParticipatedToMinnaMap] = useState<
    boolean | undefined
  >(undefined);
  const [errorMessageOpen, setErrorMessageOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const [geocoder, setGeocoder] = useState<google.maps.Geocoder | null>(null);
  const [searchedLatLng, setSearchedLatLng] = useState<ILatLng>();

  const [selectedPrefectureName, setSelectedPrefectureName] =
    useState<string>("");

  const [zoom, setZoom] = useState<number>(15);

  useEffect(() => {
    const fetchData = async () => {
      const officeCategoryListResponse =
        await axiosInstance.get<OfficeCategoryListResponse>(
          OfficeCategoryListURI,
          {
            params: {
              offset: 0,
              limit: 5000,
              sort_by: "created_at-desc",
            },
          }
        );
      setOfficeCategoryList(officeCategoryListResponse.data.objects);

      const groupListResponse = await axiosInstance.get<FetchGroupListResponse>(
        GroupListURI,
        {
          params: {
            offset: 0,
            limit: 5000,
            sort_by: "created_at-desc",
          },
        }
      );
      setGroupList(groupListResponse.data.objects);
    };

    fetchData();
  }, []);

  useEffect(() => {
    setLoading(true);
    setLoading(false);
  }, [selectedPrefectureId, districtValue]);

  const submit = async () => {
    const res = await axiosInstance.post<OfficeResponse | ErrorResponse>(
      AddNewOfficeURI,
      {
        office_category_id: selectedOfficeCategory?.id,
        group_id: selectedGroup?.id,
        name: nameValue,
        postal_code: postalCodeValue,
        prefecture: selectedPrefectureId,
        district: districtValue,
        address: addressValue,
        building: buildingValue,
        is_participated_to_minna_map: isParticipatedToMinnaMap,
        lat_lng:
          searchedLatLng && searchedLatLng.lat && searchedLatLng.lng
            ? `${searchedLatLng?.lat},${searchedLatLng?.lng}`
            : null,
      } as AddNewOfficeParams
    );

    if (res.status === 200) {
      navigate("/offices");
    } else {
      setErrorMessage((res.data as ErrorResponse).message!);
      setErrorMessageOpen(true);
      setTimeout(() => {
        setErrorMessageOpen(false);
      }, 5000);
    }
  };

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const onLoad = (mapProp: google.maps.Map) => {
    // map.current = mapProp;
    setMap(mapProp);
    const geocoder = new window.google.maps.Geocoder();
    setGeocoder(geocoder);
  };

  const searchLatLng = async (address: string): Promise<void> => {
    if (geocoder) {
      console.log(address);
      await geocoder.geocode({ address: address }, (results, status) => {
        if (status === "OK" && results && results.length > 0) {
          const location = results[0].geometry.location;
          setSearchedLatLng({
            lat: location.lat(),
            lng: location.lng(),
          });
          setZoom(15);
        } else {
          console.error(
            "Geocode was not successful for the following reason: " + status
          );
        }
      });
    }
  };

  const setFullAddress = async () => {
    let fullAddress = "";
    if (postalCodeValue !== null) {
      fullAddress += postalCodeValue;
    }
    if (selectedPrefectureName !== null) {
      fullAddress += selectedPrefectureName;
    }
    if (districtValue !== null) {
      fullAddress += districtValue;
    }
    if (addressValue !== null) {
      fullAddress += addressValue;
    }
    if (buildingValue !== null) {
      fullAddress += buildingValue;
    }

    await searchLatLng(fullAddress);
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyB0OuoBvX7M_hV9t4fL2RMuLkpviT84gK8",
    libraries: ["places"],
    language: "ja",
  });

  const options: google.maps.MapOptions = {
    scaleControl: true,
    mapTypeId: "roadmap",
    mapTypeControl: false,
    fullscreenControl: false,
    center: searchedLatLng,

    zoomControl: true,
    zoom: zoom,
    streetViewControl: false,
  };

  useEffect(() => {
    if (map) {
      if (searchedLatLng) {
        map.panTo(searchedLatLng);
      }
    }
  }, [map, searchedLatLng]);

  return (
    <>
      <Card mb={6}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item md={3}>
              <FormControl my={2}>
                <Autocomplete
                  disablePortal
                  id="office-category-list-select"
                  fullWidth
                  renderInput={(params) => (
                    <TextField {...params} label="事業所カテゴリ" required />
                  )}
                  options={officeCategoryList.map((item) => {
                    return { id: item.id, label: item.name };
                  })}
                  onChange={(event, target) => {
                    if (target) {
                      setSelectedOfficeCategory({
                        id: target.id,
                        label: target.label,
                      });
                    }
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item md={3}>
              <FormControl my={2}>
                <Autocomplete
                  disablePortal
                  id="group-list-select"
                  fullWidth
                  renderInput={(params) => (
                    <TextField {...params} label="グループ" required />
                  )}
                  options={groupList.map((item) => {
                    return { id: item.id, label: item.name };
                  })}
                  onChange={(event, target) => {
                    if (target) {
                      setSelectedGroup({
                        id: target.id,
                        label: target.label,
                      });
                    }
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <FormControl fullWidth my={2} variant="outlined">
            <TextField
              id="name"
              label="事業所名"
              required
              variant="outlined"
              defaultValue={nameValue === null ? "" : nameValue}
              value={nameValue === null ? "" : nameValue}
              fullWidth
              my={2}
              onChange={(e) => {
                setNameValue(e.target.value);
              }}
            />
          </FormControl>

          <Grid container spacing={6}>
            <Grid item md={3}>
              <TextField
                id="postal-code"
                label="郵便番号"
                required
                variant="outlined"
                defaultValue={postalCodeValue === null ? "" : postalCodeValue}
                value={postalCodeValue === null ? "" : postalCodeValue}
                fullWidth
                my={2}
                onChange={(e) => {
                  setPostalCodeValue(e.target.value);
                }}
                onBlur={async (e) => {
                  setLoading(true);
                  await getAddressInfosByPoseCode(e.target?.value)
                    .then(async (addressInfos) => {
                      if (addressInfos.length > 0) {
                        const keys = Object.keys(addressInfos[0]);
                        let prefectureInfos: any[] = [];
                        let district: string = "";
                        let prefectureId: string = "";
                        let prefectureName: string = "";
                        keys.forEach((key) => {
                          if (key === "address1") {
                            prefectureInfos = PrefectureList.filter(
                              (list) => list.label === addressInfos[0][key]
                            );
                            if (prefectureInfos.length > 0) {
                              prefectureId = prefectureInfos[0].id;
                            }
                            prefectureName = addressInfos[0][key];
                            setSelectedPrefectureName(prefectureName);
                          }

                          if (key === "address2") {
                            district = addressInfos[0][key];
                          }

                          if (key === "address3") {
                            district = district + addressInfos[0][key];
                          }

                          if (key === "prefcode") {
                            if (prefectureInfos.length > 0) {
                              if (
                                prefectureInfos[0].prefecture_id !==
                                addressInfos[0][key]
                              ) {
                                prefectureId = prefectureInfos[0].id;
                              }
                            }
                          }
                        });
                        setSelectedPrefectureId(prefectureId);
                        setDistrictValue(district);
                        let fullAddress =
                          e.target.value + prefectureName + district;

                        if (addressValue !== null) {
                          fullAddress += addressValue;
                        }
                        if (buildingValue !== null) {
                          fullAddress += buildingValue;
                        }

                        await searchLatLng(fullAddress);
                        setLoading(false);
                      }
                    })
                    .catch((err) => {
                      setLoading(false);
                    });
                  setLoading(false);
                }}
              />
            </Grid>
            <Grid item md={3}>
              <FormControl my={2}>
                <InputLabel id="prefecture-list-select-label" required>
                  都道府県
                </InputLabel>
                <Select
                  labelId="prefecture-list-select-label"
                  id="prefecture-list-select"
                  value={
                    selectedPrefectureId === null ? "" : selectedPrefectureId
                  }
                  label="都道府県"
                  defaultValue={
                    selectedPrefectureId === null ? "" : selectedPrefectureId
                  }
                  required
                  fullWidth
                  onChange={(e) => {
                    setSelectedPrefectureId(e.target.value);
                  }}
                  onBlur={async (e) => {
                    await setFullAddress();
                  }}
                >
                  {PrefectureList.map((prefecture, i) => {
                    return (
                      <MenuItem key={prefecture.id} value={prefecture.id}>
                        {prefecture.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={3}>
              {isLoaded && !loading && (
                <GoogleMap
                  onLoad={onLoad}
                  mapContainerStyle={{
                    width:
                      searchedLatLng && searchedLatLng.lat && searchedLatLng.lng
                        ? "100%"
                        : 0,
                    height:
                      searchedLatLng && searchedLatLng.lat && searchedLatLng.lng
                        ? "300px"
                        : 0,
                  }}
                  options={options}
                  zoom={15}
                >
                  {searchedLatLng && searchedLatLng.lat && searchedLatLng.lng && (
                    <Marker
                      position={{
                        lat: searchedLatLng.lat,
                        lng: searchedLatLng.lng,
                      }}
                    />
                  )}
                </GoogleMap>
              )}
            </Grid>
          </Grid>

          <FormControl fullWidth my={2} variant="outlined">
            <TextField
              id="district"
              label="市町村区"
              required
              variant="outlined"
              defaultValue={districtValue === null ? "" : districtValue}
              value={districtValue === null ? "" : districtValue}
              fullWidth
              my={2}
              onChange={(e) => {
                setDistrictValue(e.target.value);
              }}
              onBlur={async (e) => {
                await setFullAddress();
              }}
            />
          </FormControl>

          <FormControl fullWidth my={2} variant="outlined">
            <TextField
              id="address"
              label="番地"
              required
              variant="outlined"
              defaultValue={addressValue === null ? "" : addressValue}
              value={addressValue === null ? "" : addressValue}
              fullWidth
              my={2}
              onChange={(e) => {
                setAddressValue(e.target.value);
              }}
              onBlur={async (e) => {
                await setFullAddress();
              }}
            />
          </FormControl>

          <FormControl fullWidth my={2} variant="outlined">
            <TextField
              id="building"
              label="建物名・階数・部屋番号"
              variant="outlined"
              defaultValue={buildingValue === null ? "" : buildingValue}
              value={buildingValue === null ? "" : buildingValue}
              fullWidth
              my={2}
              onChange={(e) => {
                setBuildingValue(e.target.value);
              }}
              onBlur={async (e) => {
                await setFullAddress();
              }}
            />
          </FormControl>

          <Button variant="contained" color="primary" onClick={() => submit()}>
            作成する
          </Button>
          {user?.role === "admin" && (
            <Box display="flex" gap={8} justifyContent="flex-end">
              <Box gap={9} padding={2}>
                <Box
                  display="flex"
                  gap={8}
                  paddingBottom={2}
                  justifyContent="flex-end"
                >
                  <Typography fontSize={14}>総合マップ</Typography>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Typography fontSize={14}>不参加</Typography>
                    <AntSwitch
                      // defaultChecked
                      inputProps={{ "aria-label": "ant design" }}
                      checked={isParticipatedToMinnaMap}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setIsParticipatedToMinnaMap(e.target.checked);
                      }}
                    />
                    <Typography fontSize={14}>参加</Typography>
                  </Stack>
                </Box>
              </Box>
            </Box>
          )}
        </CardContent>
        {loading && (
          <CardContent>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress />
            </Backdrop>
          </CardContent>
        )}
      </Card>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={errorMessageOpen}
        autoHideDuration={6000}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

const OfficesNewPage = () => {
  return (
    <React.Fragment>
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            新規事業所作成
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              ダッシュボード
            </Link>
            <Link component={NavLink} to="/groups">
              事業所一覧
            </Link>
            <Typography>新規事業所作成</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={6} />

      <OfficesNewPageContent />
    </React.Fragment>
  );
};

export default OfficesNewPage;
