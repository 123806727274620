import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import {
  Alert,
  Autocomplete,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Snackbar,
  TextField as MuiTextField,
  Typography,
  Stack,
  Box,
  Paper,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import { axiosInstance } from "../../utils/axios";
import {
  OfficeCategoryListResponse,
  OfficeCategoryListURI,
  ErrorResponse,
} from "../../types/api";
import { PrefectureList } from "../../utils/prefectureList";
import {
  OfficeResponse,
  OfficeURI,
  UpdateOfficeParams,
} from "../../types/api/office";
import { FetchGroupListResponse, GroupListURI } from "../../types/api/group";
import { AntSwitch } from "../components/AntSwitch";
import useAuth from "../../hooks/useAuth";
import { getAddressInfosByPoseCode } from "../../apis/get-address-by-post-code";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
interface ButtonProps extends SpacingProps {
  component?: string;
}
const Button = styled(MuiButton)<ButtonProps>(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)<{ m?: number }>`
  min-width: 240px;
  width: 100%;
`;

type Props = {
  id: string;
};

interface ILatLng {
  lat: number;
  lng: number;
}

const OfficesEditPageContent = ({ id }: Props) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [office, setOffice] = useState<OfficeResponse>();
  const [officeCategoryList, setOfficeCategoryList] = useState<
    OfficeCategoryListResponse["objects"]
  >([]);
  const [selectedOfficeCategory, setSelectedOfficeCategory] =
    useState<{ id: string; label: string }>();
  const [groupList, setGroupList] = useState<FetchGroupListResponse["objects"]>(
    []
  );
  const [selectedGroup, setSelectedGroup] =
    useState<{ id: string; label: string }>();
  const [selectedPrefectureId, setSelectedPrefectureId] =
    React.useState<String | null>(null);
  const [errorMessageOpen, setErrorMessageOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const [geocoder, setGeocoder] = useState<google.maps.Geocoder | null>(null);
  const [searchedLatLng, setSearchedLatLng] = useState<ILatLng>();

  const [selectedPrefectureName, setSelectedPrefectureName] =
    useState<string>("");

  const [zoom, setZoom] = useState<number>(15);
  // const [zipErrorMessage, setZipErrorMessage] = useState<string | undefined>();

  // const [dragging, setDragging] = useState(false);
  // const [files, setFiles] = useState<File[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const officeCategoryListResponse =
        await axiosInstance.get<OfficeCategoryListResponse>(
          OfficeCategoryListURI
        );
      setOfficeCategoryList(officeCategoryListResponse.data.objects);

      const groupListResponse = await axiosInstance.get<FetchGroupListResponse>(
        GroupListURI,
        {
          params: {
            offset: 0,
            limit: 5000,
            sort_by: "created_at-desc",
          },
        }
      );
      setGroupList(groupListResponse.data.objects);

      const officeResponse = await axiosInstance.get<OfficeResponse>(
        OfficeURI(id)
      );
      setOffice(officeResponse.data);
      setSelectedOfficeCategory({
        id: officeResponse.data.officeCategory.id,
        label: officeResponse.data.officeCategory.name,
      });
      setSelectedGroup({
        id: officeResponse.data.group.id,
        label: officeResponse.data.group.name,
      });
      setSelectedPrefectureId(officeResponse.data.prefecture);
      if (officeResponse.data.latLng !== null && officeResponse.data.latLng) {
        const latLngSplit = officeResponse.data.latLng.split(",");
        if (latLngSplit.length > 0)
          setSearchedLatLng({
            lat: Number(latLngSplit[0]),
            lng: Number(latLngSplit[1]),
          });
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    setLoading(true);
    setLoading(false);
  }, [selectedPrefectureId, office]);

  // const convertToBinaryString = async (file: File): Promise<string> => {
  //   return new Promise((resolve, reject) => {
  //     const blobData = new Blob([file], { type: file.type });
  //     const reader = new FileReader();

  //     reader.onload = (event: any) => {
  //       const binaryString = event.target.result;
  //       resolve(binaryString);
  //     };

  //     reader.onerror = (event: any) => {
  //       reject(event.target.error);
  //     };

  //     reader.readAsBinaryString(blobData);
  //   });
  // };

  const submit = async () => {
    // const binaryData =
    //   files.length > 0 ? await convertToBinaryString(files[0]) : undefined;
    const res = await axiosInstance.patch<OfficeResponse | ErrorResponse>(
      OfficeURI(id),
      {
        office_category_id: selectedOfficeCategory?.id,
        group_id: selectedGroup?.id,
        name: office?.name,
        postal_code: office?.postalCode,
        prefecture: selectedPrefectureId,
        district: office?.district,
        address: office?.address,
        building: office?.building,
        is_participated_to_minna_map: office?.isParticipatedToMinnaMap,
        lat_lng:
          searchedLatLng && searchedLatLng.lat && searchedLatLng.lng
            ? `${searchedLatLng?.lat},${searchedLatLng?.lng}`
            : null,
      } as UpdateOfficeParams
    );

    if (res.status === 200) {
      navigate("/offices");
    } else {
      setErrorMessage((res.data as ErrorResponse).message!);
      setErrorMessageOpen(true);
      setTimeout(() => {
        setErrorMessageOpen(false);
      }, 5000);
    }
  };

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const onLoad = (mapProp: google.maps.Map) => {
    // map.current = mapProp;
    setMap(mapProp);
    const geocoder = new window.google.maps.Geocoder();
    setGeocoder(geocoder);
  };

  const searchLatLng = async (address: string): Promise<void> => {
    if (geocoder) {
      await geocoder.geocode({ address: address }, (results, status) => {
        if (status === "OK" && results && results.length > 0) {
          const location = results[0].geometry.location;
          setSearchedLatLng({
            lat: location.lat(),
            lng: location.lng(),
          });
          setZoom(15);
        } else {
          console.error(
            "Geocode was not successful for the following reason: " + status
          );
        }
      });
    }
  };

  const setFullAddress = async () => {
    let fullAddress = "";
    if (office?.postalCode !== null && office?.postalCode) {
      fullAddress += office.postalCode;
    }
    if (selectedPrefectureName !== null) {
      fullAddress += selectedPrefectureName;
    }
    if (office?.district !== null && office?.district) {
      fullAddress += office.district;
    }
    if (office?.address !== null && office?.address) {
      fullAddress += office.address;
    }
    if (office?.building !== null && office?.building) {
      fullAddress += office.building;
    }

    await searchLatLng(fullAddress);
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyB0OuoBvX7M_hV9t4fL2RMuLkpviT84gK8",
    libraries: ["places"],
    language: "ja",
  });

  const options: google.maps.MapOptions = {
    scaleControl: true,
    mapTypeId: "roadmap",
    mapTypeControl: false,
    fullscreenControl: false,
    center: searchedLatLng,

    zoomControl: true,
    zoom: zoom,
    streetViewControl: false,
  };

  useEffect(() => {
    if (map) {
      if (searchedLatLng) {
        map.panTo(searchedLatLng);
      }
    }
  }, [map, searchedLatLng]);

  // const handleDragEnter = (e: any) => {
  //   e.preventDefault();
  //   if (dragging! && files.length === 0) {
  //     setDragging(true);
  //   }
  // };

  // const handleDragLeave = (e: any) => {
  //   e.preventDefault();
  //   setDragging(false);
  // };

  // const handleDragOver = (e: any) => {
  //   e.preventDefault();
  // };

  // const handleDrop = (e: any) => {
  //   e.preventDefault();
  //   setDragging(false);
  //   setZipErrorMessage(undefined);

  //   if (files.length === 0) {
  //     const droppedFiles: File[] = Array.from(e.dataTransfer.files);
  //     if (droppedFiles.length > 0) {
  //       if (droppedFiles[0].type === "application/zip") {
  //         setFiles([...files, ...droppedFiles]);
  //       } else {
  //         setZipErrorMessage("zip形式のみアップロードできます。");
  //       }
  //     }
  //   }
  // };

  // const removeFile = (index: any) => {
  //   const newFiles = [...files];
  //   newFiles.splice(index, 1);
  //   setFiles(newFiles);
  // };

  return (
    <>
      <Card mb={6}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item md={3}>
              {selectedOfficeCategory && officeCategoryList && (
                <FormControl my={2}>
                  <Autocomplete
                    disablePortal
                    id="office-category-list-select"
                    fullWidth
                    defaultValue={{
                      id: selectedOfficeCategory.id,
                      label: selectedOfficeCategory.label,
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="事業所カテゴリ" required />
                    )}
                    options={officeCategoryList.map((item) => {
                      return { id: item.id, label: item.name };
                    })}
                    onChange={(event, target) => {
                      if (target) {
                        setSelectedOfficeCategory({
                          id: target.id,
                          label: target.label,
                        });
                      }
                    }}
                  />
                </FormControl>
              )}
            </Grid>
            <Grid item md={3}>
              {selectedGroup && groupList && (
                <FormControl my={2}>
                  <Autocomplete
                    disablePortal
                    id="group-list-select"
                    fullWidth
                    defaultValue={{
                      id: selectedGroup.id,
                      label: selectedGroup.label,
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="グループ" required />
                    )}
                    options={groupList.map((item) => {
                      return { id: item.id, label: item.name };
                    })}
                    onChange={(event, target) => {
                      if (target) {
                        setSelectedGroup({
                          id: target.id,
                          label: target.label,
                        });
                      }
                    }}
                  />
                </FormControl>
              )}
            </Grid>
          </Grid>
          <FormControl fullWidth my={2} variant="outlined">
            <TextField
              id="name"
              label="事業所名"
              required
              variant="outlined"
              defaultValue={office?.name}
              value={office?.name}
              fullWidth
              my={2}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                if (office) setOffice({ ...office, name: e.target?.value });
              }}
            />
          </FormControl>

          <Grid container spacing={6}>
            <Grid item md={3}>
              <TextField
                id="postal-code"
                label="郵便番号"
                required
                variant="outlined"
                defaultValue={office?.postalCode}
                value={office?.postalCode}
                fullWidth
                my={2}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  if (office)
                    setOffice({ ...office, postalCode: e.target?.value });
                }}
                onBlur={async (e) => {
                  setLoading(true);
                  await getAddressInfosByPoseCode(e.target?.value)
                    .then(async (addressInfos) => {
                      if (addressInfos.length > 0) {
                        const keys = Object.keys(addressInfos[0]);
                        let prefectureInfos: any[] = [];
                        let district: string = "";
                        let prefectureId: string = "";
                        let prefectureName: string = "";
                        keys.forEach((key) => {
                          if (key === "address1") {
                            prefectureInfos = PrefectureList.filter(
                              (list) => list.label === addressInfos[0][key]
                            );
                            if (prefectureInfos.length > 0) {
                              prefectureId = prefectureInfos[0].id;
                            }
                            prefectureName = addressInfos[0][key];
                            setSelectedPrefectureName(prefectureName);
                          }

                          if (key === "address2") {
                            district = addressInfos[0][key];
                          }

                          if (key === "address3") {
                            district = district + addressInfos[0][key];
                          }

                          if (key === "prefcode") {
                            if (prefectureInfos.length > 0) {
                              if (
                                prefectureInfos[0].prefecture_id !==
                                addressInfos[0][key]
                              ) {
                                prefectureId = prefectureInfos[0].id;
                              }
                            }
                          }
                        });
                        setSelectedPrefectureId(prefectureId);
                        if (office) {
                          setOffice({ ...office, district: district });
                          let fullAddress =
                            e.target.value + prefectureName + district;

                          if (office.address !== null && office.address) {
                            fullAddress += office.address;
                          }
                          if (office.building !== null && office.building) {
                            fullAddress += office.building;
                          }

                          await searchLatLng(fullAddress);
                        }
                        setLoading(false);
                      }
                    })
                    .catch((err) => {
                      setLoading(false);
                    });
                  setLoading(false);
                }}
              />
            </Grid>
            <Grid item md={3}>
              {selectedPrefectureId && (
                <FormControl my={2}>
                  <InputLabel id="prefecture-list-select-label" required>
                    都道府県
                  </InputLabel>
                  <Select
                    required
                    labelId="prefecture-list-select-label"
                    id="prefecture-list-select"
                    value={
                      selectedPrefectureId === null ? "" : selectedPrefectureId
                    }
                    label="都道府県"
                    defaultValue={
                      selectedPrefectureId === null ? "" : selectedPrefectureId
                    }
                    fullWidth
                    onChange={(e) => {
                      setSelectedPrefectureId(e.target.value);
                    }}
                    onBlur={async (e) => {
                      await setFullAddress();
                    }}
                  >
                    {PrefectureList.map((prefecture, i) => {
                      return (
                        <MenuItem key={prefecture.id} value={prefecture.id}>
                          {prefecture.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
            </Grid>
            <Grid item md={3}>
              {isLoaded && !loading && (
                <GoogleMap
                  onLoad={onLoad}
                  mapContainerStyle={{
                    width:
                      searchedLatLng && searchedLatLng.lat && searchedLatLng.lng
                        ? "100%"
                        : 0,
                    height:
                      searchedLatLng && searchedLatLng.lat && searchedLatLng.lng
                        ? "300px"
                        : 0,
                  }}
                  options={options}
                  zoom={15}
                >
                  {searchedLatLng && searchedLatLng.lat && searchedLatLng.lng && (
                    <Marker
                      position={{
                        lat: searchedLatLng.lat,
                        lng: searchedLatLng.lng,
                      }}
                    />
                  )}
                </GoogleMap>
              )}
            </Grid>
          </Grid>

          <FormControl fullWidth my={2} variant="outlined">
            <TextField
              id="district"
              label="市町村区"
              required
              variant="outlined"
              defaultValue={office?.district === null ? "" : office?.district}
              value={office?.district === null ? "" : office?.district}
              fullWidth
              my={2}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                if (office) setOffice({ ...office, district: e.target?.value });
              }}
              onBlur={async (e) => {
                await setFullAddress();
              }}
            />
          </FormControl>

          <FormControl fullWidth my={2} variant="outlined">
            <TextField
              id="address"
              label="番地"
              required
              variant="outlined"
              defaultValue={office?.address}
              value={office?.address}
              fullWidth
              my={2}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                if (office) setOffice({ ...office, address: e.target?.value });
              }}
              onBlur={async (e) => {
                await setFullAddress();
              }}
            />
          </FormControl>

          <FormControl fullWidth my={2} variant="outlined">
            <TextField
              id="building"
              label="建物名・階数・部屋番号"
              variant="outlined"
              defaultValue={office?.building}
              value={office?.building}
              fullWidth
              my={2}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                if (office) setOffice({ ...office, building: e.target?.value });
              }}
              onBlur={async (e) => {
                await setFullAddress();
              }}
            />
          </FormControl>
          {/* {user?.role === "admin" && (
            <FormControl>
              <InputLabel>オフィスイメージ</InputLabel>
              <Box
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                style={{
                  width: "100%",
                  height: "20%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: dragging ? "#c4c4c4" : "white",
                  border: "2px dashed #c4c4c4",
                  outline: "none",
                }}
                marginBottom={"20px"}
              >
                <Paper
                  elevation={3}
                  style={{
                    padding: "20px",
                    backgroundColor: dragging ? "#c4c4c4" : "white",
                  }}
                >
                  {files.length === 0 && (
                    <Typography
                      variant="body2"
                      bgcolor={dragging ? "#c4c4c4" : "white"}
                    >
                      zip形式のファイルをここにドラッグしてください。
                    </Typography>
                  )}
                  {zipErrorMessage && (
                    <Typography
                      variant="body2"
                      bgcolor={dragging ? "#c4c4c4" : "white"}
                      color={"#fb0f3a"}
                    >
                      {zipErrorMessage}
                    </Typography>
                  )}
                  {files.map((file, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <Typography
                        variant="body1"
                        style={{ marginRight: "10px" }}
                      >
                        {file.name}
                      </Typography>
                      <button onClick={() => removeFile(index)}>X</button>
                    </div>
                  ))}
                </Paper>
              </Box>
            </FormControl>
          )} */}
          <Button variant="contained" color="primary" onClick={() => submit()}>
            変更を保存する
          </Button>
          {user?.role === "admin" && (
            <Box display="flex" gap={8} justifyContent="flex-end">
              <Box gap={9} padding={2}>
                <Box
                  display="flex"
                  gap={8}
                  paddingBottom={2}
                  justifyContent="flex-end"
                >
                  <Typography fontSize={14}>総合マップ</Typography>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Typography fontSize={14}>不参加</Typography>
                    <AntSwitch
                      // defaultChecked
                      inputProps={{ "aria-label": "ant design" }}
                      checked={office?.isParticipatedToMinnaMap ? true : false}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (office) {
                          setOffice({
                            ...office,
                            isParticipatedToMinnaMap: e.target?.checked,
                          });
                        }
                      }}
                    />
                    <Typography fontSize={14}>参加</Typography>
                  </Stack>
                </Box>
              </Box>
            </Box>
          )}
        </CardContent>
        {loading && (
          <CardContent>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress />
            </Backdrop>
          </CardContent>
        )}
      </Card>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={errorMessageOpen}
        autoHideDuration={6000}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

const OfficesEditPage = () => {
  const location = useLocation();
  const pathNames = location.pathname.split("/").filter((x) => x);
  const id = pathNames[pathNames.length - 1];

  return (
    <React.Fragment>
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            事業所編集
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              ダッシュボード
            </Link>
            <Link component={NavLink} to="/offices">
              事業所一覧
            </Link>
            <Typography>事業所編集</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={6} />

      <OfficesEditPageContent id={id} />
    </React.Fragment>
  );
};

export default OfficesEditPage;
