import Axios from "axios";
import { getBffUri } from "./uriHelper";
import camelcaseKeys from "camelcase-keys";

const baseURL = getBffUri();

export const axiosInstance = Axios.create({
  baseURL,
  headers: {
    "X-API-KEY": process.env.REACT_APP_API_KEY || "",
  },
});

axiosInstance.interceptors.response.use(
  (response) => {
    const { data } = response;
    const convertedData = camelcaseKeys(data, { deep: true });
    return { ...response, data: convertedData };
  },
  (error) => {
    // return Promise.reject(
    //   (error.response && error.response.message) || "Something went wrong"
    // );

    return error.response;
  }
);

// export default axiosInstance;
