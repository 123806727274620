import React, { useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Switch,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import { axiosInstance } from "../../utils/axios";
import { PlanNewURI, PlanResponse } from "../../types/api/plan";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)<{ m?: number }>`
  min-width: 240px;
  width: 100%;
`;

interface ButtonProps extends SpacingProps {
  component?: string;
}
const Button = styled(MuiButton)<ButtonProps>(spacing);

const planTypeList = [{ id: "pro", name: "プロ" }];

function PlansNewPageContent() {
  const [selectedPlanTypeId, setSelectedPlanTypeId] = useState<string>("pro");
  const [nameValue, setNameValue] = useState<string>();
  const [enabled, setEnabled] = React.useState<boolean>(false);
  const [priceValue, setPriceValue] = React.useState<number | null>(null);
  const navigate = useNavigate();

  const submit = async () => {
    const res = await axiosInstance.post<PlanResponse>(PlanNewURI, {
      name: nameValue,
      plan_type: selectedPlanTypeId,
      enabled: enabled,
      price: priceValue,
    });
    if (res.data?.id) navigate("/plans");
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Grid container spacing={6}>
          <Grid item md={3}>
            <FormControl my={2}>
              <InputLabel id="plan-type-list-select-label">
                プランタイプ
              </InputLabel>
              <Select
                labelId="plan-type-list-select-label"
                id="plan-type-list-select"
                value={selectedPlanTypeId}
                label="プランタイプ"
                fullWidth
                onChange={(e) => {
                  setSelectedPlanTypeId(e.target.value);
                }}
              >
                {planTypeList.map((planType, i) => {
                  return (
                    <MenuItem key={planType.id} value={planType.id}>
                      {planType.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={3}>
            <FormControl fullWidth my={2} variant="outlined">
              <TextField
                id="name"
                label="プラン名"
                variant="outlined"
                defaultValue={nameValue}
                fullWidth
                onChange={(e) => {
                  setNameValue(e.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={3}>
            <FormControl fullWidth my={2} variant="outlined">
              <TextField
                id="price"
                label="価格"
                variant="outlined"
                defaultValue={priceValue}
                fullWidth
                onChange={(e) => {
                  setPriceValue(Number(e.target.value));
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={3}>
            <FormControlLabel
              control={
                <Switch
                  checked={enabled}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setEnabled(e.target.checked);
                  }}
                  name="enabled"
                />
              }
              label="有効/無効"
            />
          </Grid>
        </Grid>
        <Button
          my={2}
          variant="contained"
          color="primary"
          onClick={() => submit()}
        >
          作成する
        </Button>
      </CardContent>
    </Card>
  );
}

function PlansNewPage() {
  return (
    <React.Fragment>
      <Typography variant="h3" gutterBottom display="inline">
        新規プラン追加
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          ダッシュボード
        </Link>
        <Link component={NavLink} to="/plans">
          プラン一覧
        </Link>
        <Typography>新規プラン追加</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <PlansNewPageContent />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default PlansNewPage;
