import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router";

export const useTracker = () => {
  const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_KEY;
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (process.env.REACT_APP_ENV === "prod" && TRACKING_ID) {
      ReactGA.initialize(TRACKING_ID);
    }
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA.send(location.pathname + location.search);
    }
  }, [location, initialized]);
};
