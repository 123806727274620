import { CamelCasedPropertiesDeep } from "type-fest";
import { components, operations } from "../../schema";

// URI
export const GroupListURI = "/admin/group/list";
export const GroupListTopDownURI = "/admin/group/topdown/list-all";
export const GroupNewURI = "/admin/group/new";
export const GroupEditURI = (id: string) => `/admin/group/${id}`;
export const GroupURI = (id: string) => `/admin/group/${id}`;
export const GroupDeleteURI = (id: string) => `/admin/group/${id}`;
export const GroupIdNameListURI = "/admin/group/id-name/list-all";
export const GroupsUploadCsvURI = "/admin/group/csv";
export const GroupsDownloadCsvURI = "/admin/group/csv";
export const GroupsGenerateCsvURI = "/admin/group/generate-csv";

// Request
export type FetchGroupListParams =
  operations["admin__get_group_list"]["parameters"]["query"];
export type GroupNewParams = components["schemas"]["RequestBody_AddNewGroup"];
export type GroupEditParams = components["schemas"]["RequestBody_UpdateGroup"];

// Response
export type FetchGroupListResponse = CamelCasedPropertiesDeep<
  components["schemas"]["Response_GroupList"]
>;
export type GroupNewResponse = CamelCasedPropertiesDeep<
  components["schemas"]["Response_Group"]
>;
export type GroupEditResponse = CamelCasedPropertiesDeep<
  components["schemas"]["Response_Group"]
>;
export type GroupResponse = CamelCasedPropertiesDeep<
  components["schemas"]["Response_Group"]
>;
export type GroupDeleteResponse = CamelCasedPropertiesDeep<
  components["schemas"]["Response_Group"]
>;
export type GroupsUploadCsvResponse = CamelCasedPropertiesDeep<
  components["schemas"]["Response_UploadCSV"]
>;
export type ArrayGroup = CamelCasedPropertiesDeep<
  components["schemas"]["Array_Group"]
>;
export type ObjectGroup = CamelCasedPropertiesDeep<
  components["schemas"]["Object_Group"]
>;
export type FetchGroupIdNameListResponse = CamelCasedPropertiesDeep<
  components["schemas"]["Response_IDNameList"]
>;

export const InitialObjectGroup: ObjectGroup = {
  id: "",
  createdAt: 0,
  updatedAt: 0,
  name: "",
};

export const InitialObjectGroups: ArrayGroup = [InitialObjectGroup];
