import { OfficeDeviceResponse } from "../types/api/officeDevice";

export const getDeviceSensorStatus = (officeDevice: OfficeDeviceResponse) => {
  const latestDeviceLogTimestamp = officeDevice.latestDeviceLog?.timestampValue;
  if (!latestDeviceLogTimestamp) return false;
  const latestDeviceLogDate = new Date(latestDeviceLogTimestamp * 1000);
  const comparisonDate = new Date();
  comparisonDate.setMinutes(comparisonDate.getMinutes() - 30);
  return latestDeviceLogDate >= comparisonDate;
};
