import * as React from "react";
import { Grid } from "@mui/material";
import useAuth from "../../../hooks/useAuth";
import OfficeDashboard from "./OfficeDashboard";
import Offices from "./Offices";
import AirQualigtyViewer from "./AirQualityViewer";

function Default() {
  const { user } = useAuth();

  return (
    <React.Fragment>
      {user && ["manager", "admin"].includes(user.role) && (
        <>
          <Grid container spacing={6}>
            <Grid item xs={12} lg={12}>
              <Offices />
            </Grid>
          </Grid>
          <AirQualigtyViewer />
        </>
      )}
      <OfficeDashboard />
    </React.Fragment>
  );
}

export default Default;
