import { CamelCasedPropertiesDeep } from "type-fest";
import { components, operations } from "../../schema";

// URI
export const OfficeCategoryListURI = "/admin/office-category/list";
export const OfficeCategoryNewURI = "/admin/office-category/new";
export const OfficeCategoryEditURI = (id: string) =>
  `/admin/office-category/${id}`;
export const OfficeCategoryURI = (id: string) => `/admin/office-category/${id}`;
export const OfficeCategoryDeleteURI = (id: string) =>
  `/admin/office-category/${id}`;

// Request
export type FetchOfficeCategoryListParams =
  operations["admin__get_office_category_list"]["parameters"]["query"];
export type OfficeCategoryNewParams =
  components["schemas"]["RequestBody_AddNewOfficeCategory"];
export type OfficeCategoryEditParams =
  components["schemas"]["RequestBody_UpdateOfficeCategory"];

// Response
export type FetchOfficeCategoryListResponse = CamelCasedPropertiesDeep<
  components["schemas"]["Response_OfficeCategoryList"]
>;
export type OfficeCategoryNewResponse = CamelCasedPropertiesDeep<
  components["schemas"]["Response_OfficeCategory"]
>;
export type OfficeCategoryEditResponse = CamelCasedPropertiesDeep<
  components["schemas"]["Response_OfficeCategory"]
>;
export type OfficeCategoryResponse = CamelCasedPropertiesDeep<
  components["schemas"]["Response_OfficeCategory"]
>;
export type OfficeCategoryDeleteResponse = CamelCasedPropertiesDeep<
  components["schemas"]["Response_OfficeCategory"]
>;
export type ArrayGroup = CamelCasedPropertiesDeep<
  components["schemas"]["Array_OfficeCategory"]
>;
export type ObjectGroup = CamelCasedPropertiesDeep<
  components["schemas"]["Object_OfficeCategory"]
>;
export type FetchOfficeCategoryIdNameListResponse = CamelCasedPropertiesDeep<
  components["schemas"]["Response_IDNameList"]
>;

export type ObjectOfficeCategory = CamelCasedPropertiesDeep<
  components["schemas"]["Object_OfficeCategory"]
>;

export const InitialObjectOfficeCategory: ObjectOfficeCategory = {
  id: "",
  createdAt: 0,
  updatedAt: 0,
  name: "",
};
