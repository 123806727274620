import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";

import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridValueFormatterParams,
  jaJP,
} from "@mui/x-data-grid";
import { spacing } from "@mui/system";
import { Add as AddIcon } from "@mui/icons-material";
import { axiosInstance } from "../../utils/axios";
import {
  FetchPlanListResponse,
  PlanListURI,
  PlanResponse,
  PlanURI,
} from "../../types/api/plan";
import EditButton from "../../components/buttons/EditButton";
import DeleteButton from "../../components/buttons/DeleteButton";
import { CustomToolbar } from "../../components/tables/Toolbar";
import { formatDateFromTimestamp } from "../../utils/dateHelper";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

const PlansPageContent = () => {
  const [data, setData] = useState<FetchPlanListResponse["objects"] | []>([]);
  const [deletedDataId, setDeletedDataId] = useState<string[]>([]);
  const handleDelete = async (id: string) => {
    const res = await axiosInstance.delete<PlanResponse>(PlanURI(id));
    if (res.status === 200) {
      setDeletedDataId([...deletedDataId, id]);
    }
  };

  const columns: GridColDef[] = [
    // 編集ボタン
    {
      field: "editBtn",
      headerName: "編集",
      editable: false,
      sortable: false,
      filterable: false,
      width: 60,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => <EditButton href={`/plans/${params.id}`} />,
    },
    // 削除ボタン
    {
      field: "deleteBtn",
      headerName: "削除",
      editable: false,
      sortable: false,
      filterable: false,
      width: 60,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <DeleteButton id={params.id as string} callback={handleDelete} />
      ),
    },
    {
      field: "id",
      headerName: "ID",
      width: 150,
      editable: false,
      hide: true,
    },
    {
      field: "name",
      headerName: "プラン名",
      width: 200,
      editable: false,
    },
    {
      field: "planType",
      headerName: "タイプ",
      width: 200,
      editable: false,
    },
    {
      field: "price",
      headerName: "価格",
      width: 200,
      editable: false,
    },
    {
      field: "enabled",
      headerName: "ステータス",
      width: 200,
      editable: false,
    },
    {
      field: "createdAt",
      headerName: "作成日時",
      type: "dateTime",
      width: 150,
      editable: false,
      valueFormatter: (params: GridValueFormatterParams) => {
        return formatDateFromTimestamp(params.value);
      },
    },
    {
      field: "updatedAt",
      headerName: "更新日時",
      type: "datetime",
      width: 150,
      editable: false,
      valueFormatter: (params: GridValueFormatterParams) => {
        return formatDateFromTimestamp(params.value);
      },
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const res = await axiosInstance.get<FetchPlanListResponse>(PlanListURI);

      setData(res.data.objects);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (deletedDataId) {
      setData(data.filter((element) => !deletedDataId.includes(element.id)));
    }
  }, [deletedDataId]);

  return (
    <Card mb={6}>
      <Paper>
        <div style={{ height: 800, width: "100%" }}>
          <DataGrid
            rowsPerPageOptions={[50, 100]}
            rows={data}
            columns={columns}
            pageSize={5}
            checkboxSelection={false}
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
            components={{ Toolbar: CustomToolbar }}
            initialState={{
              sorting: {
                sortModel: [
                  {
                    field: "createdAt",
                    sort: "desc",
                  },
                ],
              },
            }}
          />
        </div>
      </Paper>
    </Card>
  );
};

const PlansPage = () => {
  return (
    <React.Fragment>
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            プラン一覧
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              ダッシュボード
            </Link>
            <Typography>プラン一覧</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            <Button
              component={NavLink}
              to="/plans/new"
              variant="contained"
              color="primary"
            >
              <AddIcon />
              新規追加
            </Button>
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />

      <PlansPageContent />
    </React.Fragment>
  );
};

export default PlansPage;
