import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";

import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Grid,
  FormControl as MuiFormControl,
  TextField,
  Autocomplete,
  CardContent,
} from "@mui/material";
import { spacing } from "@mui/system";
import { ErrorResponse } from "../../types/api";
import { axiosInstance } from "../../utils/axios";
import Notification, { NotificationProps } from "../../components/Notification";
import {
  formatDate,
  getBeforeDate,
  getYesterday,
} from "../../utils/dateHelper";
import { DatePicker, LoadingButton } from "@mui/lab";
import useAuth from "../../hooks/useAuth";
import {
  ArrayGroup,
  FetchGroupListResponse,
  GroupListURI,
} from "../../types/api/group";
import { ObjectIdName } from "../../types/api/idNameList";
import {
  ArrayOffice,
  OfficeListResponse,
  OfficeListURI,
} from "../../types/api/office";
import { OfficeDevicesLogGenerateCsvURI } from "../../types/api/officeDevice";
import { format } from "date-fns";
import { useRecoilState } from "recoil";
import { groupAtom } from "../../atoms/group";
import { officeAtom } from "../../atoms/office";
import { Download as DownloadIcon } from "@mui/icons-material";

const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 240px;
  width: 100%;
`;

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

type ParamsType = {
  pm25LowerLimit: number | null;
  pm25UpperLimit: number | null;
  co2LowerLimit: number | null;
  co2UpperLimit: number | null;
  temperatureLowerLimit: number | null;
  temperatureUpperLimit: number | null;
  humidityLowerLimit: number | null;
  humidityUpperLimit: number | null;
};

const initialParams = {
  pm25LowerLimit: null,
  pm25UpperLimit: null,
  co2LowerLimit: null,
  co2UpperLimit: null,
  temperatureLowerLimit: null,
  temperatureUpperLimit: null,
  humidityLowerLimit: null,
  humidityUpperLimit: null,
};

const OfficeDeviceLogDownloadPage = () => {
  const { user } = useAuth();
  const [notification, setNotification] = useState<NotificationProps>();

  const [groupList, setGroupList] = useState<ArrayGroup>([]);
  // const [groupIdNameList, setGroupIdNameList] = useState<ArrayIdName>([]);
  const [selectedGroupIdName, setSelectedGroupIdName] =
    useState<ObjectIdName>();
  const [officeList, setOfficeList] = useState<ArrayOffice>([]);
  const [selectedOfficeIdName, setSelectedOfficeIdName] =
    useState<ObjectIdName>();
  const [startDate, setStartDate] = useState<string>(
    formatDate(getBeforeDate(new Date(), 30), "yyyy-MM-dd")
  );
  const [endDate, setEndDate] = useState<string>(
    formatDate(getYesterday(new Date()), "yyyy-MM-dd")
  );
  const [currentGroup, setCurrentGroup] = useRecoilState(groupAtom);
  const [currentOffice, setCurrentOffice] = useRecoilState(officeAtom);
  const [params, setParams] = useState<ParamsType>(initialParams);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchGroupIdNameList = async () => {
    // const res = await axiosInstance.get<FetchGroupIdNameListResponse | ErrorResponse>(GroupIdNameListURI, {
    //   params: {
    //     sort_by: "created_at-desc",
    //   }
    // });
    // if (res.status === 200) {
    //   setGroupIdNameList((res.data as FetchGroupIdNameListResponse).objects);
    // }

    const res = await axiosInstance.get<FetchGroupListResponse | ErrorResponse>(
      GroupListURI,
      {
        params: {
          offset: 0,
          limit: 5000,
          sort_by: "created_at-desc",
        },
      }
    );
    if (res.status === 200) {
      setGroupList((res.data as FetchGroupListResponse).objects);
    }
  };

  const fetchOfficeIdNameList = async (groupId: string | undefined) => {
    const res = await axiosInstance.get<OfficeListResponse | ErrorResponse>(
      OfficeListURI,
      {
        params: {
          offset: 0,
          limit: 5000,
          sort_by: "created_at-desc",
          group_id: groupId,
        },
      }
    );
    if (res.status === 200) {
      setOfficeList((res.data as OfficeListResponse).objects);
    }
  };

  useEffect(() => {
    fetchGroupIdNameList();
  }, []);

  useEffect(() => {
    if (selectedGroupIdName || currentGroup.id.length > 0) {
      fetchOfficeIdNameList(selectedGroupIdName?.id || currentGroup.id);
    }
  }, [selectedGroupIdName, currentGroup]);

  const downloadCsvFile = () => {
    setLoading(true);
    axiosInstance
      .post(OfficeDevicesLogGenerateCsvURI, {
        timezone: "jst",
        group_id:
          user?.role === "admin"
            ? selectedGroupIdName?.id
            : user?.role === "manager"
            ? currentGroup.id
            : undefined,
        office_id:
          user?.role === "staff" ? currentOffice.id : selectedOfficeIdName?.id,
        start_date: startDate,
        end_date: endDate,
        pm25_lower_limit: params.pm25LowerLimit,
        pm25_upper_limit: params.pm25UpperLimit,
        co2_lower_limit: params.co2LowerLimit,
        co2_upper_limit: params.co2UpperLimit,
        temperature_lower_limit: params.temperatureLowerLimit,
        temperature_upper_limit: params.temperatureUpperLimit,
        humidity_lower_limit: params.humidityLowerLimit,
        humidity_upper_limit: params.humidityUpperLimit,
      })
      .then((response) => {
        setLoading(false);
        if (response.status === 200) {
          setNotification({
            message: "CSVダウンロードのリクエストを送信しました",
            isOpen: true,
            type: "success",
          });
        } else {
          setNotification({
            message: "CSVダウンロードのリクエストを送信に失敗しました",
            isOpen: true,
            type: "error",
          });
        }
      });
  };

  return (
    <React.Fragment>
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            センサー機器ログ情報
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              ダッシュボード
            </Link>
            <Typography>センサー機器ログ情報</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Card mb={6} style={{ maxWidth: "800px" }}>
        <CardContent>
          <Grid container display="flex" gap="16px" flexDirection="column">
            <Grid item>
              <Grid container display="flex" gap="16px">
                {user?.role === "admin" && (
                  <Grid item flex={1}>
                    <FormControl fullWidth style={{ minWidth: "300px" }}>
                      <Autocomplete
                        disablePortal
                        id="group-list-select"
                        fullWidth
                        size="small"
                        renderInput={(params) => (
                          <TextField {...params} label="グループ" />
                        )}
                        options={groupList.map((item) => {
                          return { id: item.id, label: item.name };
                        })}
                        onChange={(event, target) => {
                          if (target) {
                            setSelectedGroupIdName({
                              id: target.id,
                              name: target.label,
                            });
                          } else {
                            setSelectedGroupIdName(undefined);
                          }
                        }}
                      />
                    </FormControl>
                  </Grid>
                )}
                {(user?.role === "admin" || user?.role === "manager") && (
                  <Grid item flex={1}>
                    <FormControl fullWidth style={{ minWidth: "300px" }}>
                      <Autocomplete
                        disablePortal
                        id="office-list-select"
                        fullWidth
                        size="small"
                        renderInput={(params) => (
                          <TextField {...params} label="オフィス" />
                        )}
                        options={officeList.map((item) => {
                          return { id: item.id, label: item.name };
                        })}
                        onChange={(event, target) => {
                          if (target) {
                            setSelectedOfficeIdName({
                              id: target.id,
                              name: target.label,
                            });
                          } else {
                            setSelectedOfficeIdName(undefined);
                          }
                        }}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item>
              <Grid container display="flex" gap="16px">
                <Grid item flex={1}>
                  <FormControl fullWidth variant="outlined">
                    <DatePicker
                      label="開始日"
                      value={startDate}
                      inputFormat={"yyyy-MM-dd"}
                      onChange={(date: Date | null) => {
                        if (date) setStartDate(format(date, "yyyy-MM-dd"));
                      }}
                      mask="____-__-__"
                      renderInput={(params) => (
                        <TextField size="small" {...params} />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item flex={1}>
                  <FormControl fullWidth variant="outlined">
                    <DatePicker
                      label="終了日"
                      value={endDate}
                      inputFormat={"yyyy-MM-dd"}
                      onChange={(date: Date | null) => {
                        if (date) setEndDate(format(date, "yyyy-MM-dd"));
                      }}
                      mask="____-__-__"
                      renderInput={(params) => (
                        <TextField size="small" {...params} />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container display="flex" gap="16px">
                <Grid item flex={1}>
                  <FormControl fullWidth variant="outlined">
                    <TextField
                      id="pm25UpperLimit"
                      label="PM2.5 以下"
                      variant="outlined"
                      inputMode="numeric"
                      defaultValue={params.pm25UpperLimit}
                      value={params.pm25UpperLimit}
                      fullWidth
                      onChange={(e) => {
                        setParams({
                          ...params,
                          pm25UpperLimit: Number(e.target.value) || null,
                        });
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item flex={1}>
                  <FormControl fullWidth variant="outlined">
                    <TextField
                      id="pm25LowerLimit"
                      label="PM2.5 以上"
                      variant="outlined"
                      inputMode="numeric"
                      defaultValue={params.pm25LowerLimit}
                      value={params.pm25LowerLimit}
                      fullWidth
                      onChange={(e) => {
                        setParams({
                          ...params,
                          pm25LowerLimit: Number(e.target.value) || null,
                        });
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container display="flex" gap="16px">
                <Grid item flex={1}>
                  <FormControl fullWidth variant="outlined">
                    <TextField
                      id="co2UpperLimit"
                      label="CO2 以下"
                      variant="outlined"
                      inputMode="numeric"
                      // defaultValue={params.co2UpperLimit}
                      value={params.co2UpperLimit}
                      fullWidth
                      onChange={(e) => {
                        setParams({
                          ...params,
                          co2UpperLimit: Number(e.target.value) || null,
                        });
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item flex={1}>
                  <FormControl fullWidth variant="outlined">
                    <TextField
                      id="co2LowerLimit"
                      label="CO2 以上"
                      variant="outlined"
                      inputMode="numeric"
                      defaultValue={params.co2LowerLimit}
                      value={params.co2LowerLimit}
                      fullWidth
                      onChange={(e) => {
                        setParams({
                          ...params,
                          co2LowerLimit: Number(e.target.value) || null,
                        });
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container display="flex" gap="16px">
                <Grid item flex={1}>
                  <FormControl fullWidth variant="outlined">
                    <TextField
                      id="temperatureUpperLimit"
                      label="温度 以下"
                      variant="outlined"
                      inputMode="numeric"
                      defaultValue={params.temperatureUpperLimit}
                      value={params.temperatureUpperLimit}
                      fullWidth
                      onChange={(e) => {
                        setParams({
                          ...params,
                          temperatureUpperLimit: Number(e.target.value) || null,
                        });
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item flex={1}>
                  <FormControl fullWidth variant="outlined">
                    <TextField
                      id="temperatureLowerLimit"
                      label="温度 以上"
                      variant="outlined"
                      inputMode="numeric"
                      defaultValue={params.temperatureLowerLimit}
                      value={params.temperatureLowerLimit}
                      fullWidth
                      onChange={(e) => {
                        setParams({
                          ...params,
                          temperatureLowerLimit: Number(e.target.value) || null,
                        });
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container display="flex" gap="16px">
                <Grid item flex={1}>
                  <FormControl fullWidth variant="outlined">
                    <TextField
                      id="humidityUpperLimit"
                      label="湿度 以下"
                      variant="outlined"
                      inputMode="numeric"
                      defaultValue={params.humidityUpperLimit}
                      value={params.humidityUpperLimit}
                      fullWidth
                      onChange={(e) => {
                        setParams({
                          ...params,
                          humidityUpperLimit: Number(e.target.value) || null,
                        });
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item flex={1}>
                  <FormControl fullWidth variant="outlined">
                    <TextField
                      id="humidityLowerLimit"
                      label="湿度 以上"
                      variant="outlined"
                      inputMode="numeric"
                      defaultValue={params.humidityLowerLimit}
                      value={params.humidityLowerLimit}
                      fullWidth
                      onChange={(e) => {
                        setParams({
                          ...params,
                          humidityLowerLimit: Number(e.target.value) || null,
                        });
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container>
                <Grid item>
                  <LoadingButton
                    variant="contained"
                    component="label"
                    color="primary"
                    onClick={downloadCsvFile}
                    loading={loading}
                  >
                    <DownloadIcon />
                    CSVデータ
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {notification && (
        <Notification
          message={notification.message || ""}
          type={notification.type}
          isOpen={notification.isOpen}
          onClose={() =>
            setNotification({
              message: "",
              isOpen: false,
              type: "info",
            })
          }
        />
      )}
    </React.Fragment>
  );
};

export default OfficeDeviceLogDownloadPage;
