import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";

import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Grid,
  Button,
  FormControl,
  TextField,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridValueFormatterParams,
  jaJP,
} from "@mui/x-data-grid";
import { spacing } from "@mui/system";
import {
  Add as AddIcon,
  Upload as UploadIcon,
  Download as DownloadIcon,
} from "@mui/icons-material";
import { axiosInstance } from "../../utils/axios";
import { ErrorResponse } from "../../types/api";
import EditButton from "../../components/buttons/EditButton";
import DeleteButton from "../../components/buttons/DeleteButton";
import Notification, { NotificationProps } from "../../components/Notification";
import useAuth from "../../hooks/useAuth";
import { CustomToolbar } from "../../components/tables/Toolbar";
import {
  FetchGroupListResponse,
  GroupDeleteResponse,
  GroupDeleteURI,
  GroupListURI,
  GroupResponse,
  GroupsDownloadCsvURI,
  GroupsGenerateCsvURI,
  GroupsUploadCsvResponse,
  GroupsUploadCsvURI,
} from "../../types/api/group";
import { formatDate, formatDateFromTimestamp } from "../../utils/dateHelper";
import { downloadFile } from "../../utils/file";
import { scroll2Top } from "../../utils/dataGridHelper";
import { LoadingButton } from "@mui/lab";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

type Props = {
  officeGroupName?: string;
};

const GroupsPageContent = ({ officeGroupName }: Props) => {
  const { user } = useAuth();
  const [errorMessageOpen, setErrorMessageOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [data, setData] = useState<FetchGroupListResponse["objects"] | []>([]);

  const [deletedDataId, setDeletedDataId] = useState<string[]>([]);
  const handleDelete = async (id: string) => {
    const res = await axiosInstance.delete<GroupDeleteResponse | ErrorResponse>(
      GroupDeleteURI(id)
    );

    if (res.status === 200) {
      setDeletedDataId([...deletedDataId, id]);
    } else {
      setErrorMessage((res.data as ErrorResponse).message!);
      setErrorMessageOpen(true);
      setTimeout(() => {
        setErrorMessageOpen(false);
      }, 5000);
    }
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 300,
      editable: false,
      filterable: true,
      sortable: false,
      hide: true,
    },
    {
      field: "parentGroupId",
      headerName: "親グループ名",
      width: 200,
      editable: false,
      filterable: true,
      sortable: true,
      valueGetter: (params) => {
        const group = params.row as GroupResponse;
        return group.parentGroup?.name;
      },
    },
    {
      field: "name",
      headerName: "グループ名",
      width: 200,
      editable: false,
      filterable: true,
      sortable: true,
    },
    {
      field: "createdAt",
      headerName: "作成日時",
      type: "dateTime",
      width: 150,
      editable: false,
      filterable: true,
      sortable: true,
      valueFormatter: (params: GridValueFormatterParams) => {
        return formatDateFromTimestamp(params.value);
      },
    },
    {
      field: "updatedAt",
      headerName: "更新日時",
      type: "datetime",
      width: 150,
      editable: false,
      filterable: true,
      sortable: true,
      valueFormatter: (params: GridValueFormatterParams) => {
        return formatDateFromTimestamp(params.value);
      },
    },
  ];

  if (user?.role === "admin") {
    columns.unshift(
      // 削除ボタン
      {
        field: "deleteBtn",
        headerName: "削除",
        sortable: false,
        filterable: false,
        width: 60,
        align: "center",
        headerAlign: "center",
        renderCell: (params) => (
          <DeleteButton id={params.id as string} callback={handleDelete} />
        ),
      }
    );
    columns.unshift(
      // 削除ボタン
      {
        field: "editBtn",
        headerName: "編集",
        sortable: false,
        filterable: false,
        width: 60,
        align: "center",
        headerAlign: "center",
        renderCell: (params) => <EditButton href={`/groups/${params.id}`} />,
      }
    );
  }

  const [pageSize, setPageSize] = useState<number>(50);
  const [page, setPage] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const handlePageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
  };
  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const fetchData = async (
    officeGroupName: string | undefined,
    pageSize: number,
    page: number
  ) => {
    setLoading(true);
    const res = await axiosInstance.get<FetchGroupListResponse | ErrorResponse>(
      GroupListURI,
      {
        params: {
          offset: page * pageSize,
          limit: pageSize,
          sort_by: "created_at-desc",
          keyword: officeGroupName,
        },
      }
    );

    if (res.status === 200) {
      const responseData = res.data as FetchGroupListResponse;
      setData(responseData.objects);
      setTotal(responseData.paging.total);
      setLoading(false);
    } else {
      setErrorMessage((res.data as ErrorResponse).message!);
      setErrorMessageOpen(true);
      setTimeout(() => {
        setErrorMessageOpen(false);
      }, 5000);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!loading) {
      scroll2Top();
    }
  }, [loading]);

  // useEffect(() => {
  //   fetchData(pageSize, page);
  // }, []);

  let filterTimeout: NodeJS.Timeout | undefined = undefined;
  useEffect(() => {
    if (filterTimeout) clearTimeout(filterTimeout);
    filterTimeout = setTimeout(() => {
      fetchData(officeGroupName, pageSize, page);
    });
  }, [officeGroupName, pageSize, page]);

  useEffect(() => {
    if (deletedDataId) {
      setData(data.filter((element) => !deletedDataId.includes(element.id)));
    }
  }, [deletedDataId]);

  return (
    <>
      <Card mb={6}>
        <Paper>
          <div style={{ height: 800, width: "100%" }}>
            <DataGrid
              rowsPerPageOptions={[50, 100]}
              rows={data}
              columns={columns}
              pageSize={pageSize}
              rowCount={total}
              page={page}
              checkboxSelection={false}
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
              components={{ Toolbar: CustomToolbar }}
              initialState={{
                sorting: {
                  sortModel: [
                    {
                      field: "createdAt",
                      sort: "desc",
                    },
                  ],
                },
              }}
              loading={loading}
              pagination
              paginationMode="server"
              onPageSizeChange={handlePageSizeChange}
              onPageChange={handlePageChange}
            />
          </div>
        </Paper>
      </Card>
      <Notification
        message={errorMessage}
        isOpen={errorMessageOpen}
        onClose={() => {
          setErrorMessageOpen(false);
        }}
      />
    </>
  );
};

const GroupsPage = () => {
  const { user } = useAuth();
  const [refetch, setRefetch] = useState(false);
  const [officeGroupName, setOfficeGroupName] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [notification, setNotification] = useState<NotificationProps>();

  const changeUploadFile = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { files } = event.target;

    if (files && files.length > 0) {
      const data = new FormData();
      data.append("csv_file", files[0]);
      const headers = { "content-type": "multipart/form-data" };

      const res = await axiosInstance.post<GroupsUploadCsvResponse>(
        GroupsUploadCsvURI,
        data,
        { headers }
      );
      if (res.status === 200) {
        setRefetch(true);
      }
    }

    event.target.value = "";
  };

  const downloadCsvTemplateFile = () => {
    setLoading(true);
    axiosInstance.post(GroupsGenerateCsvURI, {}).then((response) => {
      setLoading(false);
      if (response.status === 200) {
        setNotification({
          message: "CSVダウンロードのリクエストを送信しました",
          isOpen: true,
          type: "success",
        });
      } else {
        setNotification({
          message: "CSVダウンロードのリクエストを送信に失敗しました",
          isOpen: true,
          type: "error",
        });
      }
    });
  };

  useEffect(() => {
    setRefetch(false);
  }, []);

  return (
    <>
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            事業所グループ一覧
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              ダッシュボード
            </Link>
            <Typography>事業所グループ一覧</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item pl={2}>
              <FormControl fullWidth style={{ minWidth: "300px" }}>
                <TextField
                  id="name"
                  label="グループ名"
                  placeholder="グループ名で検索"
                  variant="outlined"
                  value={officeGroupName}
                  fullWidth
                  onChange={(e) => {
                    if (e.target.value) {
                      setOfficeGroupName(e.target?.value.toString());
                    } else {
                      setOfficeGroupName("");
                    }
                  }}
                  size="small"
                />
              </FormControl>
            </Grid>
            <Grid item pl={2}>
              {user && ["admin"].includes(user.role) && (
                <div>
                  <Button
                    component={NavLink}
                    to="/groups/new"
                    variant="contained"
                    color="primary"
                  >
                    <AddIcon />
                    新規追加
                  </Button>
                </div>
              )}
            </Grid>
            {user?.role === "admin" && (
              <Grid item pl={2}>
                <Button variant="contained" component="label" color="primary">
                  <UploadIcon />
                  CSVアップロード
                  <input type="file" hidden onChange={changeUploadFile} />
                </Button>
              </Grid>
            )}

            {user?.role === "admin" && (
              <Grid item pl={2}>
                <LoadingButton
                  variant="contained"
                  component="label"
                  color="primary"
                  onClick={downloadCsvTemplateFile}
                  loading={loading}
                >
                  <DownloadIcon />
                  CSV一括ダウンロード
                </LoadingButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Divider my={6} />

      <GroupsPageContent officeGroupName={officeGroupName} />

      {notification && (
        <Notification
          message={notification.message || ""}
          type={notification.type}
          isOpen={notification.isOpen}
          onClose={() =>
            setNotification({
              message: "",
              isOpen: false,
              type: "info",
            })
          }
        />
      )}
    </>
  );
};

export default GroupsPage;
