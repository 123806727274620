import { Box, Stack, Typography } from "@mui/material";
import { addDays } from "date-fns";
import {
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  startOfMonth,
  startOfWeek,
} from "date-fns/esm";
import { MinMaxDataType } from "../pages/pages/Reports";
import { ChartProps } from "./FirstChart";

type Props = {
  groupedData: any;
  Chart: ({ date, groupedData }: ChartProps) => JSX.Element;
  legendLeft: String;
  legendRight: String;
  selectedDate: Date;
  minMaxData: MinMaxDataType;
};

export const ChartCalender = ({
  groupedData,
  Chart,
  legendLeft,
  legendRight,
  selectedDate,
  minMaxData,
}: Props) => {
  const firstDate = startOfMonth(selectedDate);
  const lastDate = endOfMonth(selectedDate);
  const startDate = startOfWeek(firstDate);
  const endDate = endOfWeek(lastDate);
  const displayDays = eachDayOfInterval(
    { start: startDate, end: endDate },
    { step: 7 }
  );

  return (
    <Box>
      <Stack marginBottom={4} width="100%" paddingX={2} direction={"row"}>
        <span style={{ color: "#ff9800" }}>{legendLeft}</span>
        <span style={{ marginLeft: "auto", color: "#4caf50" }}>
          {legendRight}
        </span>
      </Stack>
      <Stack width="100%" alignItems={"left"} direction="row">
        <Typography fontWeight={"600"} marginLeft={"96px"}>
          日
        </Typography>
        <Typography fontWeight={"600"} marginLeft={"130px"}>
          月
        </Typography>
        <Typography fontWeight={"600"} marginLeft={"125px"}>
          火
        </Typography>
        <Typography fontWeight={"600"} marginLeft={"125px"}>
          水
        </Typography>
        <Typography fontWeight={"600"} marginLeft={"130px"}>
          木
        </Typography>
        <Typography fontWeight={"600"} marginLeft={"125px"}>
          金
        </Typography>
        <Typography fontWeight={"600"} marginLeft={"128px"}>
          土
        </Typography>
      </Stack>
      {displayDays.map((day) => {
        return (
          <Stack direction={"column"}>
            <Stack direction={"row"}>
              <Chart
                date={day}
                groupedData={groupedData}
                selectedDate={selectedDate}
                minMaxData={minMaxData}
              />
              <Chart
                date={addDays(day, 1)}
                groupedData={groupedData}
                selectedDate={selectedDate}
                minMaxData={minMaxData}
              />
              <Chart
                date={addDays(day, 2)}
                groupedData={groupedData}
                selectedDate={selectedDate}
                minMaxData={minMaxData}
              />
              <Chart
                date={addDays(day, 3)}
                groupedData={groupedData}
                selectedDate={selectedDate}
                minMaxData={minMaxData}
              />
              <Chart
                date={addDays(day, 4)}
                groupedData={groupedData}
                selectedDate={selectedDate}
                minMaxData={minMaxData}
              />
              <Chart
                date={addDays(day, 5)}
                groupedData={groupedData}
                selectedDate={selectedDate}
                minMaxData={minMaxData}
              />
              <Chart
                date={addDays(day, 6)}
                groupedData={groupedData}
                selectedDate={selectedDate}
                minMaxData={minMaxData}
              />
            </Stack>
          </Stack>
        );
      })}
      <Stack width="100%" alignItems={"left"} direction="row">
        <Typography fontSize={4} marginLeft={"30px"}>
          0
        </Typography>
        {[1, 2, 3, 4, 5, 6, 7].map(() => (
          <>
            <Typography fontSize={4} marginLeft={"30px"}>
              6
            </Typography>
            <Typography fontSize={4} marginLeft={"25px"}>
              12
            </Typography>
            <Typography fontSize={4} marginLeft={"25px"}>
              18
            </Typography>
            <Typography fontSize={4} marginLeft={"20px"}>
              24
            </Typography>
          </>
        ))}
      </Stack>
    </Box>
  );
};
