import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Switch,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import { axiosInstance } from "../../utils/axios";
import { PlanResponse, PlanURI } from "../../types/api/plan";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)<{ m?: number }>`
  min-width: 240px;
  width: 100%;
`;

interface ButtonProps extends SpacingProps {
  component?: string;
}
const Button = styled(MuiButton)<ButtonProps>(spacing);
const planTypeList = [{ id: "pro", name: "プロ" }];

type Props = {
  id: string;
};

const PlansEditPageContent = ({ id }: Props) => {
  const [selectedPlanTypeId, setSelectedPlanTypeId] = useState<string>("pro");
  const [plan, setPlan] = useState<PlanResponse>();
  const [enabled, setEnabled] = React.useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const planResponse = await axiosInstance.get<PlanResponse>(PlanURI(id));
      setPlan(planResponse.data);
    };

    fetchData();
  }, [id]);

  const submit = async () => {
    const res = await axiosInstance.patch<PlanResponse>(PlanURI(id), {
      name: plan?.name,
      // plan_type: selectedPlanTypeId,
      enabled: enabled,
      price: plan?.price,
    });
    if (res.data?.id) navigate("/plans");
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Grid container spacing={6}>
          <Grid item md={3}>
            <FormControl my={2}>
              <InputLabel id="plan-type-list-select-label">
                プランタイプ
              </InputLabel>
              <Select
                labelId="plan-type-list-select-label"
                id="plan-type-list-select"
                value={selectedPlanTypeId}
                label="プランタイプ"
                fullWidth
                onChange={(e) => {
                  setSelectedPlanTypeId(e.target.value);
                }}
              >
                {planTypeList.map((planType, i) => {
                  return (
                    <MenuItem key={planType.id} value={planType.id}>
                      {planType.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={3}>
            <FormControl fullWidth my={2} variant="outlined">
              <TextField
                id="name"
                label="プラン名"
                variant="outlined"
                defaultValue={plan?.name}
                value={plan?.name}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  if (plan) setPlan({ ...plan, name: e.target?.value });
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={3}>
            <FormControl fullWidth my={2} variant="outlined">
              <TextField
                id="price"
                label="価格"
                variant="outlined"
                defaultValue={plan?.price}
                value={plan?.price}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  if (plan)
                    setPlan({ ...plan, price: Number(e.target?.value) });
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={3}>
            <FormControlLabel
              control={
                <Switch
                  checked={enabled}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setEnabled(e.target.checked);
                  }}
                  name="enabled"
                />
              }
              label="有効/無効"
            />
          </Grid>
        </Grid>
        <Button
          my={2}
          variant="contained"
          color="primary"
          onClick={() => submit()}
        >
          変更を保存する
        </Button>
      </CardContent>
    </Card>
  );
};

function PlansEditPage() {
  const location = useLocation();
  const pathNames = location.pathname.split("/").filter((x) => x);
  const id = pathNames[pathNames.length - 1];

  return (
    <React.Fragment>
      <Typography variant="h3" gutterBottom display="inline">
        プラン編集
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          ダッシュボード
        </Link>
        <Link component={NavLink} to="/plans">
          プラン一覧
        </Link>
        <Typography>プラン編集</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <PlansEditPageContent id={id} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default PlansEditPage;
