import React, { useState } from "react";
import styled from "@emotion/styled";

import {
  Card as MuiCard,
  Paper as MuiPaper,
  Typography,
  Chip as MuiChip,
  CardContent,
  Box,
  Tabs,
  Tab,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridValueFormatterParams,
  jaJP,
  getGridNumericColumnOperators,
} from "@mui/x-data-grid";
import {
  OfficeDeviceListResponse,
  OfficeDeviceResponse,
} from "../../../types/api/officeDevice";
import useAuth from "../../../hooks/useAuth";
import { formatDateFromTimestamp } from "../../../utils/dateHelper";
import { InitialMessageData, MessageType } from "../../../types/message";
import { spacing, SpacingProps } from "@mui/system";
import { getDeviceSensorStatus } from "../../../utils/deviceHelper";
import { red } from "@mui/material/colors";
import { formatPrefecture } from "../../../utils/formatter";

const Card = styled(MuiCard)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Image = styled.img`
  height: 32px;
  width: 32px;
`;
interface ChipProps extends SpacingProps {
  component?: React.ElementType;
  href?: string;
  icon?: JSX.Element | null;
}
const Chip = styled(MuiChip)<ChipProps>(spacing);

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{
        flex: "1",
      }}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

const TabPanelDataGrid = (columns: any, data: any) => {
  return (
    <DataGrid
      rowsPerPageOptions={[50, 100]}
      rows={data}
      columns={columns}
      checkboxSelection={false}
      localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
      components={{ Toolbar: undefined }}
    />
  );
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

type EachLevelOfficeDevicesType = {
  [key: string]: OfficeDeviceListResponse["objects"] | [];
};
type Props = {
  eachLevelOfficeDevices: EachLevelOfficeDevicesType;
};

const OfficeDevicesPageContent = ({ eachLevelOfficeDevices }: Props) => {
  const { user } = useAuth();
  const [message, setMessage] = useState<MessageType>(InitialMessageData);
  const [data, setData] = useState<OfficeDeviceListResponse["objects"] | []>(
    []
  );
  const [tabIndex, setTabIndex] = React.useState<number>(0);

  const handleTabIndexChange = (
    event: React.SyntheticEvent,
    newIndex: number
  ) => {
    setTabIndex(newIndex);
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 300,
      hide: true,
      sortable: false,
      filterable: false,
    },
    {
      field: "macAddress",
      headerName: "MACアドレス",
      width: 300,
      hide: true,
      sortable: true,
      filterable: true,
      filterOperators: getGridNumericColumnOperators().filter(
        (operator) => operator.value === "include"
      ),
    },
    {
      field: "group",
      headerName: "グループ",
      width: 200,
      editable: false,
      sortable: false,
      filterable: false,
      valueGetter: (params) => {
        const officeDevice = params.row as OfficeDeviceResponse;
        return officeDevice.office.group.name;
      },
    },
    {
      field: "officeName",
      headerName: "事業所",
      width: 200,
      editable: false,
      sortable: true,
      filterable: false,
      valueGetter(params) {
        const officeDevice = params.row as OfficeDeviceResponse;
        return officeDevice.office.name;
      },
    },
    {
      field: "placeName",
      headerName: "設置場所",
      width: 200,
      hide: true,
      editable: false,
      sortable: false,
      filterable: false,
      valueGetter(params) {
        const officeDevice = params.row as OfficeDeviceResponse;
        return officeDevice.placeName;
      },
    },
    {
      field: "prefecture",
      headerName: "都道府県",
      width: 200,
      editable: false,
      sortable: false,
      filterable: false,
      valueGetter(params) {
        const officeDevice = params.row as OfficeDeviceResponse;
        return formatPrefecture(officeDevice.office.prefecture as string);
      },
    },
    {
      field: "status",
      headerName: "センサー状態",
      width: 150,
      editable: false,
      sortable: false,
      filterable: false,
      valueGetter(params) {
        const officeDevice = params.row as OfficeDeviceResponse;
        const deviceSensorStatus = getDeviceSensorStatus(officeDevice);
        return deviceSensorStatus ? "正常" : "停止";
      },
      renderCell: (params) => {
        return (
          <Chip
            label={params.value}
            color={params.value === "停止" ? "error" : "info"}
            size="small"
          />
        );
      },
    },
    {
      field: "setupDate",
      headerName: "設置日",
      width: 200,
      editable: false,
      sortable: false,
      filterable: false,
      valueGetter(params) {
        const officeDevice = params.row as OfficeDeviceResponse;
        return officeDevice.setupDate;
      },
    },
    {
      field: "createdAt",
      headerName: "作成日時",
      type: "dateTime",
      width: 150,
      hide: true,
      editable: false,
      sortable: true,
      filterable: false,
      valueFormatter: (params: GridValueFormatterParams) => {
        return formatDateFromTimestamp(params.value);
      },
    },
    {
      field: "updatedAt",
      headerName: "更新日時",
      type: "datetime",
      width: 150,
      hide: true,
      editable: false,
      sortable: true,
      filterable: false,
      valueFormatter: (params: GridValueFormatterParams) => {
        return formatDateFromTimestamp(params.value);
      },
    },
  ];

  return (
    <>
      <Card mb={6} style={{ flex: "1" }}>
        <Paper style={{ height: "100%" }}>
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                marginBottom: "16px",
              }}
            >
              <Tabs
                value={tabIndex}
                onChange={handleTabIndexChange}
                aria-label="空気質レベル別事業所一覧"
              >
                <Tab
                  icon={
                    <Image
                      alt="ImgCharacter05"
                      src={`/static/img/illustrations/ImgCharacter05.png`}
                    />
                  }
                  iconPosition="start"
                  label="Lv.5"
                  {...a11yProps(0)}
                />
                <Tab
                  icon={
                    <Image
                      alt="ImgCharacter04"
                      src={`/static/img/illustrations/ImgCharacter04.png`}
                    />
                  }
                  iconPosition="start"
                  label="Lv.4"
                  {...a11yProps(1)}
                />
                <Tab
                  icon={
                    <Image
                      alt="ImgCharacter03"
                      src={`/static/img/illustrations/ImgCharacter03.png`}
                    />
                  }
                  iconPosition="start"
                  label="Lv.3"
                  {...a11yProps(2)}
                />
                <Tab
                  icon={
                    <Image
                      alt="ImgCharacter02"
                      src={`/static/img/illustrations/ImgCharacter02.png`}
                    />
                  }
                  iconPosition="start"
                  label="Lv.2"
                  {...a11yProps(3)}
                />
                <Tab
                  icon={
                    <Image
                      alt="ImgCharacter01"
                      src={`/static/img/illustrations/ImgCharacter01.png`}
                    />
                  }
                  iconPosition="start"
                  label="Lv.1"
                  {...a11yProps(4)}
                />
                <Tab label="停止中" {...a11yProps(5)} />
              </Tabs>
            </Box>
            <TabPanel value={tabIndex} index={0}>
              {eachLevelOfficeDevices["level5"] &&
                TabPanelDataGrid(columns, eachLevelOfficeDevices["level5"])}
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              {eachLevelOfficeDevices["level4"] &&
                TabPanelDataGrid(columns, eachLevelOfficeDevices["level4"])}
            </TabPanel>
            <TabPanel value={tabIndex} index={2}>
              {eachLevelOfficeDevices["level3"] &&
                TabPanelDataGrid(columns, eachLevelOfficeDevices["level3"])}
            </TabPanel>
            <TabPanel value={tabIndex} index={3}>
              {eachLevelOfficeDevices["level2"] &&
                TabPanelDataGrid(columns, eachLevelOfficeDevices["level2"])}
            </TabPanel>
            <TabPanel value={tabIndex} index={4}>
              {eachLevelOfficeDevices["level1"] &&
                TabPanelDataGrid(columns, eachLevelOfficeDevices["level1"])}
            </TabPanel>
            <TabPanel value={tabIndex} index={5}>
              {eachLevelOfficeDevices["level0"] &&
                TabPanelDataGrid(columns, eachLevelOfficeDevices["level0"])}
            </TabPanel>
          </div>
        </Paper>
      </Card>
    </>
  );
};

const OfficeListWithAirQuality = ({ eachLevelOfficeDevices }: Props) => {
  return (
    <React.Fragment>
      <Card mb={1} style={{ height: "100%" }}>
        <CardContent
          style={{ height: "100%", display: "flex", flexDirection: "column" }}
        >
          <Typography variant="h6" mb="16px" fontWeight="bold">
            空気質レベル別事業所一覧
          </Typography>
          <OfficeDevicesPageContent
            eachLevelOfficeDevices={eachLevelOfficeDevices}
          />
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default OfficeListWithAirQuality;
