import { atom } from "recoil";
import {
  InitialObjectOffice,
  ObjectOffice,
  InitialObjectOffices,
  ArrayOffice,
} from "../types/api/office";

export const officeAtom = atom<ObjectOffice>({
  key: "officeAtom",
  default: InitialObjectOffice,
});

export const officesAtom = atom<ArrayOffice>({
  key: "officesAtom",
  default: InitialObjectOffices,
});
