import { Alert, Snackbar } from "@mui/material";

export type NotificationProps = {
  message: string;
  isOpen: boolean;
  type?: "success" | "info" | "warning" | "error";
  onClose?: () => void;
};

const Notification = ({
  message,
  isOpen,
  type,
  onClose,
}: NotificationProps) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={isOpen}
      onClose={() => {
        // setOpen(false);
        onClose && onClose();
      }}
      autoHideDuration={5000}
    >
      <Alert severity={type || "error"} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Notification;
