import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import {
  Autocomplete,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import {
  DeviceCategoryListResponse,
  DeviceCategoryListURI,
  DeviceEditResponse,
  DeviceEditURI,
  DeviceResponse,
  DeviceURI,
  ErrorResponse,
} from "../../types/api";
import { axiosInstance } from "../../utils/axios";
import Notification from "../../components/Notification";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)<{ m?: number }>`
  min-width: 240px;
  width: 100%;
`;

interface ButtonProps extends SpacingProps {
  component?: string;
}
const Button = styled(MuiButton)<ButtonProps>(spacing);

type Props = {
  id: string;
};

const DevicesEditPageContent = ({ id }: Props) => {
  const [errorMessageOpen, setErrorMessageOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [deviceCategoryList, setDeviceCategoryList] = useState<
    DeviceCategoryListResponse["objects"]
  >([]);
  const [selectedDeviceCategory, setSelectedDeviceCategory] =
    useState<{ id: string; label: string }>();
  const [device, setDevice] = useState<DeviceResponse>();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const deviceCategoryListResponse = await axiosInstance.get<
        DeviceCategoryListResponse | ErrorResponse
      >(DeviceCategoryListURI, {});

      if (deviceCategoryListResponse.status === 200) {
        setDeviceCategoryList(
          (deviceCategoryListResponse.data as DeviceCategoryListResponse)
            .objects
        );
      } else {
        setErrorMessage(
          (deviceCategoryListResponse.data as ErrorResponse).message!
        );
        setErrorMessageOpen(true);
        setTimeout(() => {
          setErrorMessageOpen(false);
        }, 5000);
      }

      const deviceResponse = await axiosInstance.get<
        DeviceResponse | ErrorResponse
      >(DeviceURI(id));

      if (deviceResponse.status === 200) {
        const device = deviceResponse.data as DeviceResponse;
        setDevice(device);
        setSelectedDeviceCategory({
          id: device.deviceCategory.id,
          label: device.deviceCategory.name,
        });
      } else {
        setErrorMessage((deviceResponse.data as ErrorResponse).message!);
        setErrorMessageOpen(true);
        setTimeout(() => {
          setErrorMessageOpen(false);
        }, 5000);
      }
    };

    fetchData();
  }, [id]);

  const submit = async () => {
    const res = await axiosInstance.patch<DeviceEditResponse | ErrorResponse>(
      DeviceEditURI(id),
      {
        device_category_id: selectedDeviceCategory?.id,
        name: device?.name,
        maker: device?.maker,
        maintenance_frequency: device?.maintenanceFrequency,
      }
    );

    if (res.status === 200) {
      navigate("/devices");
    } else {
      setErrorMessage((res.data as ErrorResponse).message!);
      setErrorMessageOpen(true);
      setTimeout(() => {
        setErrorMessageOpen(false);
      }, 5000);
    }
  };

  return (
    <>
      <Card mb={6}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item md={3}>
              <FormControl my={2}>
                {deviceCategoryList && selectedDeviceCategory && (
                  <Autocomplete
                    disablePortal
                    id="device-category-list-select"
                    fullWidth
                    defaultValue={{
                      id: selectedDeviceCategory.id,
                      label: selectedDeviceCategory.label,
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="デバイスカテゴリ"
                        required
                      />
                    )}
                    options={deviceCategoryList.map((item) => {
                      return { id: item.id, label: item.name };
                    })}
                    onChange={(event, target) => {
                      if (target) {
                        setSelectedDeviceCategory({
                          id: target.id,
                          label: target.label,
                        });
                      }
                    }}
                  />
                )}
              </FormControl>
            </Grid>
          </Grid>
          <FormControl fullWidth my={2} variant="outlined">
            <TextField
              id="name"
              label="デバイス名"
              required
              variant="outlined"
              value={device?.name}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              my={2}
              onChange={(e) => {
                if (device) setDevice({ ...device, name: e.target?.value });
              }}
            />
          </FormControl>
          <FormControl fullWidth my={2} variant="outlined">
            <TextField
              id="maker"
              label="メーカー"
              required
              variant="outlined"
              value={device?.maker}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              my={2}
              onChange={(e) => {
                if (device) setDevice({ ...device, maker: e.target?.value });
              }}
            />
          </FormControl>
          <FormControl fullWidth my={2} variant="outlined">
            <TextField
              id="maintenance_frequency"
              label="メンテナンス頻度"
              variant="outlined"
              inputMode="numeric"
              value={device?.maintenanceFrequency}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              my={2}
              onChange={(e) => {
                if (device)
                  setDevice({
                    ...device,
                    maintenanceFrequency: Number(e.target?.value),
                  });
              }}
            />
          </FormControl>

          <Button variant="contained" color="primary" onClick={() => submit()}>
            保存する
          </Button>
        </CardContent>
      </Card>
      <Notification
        message={errorMessage}
        isOpen={errorMessageOpen}
        onClose={() => {
          setErrorMessageOpen(false);
        }}
      />
    </>
  );
};

function DevicesEditPage() {
  const location = useLocation();
  const pathNames = location.pathname.split("/").filter((x) => x);
  const id = pathNames[pathNames.length - 1];

  return (
    <React.Fragment>
      <Typography variant="h3" gutterBottom display="inline">
        デバイス編集
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          ダッシュボード
        </Link>
        <Link component={NavLink} to="/devices">
          デバイス一覧
        </Link>
        <Typography>デバイス編集</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <DevicesEditPageContent id={id} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default DevicesEditPage;
