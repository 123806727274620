import { CamelCasedPropertiesDeep } from "type-fest";
import { components } from "../../schema";

// Plan List
export const PlanListURI = "/admin/plan/list";
export const PlanNewURI = "/admin/plan/new";
export const PlanURI = (id: string) => `/admin/plan/${id}`;

// RequestBody
export type AddNewPlanParams = components["schemas"]["RequestBody_AddNewPlan"];
export type UpdatePlanParams = components["schemas"]["RequestBody_UpdatePlan"];

// Response
export type FetchPlanListResponse = CamelCasedPropertiesDeep<
  components["schemas"]["Response_PlanList"]
>;
export type PlanResponse = CamelCasedPropertiesDeep<
  components["schemas"]["Response_Plan"]
>;
