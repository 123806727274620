import React, { useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  Link,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import { axiosInstance } from "../../utils/axios";
import { ErrorResponse } from "../../types/api";
import Notification from "../../components/Notification";
import {
  OfficeCategoryNewParams,
  OfficeCategoryNewResponse,
  OfficeCategoryNewURI,
} from "../../types/api/officeCategory";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const FormControl = styled(MuiFormControl)<{ my?: number }>(spacing);
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}
const Button = styled(MuiButton)<ButtonProps>(spacing);

const OfficeCategoriesNewPageContent = () => {
  const navigate = useNavigate();
  const [errorMessageOpen, setErrorMessageOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [nameValue, setNameValue] = useState<String | null>(null);

  const submit = async () => {
    const res = await axiosInstance.post<
      OfficeCategoryNewResponse | ErrorResponse
    >(OfficeCategoryNewURI, {
      name: nameValue,
    } as OfficeCategoryNewParams);

    if (res.status === 200) {
      navigate("/categories");
    } else {
      setErrorMessage((res.data as ErrorResponse).message!);
      setErrorMessageOpen(true);
      setTimeout(() => {
        setErrorMessageOpen(false);
      }, 5000);
    }
  };

  return (
    <>
      <Card mb={6}>
        <CardContent>
          <FormControl fullWidth my={2} variant="outlined">
            <TextField
              id="name"
              label="事業所カテゴリ"
              required
              variant="outlined"
              defaultValue={nameValue}
              fullWidth
              my={2}
              onChange={(e) => {
                setNameValue(e.target.value);
              }}
            />
          </FormControl>

          <Button variant="contained" color="primary" onClick={() => submit()}>
            作成する
          </Button>
        </CardContent>
      </Card>
      <Notification
        message={errorMessage}
        isOpen={errorMessageOpen}
        onClose={() => {
          setErrorMessageOpen(false);
        }}
      />
    </>
  );
};

const OfficeCategoriesNewPage = () => {
  return (
    <React.Fragment>
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            事業所カテゴリー作成
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              ダッシュボード
            </Link>
            <Link component={NavLink} to="/categories">
              事業所カテゴリー一覧
            </Link>
            <Typography>事業所カテゴリー作成</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={6} />

      <OfficeCategoriesNewPageContent />
    </React.Fragment>
  );
};

export default OfficeCategoriesNewPage;
