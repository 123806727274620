import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";

import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridValueFormatterParams,
  GridValueGetterParams,
  jaJP,
} from "@mui/x-data-grid";
import { spacing } from "@mui/system";
import {
  Add as AddIcon,
  Upload as UploadIcon,
  Download as DownloadIcon,
} from "@mui/icons-material";
import { ErrorResponse } from "../../types/api";
import { axiosInstance } from "../../utils/axios";
import EditButton from "../../components/buttons/EditButton";
import DeleteButton from "../../components/buttons/DeleteButton";
import Notification from "../../components/Notification";
import { CustomToolbar } from "../../components/tables/Toolbar";
import { formatDate, formatDateFromTimestamp } from "../../utils/dateHelper";
import {
  AlertSettingDeleteResponse,
  AlertSettingDeleteURI,
  AlertSettingDownloadCsvURI,
  AlertSettingListURI,
  AlertSettingUploadCsvResponse,
  AlertSettingUploadCsvURI,
  FetchAlertSettingListParams,
  FetchAlertSettingListResponse,
} from "../../types/api/alertSetting";
import { useRecoilState } from "recoil";
import { officeAtom } from "../../atoms/office";
import { groupAtom } from "../../atoms/group";
import useAuth from "../../hooks/useAuth";
import { ObjectOffice } from "../../types/api/office";
import { InitialMessageData, MessageType } from "../../types/message";
import { downloadFile } from "../../utils/file";
import { scroll2Top } from "../../utils/dataGridHelper";
import { LoadingButton } from "@mui/lab";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

function AlertSettingsPageContent() {
  const { user } = useAuth();
  const [currentGroup, setCurrentGroup] = useRecoilState(groupAtom);
  const [currentOffice, setCurrentOffice] = useRecoilState(officeAtom);
  const [message, setMessage] = useState<MessageType>(InitialMessageData);
  const [data, setData] = useState<
    FetchAlertSettingListResponse["objects"] | []
  >([]);
  const [deletedDataId, setDeletedDataId] = useState<string[]>([]);
  const handleDelete = async (id: string) => {
    const res = await axiosInstance.delete<AlertSettingDeleteResponse>(
      AlertSettingDeleteURI(id)
    );
    if (res.status === 200) {
      setDeletedDataId([...deletedDataId, id]);
    }
  };
  const columns: GridColDef[] = [
    // 編集ボタン
    {
      field: "editBtn",
      headerName: "編集",
      editable: false,
      sortable: false,
      filterable: false,
      width: 60,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <EditButton href={`/alert-setting/${params.id}`} />
      ),
    },
    // 削除ボタン
    {
      field: "deleteBtn",
      headerName: "削除",
      editable: false,
      sortable: false,
      filterable: false,
      width: 60,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <DeleteButton id={params.id as string} callback={handleDelete} />
      ),
    },
    {
      field: "id",
      headerName: "ID",
      width: 300,
      hide: true,
    },
    {
      field: "office",
      headerName: "オフィス",
      width: 200,
      editable: false,
      sortable: false,
      filterable: false,
      valueGetter: (params: GridValueGetterParams) => {
        return (params.row["office"] as ObjectOffice)?.name;
      },
    },
    {
      field: "title",
      headerName: "タイトル",
      width: 200,
      editable: false,
      sortable: false,
      filterable: false,
    },
    /* {
      field: "message",
      headerName: "メッセージ",
      width: 200,
      editable: false,
      sortable: false,
      filterable: false,
    }, */
    {
      field: "pm25LowerLimit",
      headerName: "PM2.5 以下",
      width: 100,
      editable: false,
      sortable: false,
      filterable: false,
    },
    {
      field: "pm25UpperLimit",
      headerName: "PM2.5 以上",
      width: 100,
      editable: false,
      sortable: false,
      filterable: false,
    },
    {
      field: "co2LowerLimit",
      headerName: "CO2 以下",
      width: 100,
      editable: false,
      sortable: false,
      filterable: false,
    },
    {
      field: "co2UpperLimit",
      headerName: "CO2 以上",
      width: 100,
      editable: false,
    },
    {
      field: "temperatureLowerLimit",
      headerName: "温度 以下",
      width: 100,
      editable: false,
      sortable: false,
      filterable: false,
    },
    {
      field: "temperatureUpperLimit",
      headerName: "温度 以上",
      width: 100,
      editable: false,
      sortable: false,
      filterable: false,
    },
    {
      field: "humidityLowerLimit",
      headerName: "湿度 以下",
      width: 100,
      editable: false,
      sortable: false,
      filterable: false,
    },
    {
      field: "humidityUpperLimit",
      headerName: "湿度 以上",
      width: 100,
      editable: false,
      sortable: false,
      filterable: false,
    },
    {
      field: "startTime",
      headerName: "通知時間（開始）",
      type: "dateTime",
      width: 150,
      editable: false,
      sortable: false,
      filterable: false,
    },
    {
      field: "endTime",
      headerName: "通知時間（終了）",
      type: "dateTime",
      width: 150,
      editable: false,
      sortable: false,
      filterable: false,
    },
    {
      field: "createdAt",
      headerName: "作成日時",
      type: "dateTime",
      width: 150,
      editable: false,
      sortable: false,
      filterable: false,
      valueFormatter: (params: GridValueFormatterParams) => {
        return formatDateFromTimestamp(params.value);
      },
    },
    {
      field: "updatedAt",
      headerName: "更新日時",
      type: "datetime",
      width: 150,
      editable: false,
      sortable: false,
      filterable: false,
      valueFormatter: (params: GridValueFormatterParams) => {
        return formatDateFromTimestamp(params.value);
      },
    },
  ];

  const [pageSize, setPageSize] = useState<number>(50);
  const [page, setPage] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const handlePageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
  };
  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const fetchData = async (
    groupId: string | undefined,
    officeId: string | undefined,
    pageSize: number,
    page: number
  ) => {
    setLoading(true);
    const res = await axiosInstance.get<
      FetchAlertSettingListResponse | ErrorResponse
    >(AlertSettingListURI, {
      params: {
        group_id: groupId,
        office_id: officeId,
        offset: page * pageSize,
        limit: pageSize,
        sort_by: "created_at-desc",
      } as FetchAlertSettingListParams,
    });

    if (res.status === 200) {
      const responseData = res.data as FetchAlertSettingListResponse;
      setData(responseData.objects);
      setTotal(responseData.paging.total);
      setLoading(false);
    } else {
      setMessage({
        body: (res.data as ErrorResponse).message!,
        isOpen: true,
        type: "error",
      });
      setTimeout(() => {
        setMessage({
          ...message,
          isOpen: false,
        });
      }, 5000);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!loading) {
      scroll2Top();
    }
  }, [loading]);

  useEffect(() => {
    if (user?.role === "admin") {
      fetchData(undefined, undefined, pageSize, page);
    } else if (user?.role === "manager") {
      currentGroup.id.length > 0 &&
        fetchData(currentGroup.id, undefined, pageSize, page);
    } else if (user?.role === "staff") {
      currentOffice.id.length > 0 &&
        fetchData(undefined, currentOffice.id, pageSize, page);
    }
  }, [user, currentOffice, currentGroup, pageSize, page]);

  useEffect(() => {
    if (deletedDataId) {
      setData(data.filter((element) => !deletedDataId.includes(element.id)));
    }
  }, [deletedDataId]);

  return (
    <>
      <Card mb={6}>
        <Paper>
          <div style={{ height: 800, width: "100%" }}>
            <DataGrid
              rowsPerPageOptions={[50, 100]}
              rows={data}
              columns={columns}
              pageSize={pageSize}
              rowCount={total}
              page={page}
              checkboxSelection={false}
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
              components={{ Toolbar: CustomToolbar }}
              initialState={{
                sorting: {
                  sortModel: [
                    {
                      field: "createdAt",
                      sort: "desc",
                    },
                  ],
                },
              }}
              loading={loading}
              pagination
              paginationMode="server"
              onPageSizeChange={handlePageSizeChange}
              onPageChange={handlePageChange}
            />
          </div>
        </Paper>
      </Card>
      <Notification
        message={message.body}
        isOpen={message.isOpen}
        type={message.type}
        onClose={() => {
          setMessage({
            ...message,
            isOpen: false,
          });
        }}
      />
    </>
  );
}

function AlertSettingsPage() {
  const { user } = useAuth();
  const [refetch, setRefetch] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  const changeUploadFile = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { files } = event.target;

    if (files && files.length > 0) {
      const data = new FormData();
      data.append("csv_file", files[0]);
      const headers = { "content-type": "multipart/form-data" };

      const res = await axiosInstance.post<AlertSettingUploadCsvResponse>(
        AlertSettingUploadCsvURI,
        data,
        { headers }
      );
      if (res.status === 200) {
        setRefetch(true);
      }
    }

    event.target.value = "";
  };

  const downloadCsvTemplateFile = () => {
    setLoading(true);
    axiosInstance
      .get(AlertSettingDownloadCsvURI, {
        params: {
          sort_by: "created_at-desc",
        },
      })
      .then((response) => {
        downloadFile(
          `アラート設定一覧_${formatDate(new Date(), "yyyyMMddHHmmss")}`,
          response
        );
        setLoading(false);
      });
  };

  useEffect(() => {
    setRefetch(false);
  }, []);

  return (
    <React.Fragment>
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            アラート設定一覧
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              ダッシュボード
            </Link>
            <Typography>アラート設定一覧</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item>
              <div>
                <Button
                  component={NavLink}
                  to="/alert-setting/new"
                  variant="contained"
                  color="primary"
                >
                  <AddIcon />
                  新規追加
                </Button>
              </div>
            </Grid>
            {user && ["admin"].includes(user.role) && (
              <Grid item pl={2}>
                <Button variant="contained" component="label" color="primary">
                  <UploadIcon />
                  CSVアップロード
                  <input type="file" hidden onChange={changeUploadFile} />
                </Button>
              </Grid>
            )}

            {user && ["admin"].includes(user.role) && (
              <Grid item pl={2}>
                <LoadingButton
                  variant="contained"
                  component="label"
                  color="primary"
                  onClick={downloadCsvTemplateFile}
                  loading={loading}
                >
                  <DownloadIcon />
                  CSV一括ダウンロード
                </LoadingButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Divider my={6} />
      <AlertSettingsPageContent />
    </React.Fragment>
  );
}

export default AlertSettingsPage;
