import { useEffect, useMemo, useState } from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import { green, red } from "@mui/material/colors";
import {
  Card as MuiCard,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
} from "@mui/material";
import { spacing } from "@mui/system";

import { ErrorResponse } from "../../../types/api";
import { axiosInstance } from "../../../utils/axios";
import useAuth from "../../../hooks/useAuth";
import { useRecoilState } from "recoil";
import { officeDeviceAtom } from "../../../atoms/officeDevice";
import { officeAtom } from "../../../atoms/office";
import Stats from "./Stats";
import {
  OfficeDeviceResponse,
  OfficeDeviceURI,
  ObjectOfficeDevice,
} from "../../../types/api/officeDevice";
import { getAirQualityLevel } from "../../../utils/airQualityHelper";
import React from "react";

const FormControlSpacing = styled(MuiFormControl)(spacing);

interface TooltipLabelType {
  [key: string]: string;
}

type Props = {
  officeDeviceId: string;
};

const LatestDeviceLog = ({ officeDeviceId }: Props) => {
  const { user } = useAuth();
  const [officeDeivce, setOfficeDevice] = useState<ObjectOfficeDevice>();
  const [airQualityLevel, setAirQualityLevel] = useState<number>();
  const [currentOffice, setCurrentOffice] = useRecoilState(officeAtom);
  const [currentOfficeDevice, setCurrentOfficeDevice] =
    useRecoilState(officeDeviceAtom);
  const [errorMessageOpen, setErrorMessageOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<Boolean>(false);

  const fetchOfficeDevice = async (id: string) => {
    const officeDeviceResponse = await axiosInstance.get<
      OfficeDeviceResponse | ErrorResponse
    >(OfficeDeviceURI(id));
    if (officeDeviceResponse.status === 200) {
      const officeDevice = officeDeviceResponse.data as OfficeDeviceResponse;
      setOfficeDevice(officeDevice);
    } else {
      setErrorMessage((officeDeviceResponse.data as ErrorResponse).message!);
      setErrorMessageOpen(true);
      setTimeout(() => {
        setErrorMessageOpen(false);
      }, 5000);
    }
  };

  useEffect(() => {
    if (officeDeviceId) {
      fetchOfficeDevice(officeDeviceId);
    }
  }, [officeDeviceId]);

  useEffect(() => {
    if (officeDeivce) {
      setAirQualityLevel(
        getAirQualityLevel(
          officeDeivce?.latestDeviceLog?.co2Value || -1,
          officeDeivce?.latestDeviceLog?.tvocValue || -1,
          officeDeivce?.latestDeviceLog?.pm25Value || -1
        )
      );
    }
  }, [officeDeivce]);

  return (
    <>
      {officeDeivce && (
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={6}>
            <Stats
              title="CO2"
              amount={`${officeDeivce?.latestDeviceLog?.co2Value || ""} PPM`}
              chip={`レベル ${airQualityLevel}`}
              illustration={`/static/img/illustrations/ImgCharacter0${airQualityLevel}.png`}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Stats
              title="PM2.5"
              amount={`${officeDeivce?.latestDeviceLog?.pm25Value || ""} μg/m3`}
              chip={`レベル ${airQualityLevel}`}
              illustration={`/static/img/illustrations/ImgCharacter0${airQualityLevel}.png`}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Stats
              title="TVOC"
              amount={`${officeDeivce?.latestDeviceLog?.tvocValue || ""} ppb`}
              chip={`レベル ${airQualityLevel}`}
              illustration={`/static/img/illustrations/ImgCharacter0${airQualityLevel}.png`}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Stats
              title="温度"
              amount={`${
                officeDeivce?.latestDeviceLog?.temperatureValue || ""
              } ℃`}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Stats
              title="湿度"
              amount={`${officeDeivce?.latestDeviceLog?.humidityValue || ""} %`}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};
export default React.memo(withTheme(LatestDeviceLog));
