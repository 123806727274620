export const groupBy = <K, V>(
  array: readonly V[],
  getKey: (cur: V, idx: number, src: readonly V[]) => K
): [K, V[]][] =>
  Array.from(
    array.reduce((map, cur, idx, src) => {
      const key = getKey(cur, idx, src);
      const list = map.get(key);
      if (list) list.push(cur);
      else map.set(key, [cur]);
      return map;
    }, new Map<K, V[]>())
  );

export const objGroupBy = <K extends PropertyKey, V>(
  array: readonly V[],
  getKey: (cur: V, idx: number, src: readonly V[]) => K
) => Object.fromEntries(groupBy(array, getKey)) as Partial<Record<K, V[]>>;

export const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const round = (n: number) =>
  ((m) => m * Math.round(n / m))(10 ** (`${n}`.length - 1));

export const sum = (arr: number[]) => {
  return arr.reduce((a, b) => {
    return a + b;
  });
};

export const average = (arr: number[]) => {
  return sum(arr) / arr.length;
};
