import styled from "@emotion/styled";
import { NavLink, useLocation } from "react-router-dom";
import "react-calendar/dist/Calendar.css";

import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Grid,
  Button,
  Stack,
  TextField,
  Box,
  CardContent,
  CircularProgress,
} from "@mui/material";
import { spacing } from "@mui/system";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import DownloadIcon from "@mui/icons-material/Download";
import { useEffect, useState } from "react";
import {
  ErrorResponse,
  FetchOfficeDeviceLogListParams,
  FetchOfficeDeviceLogListResponse,
  OfficeDeviceLogListURI,
} from "../../types/api";
import { axiosInstance } from "../../utils/axios";
import {
  formatDate,
  getFirstDayOfMonth,
  getLastDayOfMonth,
} from "../../utils/dateHelper";
import { objGroupBy, round } from "../../utils/other";
import {
  OfficeDeviceResponse,
  OfficeDeviceURI,
} from "../../types/api/officeDevice";
import { format } from "date-fns";
import { FirstChart } from "../../components/FirstChart";
import { ChartCalender } from "../../components/ChartCalender";
import { SecondChart } from "../../components/SecondChart";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ja from "date-fns/locale/ja";
import { resortDeviceLogArray } from "../../utils/device-log-array";
import useAuth from "../../hooks/useAuth";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

type Props = {
  id: string;
  date: Date;
};

interface IObject {
  [key: string]: {
    name: string;
    date: string;
    pm25: null | number;
    co2: null | number;
    tvoc: null | number;
    temp: null | number;
    rh: null | number;
    timestamp: null | number;
  }[];
}

export type MinMaxDataType = {
  pm25Min: number;
  pm25Max: number;
  co2Min: number;
  co2Max: number;
  tempMin: number;
  tempMax: number;
  rhMin: number;
  rhMax: number;
};

const ReportsPageContent = ({ id, date }: Props) => {
  const formattedCurrentDate = formatDate(new Date(), "yyyy-MM-dd");
  const [errorMessageOpen, setErrorMessageOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [officeDevice, setOfficeDevice] = useState<OfficeDeviceResponse>();
  const [data, setData] = useState<
    FetchOfficeDeviceLogListResponse["logs"] | any[]
  >([]);
  const [groupedData, setGroupedData] = useState<any>();
  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const [minMaxData, setMinMaxData] = useState<MinMaxDataType>();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const officeDeviceLogListResponse =
        await axiosInstance.get<FetchOfficeDeviceLogListResponse>(
          OfficeDeviceLogListURI(id),
          {
            params: {
              timezone: "jst",
              type: "list",
              start_date: formatDate(getFirstDayOfMonth(date), "yyyy-MM-dd"),
              end_date: formatDate(getLastDayOfMonth(date), "yyyy-MM-dd"),
            } as FetchOfficeDeviceLogListParams,
          }
        );
      let newLogList: any[] = [];
      officeDeviceLogListResponse.data.logs.forEach((log, index) => {
        const date = log.date;
        const pm25 = log.batteryValue > 0 ? log.pm25Value : null;
        const co2 = log.batteryValue > 0 ? log.co2Value : null;
        const tvoc = log.batteryValue > 0 ? log.tvocValue : null;
        const temp = log.batteryValue > 0 ? log.temperatureValue : null;
        const rh = log.batteryValue > 0 ? log.humidityValue : null;
        const timestamp = log.timestampValue;
        newLogList.push({
          name: "",
          date: date,
          pm25: pm25,
          co2: co2,
          tvoc: tvoc,
          temp: temp,
          rh: rh,
          timestamp: timestamp,
        });
      });
      setData(newLogList);

      const officeDeviceResponse = await axiosInstance.get<
        OfficeDeviceResponse | ErrorResponse
      >(OfficeDeviceURI(id));
      if (officeDeviceResponse.status === 200) {
        setOfficeDevice(officeDeviceResponse.data as OfficeDeviceResponse);
      } else {
        setErrorMessage((officeDeviceResponse.data as ErrorResponse).message!);
        setErrorMessageOpen(true);
        setTimeout(() => {
          setErrorMessageOpen(false);
        }, 5000);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [id, date, formattedCurrentDate]);

  useEffect(() => {
    const grouped = objGroupBy(data, (item) => item.date);
    if (grouped) {
      setGroupedData(resortDeviceLogArray(grouped as IObject));
    }
    setMinMaxData({
      pm25Min: round(Math.min(...data.map((d) => d.pm25))),
      pm25Max: round(Math.max(...data.map((d) => d.pm25))),
      co2Min: round(Math.min(...data.map((d) => d.co2))),
      co2Max: round(Math.max(...data.map((d) => d.co2))),
      tempMin: round(Math.min(...data.map((d) => d.temp))),
      tempMax: round(Math.max(...data.map((d) => d.temp))),
      rhMin: round(Math.min(...data.map((d) => d.rh))),
      rhMax: round(Math.max(...data.map((d) => d.rh))),
    });
  }, [data]);

  const month = format(date, "MM");

  return (
    <>
      {!isLoading && groupedData && officeDevice && minMaxData && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Card mb={1} id="pageContent">
            <Stack alignItems={"center"} direction="column">
              <h1 style={{ textAlign: "center" }}>{month}月</h1>
              <ChartCalender
                Chart={FirstChart}
                groupedData={groupedData}
                legendLeft="CO2 [ppm]"
                legendRight="PM2.5 [μg/m3]"
                selectedDate={date}
                minMaxData={minMaxData}
              />
            </Stack>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "16px",
                justifyContent: "right",
                padding: "16px",
              }}
            >
              <span style={{}}>{`会社名 : ${officeDevice?.office.name}`}</span>
              <span style={{}}>{`設置場所 : ${officeDevice?.placeName}`}</span>
              <span
                style={{}}
              >{`MACアドレス : ${officeDevice?.macAddress}`}</span>
              <span style={{}}>{`出力日時 : ${formatDate(
                new Date(),
                "yyyy/MM/dd HH:mm"
              )}`}</span>
            </div>
          </Card>

          <Card mb={6} id="pageContent2">
            <Stack alignItems={"center"} direction="column">
              <h1 style={{ textAlign: "center" }}>{month}月</h1>
              <ChartCalender
                Chart={SecondChart}
                groupedData={groupedData}
                legendLeft="温度 [℃]"
                legendRight="湿度 [%]"
                selectedDate={date}
                minMaxData={minMaxData}
              />
            </Stack>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "16px",
                justifyContent: "right",
                padding: "16px",
              }}
            >
              <span style={{}}>{`会社名 : ${officeDevice?.office.name}`}</span>
              <span style={{}}>{`設置場所 : ${officeDevice?.placeName}`}</span>
              <span
                style={{}}
              >{`MACアドレス : ${officeDevice?.macAddress}`}</span>
              <span style={{}}>{`出力日時 : ${formatDate(
                new Date(),
                "yyyy/MM/dd HH:mm"
              )}`}</span>
            </div>
          </Card>
        </div>
      )}

      {isLoading && (
        <CardContent
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </CardContent>
      )}
    </>
  );
};

const ReportsPage = () => {
  const { user } = useAuth();
  const location = useLocation();
  const pathNames = location.pathname.split("/").filter((x) => x);
  const id = pathNames[pathNames.length - 2];
  const [currentDate, setCurrentDate] = useState<Date>(new Date());

  const onClickHandler = () => {
    const doc = new jsPDF({
      orientation: "l",
      format: "a4",
    });
    const pageContent =
      document.querySelector<HTMLImageElement>("#pageContent");
    if (pageContent) {
      html2canvas(pageContent, { scale: 2 }).then(function (canvas) {
        const dataURI = canvas.toDataURL("image/jpeg");
        document.body.appendChild(canvas);
        const width = doc.internal.pageSize.width;
        const height = doc.internal.pageSize.height;
        doc.addImage(dataURI, "JPEG", 0, 0, width, height);
        doc.save(
          `レポート（CO2とPM25）${formatDate(currentDate, "yyyyMMdd")}.pdf`
        );
        document.body.removeChild(canvas);
      });
    }
    const pageContent2 =
      document.querySelector<HTMLImageElement>("#pageContent2");
    if (pageContent2) {
      html2canvas(pageContent2, { scale: 2 }).then(function (canvas) {
        const dataURI = canvas.toDataURL("image/jpeg");
        document.body.appendChild(canvas);
        const width = doc.internal.pageSize.width;
        const height = doc.internal.pageSize.height;
        doc.addImage(dataURI, "JPEG", 0, 0, width, height);
        doc.save(
          `レポート（温度と湿度）${formatDate(currentDate, "yyyyMMdd")}.pdf`
        );
        document.body.removeChild(canvas);
      });
    }
  };

  return (
    <>
      {user && !["admin"].includes(user.role) ? (
        <Grid justifyContent="space-between" container spacing={10}>
          <Grid item>
            <Typography variant="h3" gutterBottom display="inline">
              設置センサー機器計測レポート
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid justifyContent="space-between" container spacing={10}>
            <Grid item>
              <Typography variant="h3" gutterBottom display="inline">
                設置センサー機器計測レポート
              </Typography>

              <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Link component={NavLink} to="/">
                  ダッシュボード
                </Link>
                <Link component={NavLink} to="/office-devices">
                  設置センサー機器一覧
                </Link>
                <Typography>設置センサー機器計測レポート</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onClickHandler}
                >
                  <DownloadIcon />
                  ダウンロード
                </Button>
              </div>
            </Grid>
          </Grid>
          <Box mt={4}>
            <LocalizationProvider
              adapterLocale={ja}
              dateAdapter={AdapterDateFns}
            >
              <DatePicker
                views={["year", "month"]}
                maxDate={new Date()}
                value={currentDate}
                onChange={(newValue) => {
                  newValue && setCurrentDate(newValue);
                }}
                renderInput={(params: any) => (
                  <TextField
                    style={{ background: "white" }}
                    {...params}
                    helperText={null}
                    size="small"
                  />
                )}
                openTo={"month"}
              />
            </LocalizationProvider>
          </Box>

          <Divider my={6} />

          <ReportsPageContent date={currentDate} id={id} />
        </>
      )}
    </>
  );
};

export default ReportsPage;
