export type MessageType = {
  body: string;
  isOpen: boolean;
  type?: "success" | "info" | "warning" | "error";
};

export const InitialMessageData = {
  body: "",
  isOpen: false,
  type: "error",
} as MessageType;
