import React, { useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  Link,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import { ErrorResponse, UserNewResponse, UserNewURI } from "../../types/api";
import { axiosInstance } from "../../utils/axios";
import Notification from "../../components/Notification";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)<{ m?: number }>`
  min-width: 240px;
  width: 100%;
`;

interface ButtonProps extends SpacingProps {
  component?: string;
}
const Button = styled(MuiButton)<ButtonProps>(spacing);

function AdminsNewPageContent() {
  const [errorMessageOpen, setErrorMessageOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [emailValue, setEmailValue] = React.useState<String | null>(null);
  const [passwordValue, setPasswordValue] = React.useState<String | null>(null);
  const [lastNameValue, setLastNameValue] = React.useState<String | null>(null);
  const [firstNameValue, setFirstNameValue] = React.useState<String | null>(
    null
  );
  const [lastNameKanaValue, setLastNameKanaValue] =
    React.useState<String | null>(null);
  const [firstNameKanaValue, setFirstNameKanaValue] =
    React.useState<String | null>(null);
  const navigate = useNavigate();

  const submit = async () => {
    const res = await axiosInstance.post<UserNewResponse | ErrorResponse>(
      UserNewURI,
      {
        email: emailValue,
        password: passwordValue,
        role: "admin",
        first_name: firstNameValue,
        last_name: lastNameValue,
        first_name_kana: firstNameKanaValue,
        last_name_kana: lastNameKanaValue,
      }
    );

    if (res.status === 200) {
      navigate("/admins");
    } else {
      setErrorMessage((res.data as ErrorResponse).message!);
      setErrorMessageOpen(true);
      setTimeout(() => {
        setErrorMessageOpen(false);
      }, 5000);
    }
  };

  return (
    <>
      <Card mb={6}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item md={6}>
              <FormControl fullWidth my={2} variant="outlined">
                <TextField
                  id="email"
                  label="メールアドレス"
                  required
                  variant="outlined"
                  inputMode="email"
                  autoComplete="off"
                  defaultValue={emailValue}
                  fullWidth
                  onChange={(e) => {
                    setEmailValue(e.target.value);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl fullWidth my={2} variant="outlined">
                <TextField
                  id="password"
                  label="パスワード"
                  required
                  variant="outlined"
                  type="password"
                  autoComplete="new-password"
                  defaultValue={passwordValue}
                  fullWidth
                  onChange={(e) => {
                    setPasswordValue(e.target.value);
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item md={6}>
              <FormControl fullWidth my={2} variant="outlined">
                <TextField
                  id="last-name"
                  label="姓"
                  required
                  variant="outlined"
                  defaultValue={lastNameValue}
                  fullWidth
                  onChange={(e) => {
                    setLastNameValue(e.target.value);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl fullWidth my={2} variant="outlined">
                <TextField
                  id="first-name"
                  label="名"
                  required
                  variant="outlined"
                  defaultValue={firstNameValue}
                  fullWidth
                  onChange={(e) => {
                    setFirstNameValue(e.target.value);
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item md={6}>
              <FormControl fullWidth my={2} variant="outlined">
                <TextField
                  id="last-name"
                  label="セイ"
                  variant="outlined"
                  defaultValue={lastNameKanaValue}
                  fullWidth
                  onChange={(e) => {
                    setLastNameKanaValue(e.target.value);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl fullWidth my={2} variant="outlined">
                <TextField
                  id="first-name"
                  label="メイ"
                  variant="outlined"
                  defaultValue={firstNameKanaValue}
                  fullWidth
                  onChange={(e) => {
                    setFirstNameKanaValue(e.target.value);
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Button
            my={2}
            variant="contained"
            color="primary"
            onClick={() => submit()}
          >
            作成する
          </Button>
        </CardContent>
      </Card>
      <Notification
        message={errorMessage}
        isOpen={errorMessageOpen}
        onClose={() => {
          setErrorMessageOpen(false);
        }}
      />
    </>
  );
}

function AdminsNewPage() {
  return (
    <React.Fragment>
      <Typography variant="h3" gutterBottom display="inline">
        システム管理者追加
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          ダッシュボード
        </Link>
        <Link component={NavLink} to="/admins">
          システム管理者一覧
        </Link>
        <Typography>新規システム管理者追加</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <AdminsNewPageContent />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default AdminsNewPage;
