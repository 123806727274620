import { Marker } from "@react-google-maps/api";

export type Props = {
  id: string;
  lat: number;
  lng: number;
};

const CustomMarker = (props: Props) => {
  const { id, lat, lng } = props;

  const onMarkerClick = () => {
    window.open(`/office-devices/${id}/dashboard`, "_blank", "noreferrer");
  };

  return (
    <Marker
      key={id}
      position={{
        lat: lat,
        lng: lng,
      }}
      onClick={onMarkerClick}
    />
  );
};

export default CustomMarker;
