import { CamelCasedPropertiesDeep } from "type-fest";
import { components } from "../../schema";
import { InitialObjectDeviceCategory } from "./deviceCategory";

// URI
export const DeviceListURI = "/admin/device/list";

// Response
export type FetchDeviceListResponse = CamelCasedPropertiesDeep<
  components["schemas"]["Response_DeviceList"]
>;
export type ObjectDevice = CamelCasedPropertiesDeep<
  components["schemas"]["Object_Device"]
>;

export const InitialObjectDevice: ObjectDevice = {
  id: "",
  createdAt: 0,
  updatedAt: 0,
  deviceCategory: InitialObjectDeviceCategory,
  name: "",
  maker: "",
  maintenanceFrequency: 0,
};
