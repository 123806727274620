import { SidebarItemsType } from "../../types/sidebar";

import {
  Briefcase,
  Sliders,
  Users,
  Tool,
  MessageCircle,
  Speaker,
  User,
  Key,
  DollarSign,
  MapPin,
  List,
  Grid,
} from "react-feather";
import { NavItemsType } from "../../types/navbar";

const mainSection = [
  {
    href: "/",
    icon: Sliders,
    title: "ダッシュボード",
  },
] as SidebarItemsType[];

const adminBasicSettingsSection = [
  {
    href: "/groups",
    icon: Users,
    title: "事業所グループ",
  },
  {
    href: "/offices",
    icon: Briefcase,
    title: "事業所",
  },
  {
    href: "/office-devices",
    icon: MapPin,
    title: "センサー機器設置情報",
  },
  {
    href: "/office-devices/log/download",
    icon: List,
    title: "センサー機器ログ",
  },
] as SidebarItemsType[];

const managerBasicSettingsSection = [
  {
    href: "/groups",
    icon: Users,
    title: "事業所グループ",
  },
  {
    href: "/offices",
    icon: Briefcase,
    title: "事業所",
  },
  {
    href: "/office-devices",
    icon: MapPin,
    title: "センサー機器設置情報",
  },
  {
    href: "/office-devices/log/download",
    icon: List,
    title: "センサー機器ログ",
  },
] as SidebarItemsType[];

const staffBasicSettingsSection = [
  {
    href: "/office-devices",
    icon: MapPin,
    title: "センサー機器設置情報",
  },
  {
    href: "/office-devices/log/download",
    icon: List,
    title: "センサー機器ログ",
  },
] as SidebarItemsType[];

const adminAccountSettingsSection = [
  {
    href: "/admins",
    icon: Tool,
    title: "システム担当者",
  },
  {
    href: "/managers",
    icon: Key,
    title: "グループ管理者",
  },
  {
    href: "/staffs",
    icon: User,
    title: "事業所担当者",
  },
] as SidebarItemsType[];

const managerAccountSettingsSection = [
  {
    href: "/staffs",
    icon: User,
    title: "事業所担当者",
  },
] as SidebarItemsType[];

const adminMasterSection = [
  {
    href: "/categories",
    icon: Grid,
    title: "事業所カテゴリ",
  },
  {
    href: "/devices",
    icon: Speaker,
    title: "センサー機器",
  },
  {
    href: "/plans",
    icon: DollarSign,
    title: "プラン",
  },
] as SidebarItemsType[];

const adminOthersSection = [
  {
    href: "/alert-setting",
    icon: MessageCircle,
    title: "アラート設定",
  },
] as SidebarItemsType[];

const managerOthersSection = [
  {
    href: "/alert-setting",
    icon: MessageCircle,
    title: "アラート設定",
  },
] as SidebarItemsType[];

const staffOthersSection = [
  {
    href: "/alert-setting",
    icon: MessageCircle,
    title: "アラート設定",
  },
] as SidebarItemsType[];

export const adminNavItems = [
  {
    title: "",
    pages: mainSection,
  },
  {
    title: "基本設定",
    pages: adminBasicSettingsSection,
  },
  {
    title: "アカウント設定",
    pages: adminAccountSettingsSection,
  },
  {
    title: "マスタ情報設定",
    pages: adminMasterSection,
  },
  {
    title: "その他",
    pages: adminOthersSection,
  },
] as NavItemsType[];

export const managerNavItems = [
  {
    title: "",
    pages: mainSection,
  },
  {
    title: "基本設定",
    pages: managerBasicSettingsSection,
  },
  {
    title: "アカウント設定",
    pages: managerAccountSettingsSection,
  },
  {
    title: "その他",
    pages: managerOthersSection,
  },
] as NavItemsType[];

export const staffNavItems = [
  {
    title: "",
    pages: mainSection,
  },
  {
    title: "基本設定",
    pages: staffBasicSettingsSection,
  },
  {
    title: "その他",
    pages: staffOthersSection,
  },
] as NavItemsType[];
