import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";

import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridValueFormatterParams,
  jaJP,
} from "@mui/x-data-grid";
import { spacing } from "@mui/system";
import { Add as AddIcon } from "@mui/icons-material";
import {
  ErrorResponse,
  FetchUserListParams,
  FetchUserListResponse,
  UserDeleteResponse,
  UserDeleteURI,
  UserListURI,
} from "../../types/api";
import { axiosInstance } from "../../utils/axios";
import EditButton from "../../components/buttons/EditButton";
import DeleteButton from "../../components/buttons/DeleteButton";
import Notification from "../../components/Notification";
import { CustomToolbar } from "../../components/tables/Toolbar";
import { formatDateFromTimestamp } from "../../utils/dateHelper";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

function AdminsPageContent() {
  const [errorMessageOpen, setErrorMessageOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [data, setData] = useState<FetchUserListResponse["objects"] | []>([]);
  const [deletedDataId, setDeletedDataId] = useState<string[]>([]);
  const handleDelete = async (id: string) => {
    const res = await axiosInstance.delete<UserDeleteResponse>(
      UserDeleteURI(id)
    );
    if (res.status === 200) {
      setDeletedDataId([...deletedDataId, id]);
    }
  };
  const columns: GridColDef[] = [
    // 編集ボタン
    {
      field: "editBtn",
      headerName: "編集",
      editable: false,
      sortable: false,
      filterable: false,
      width: 60,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => <EditButton href={`/admins/${params.id}`} />,
    },
    // 削除ボタン
    {
      field: "deleteBtn",
      headerName: "削除",
      editable: false,
      sortable: false,
      filterable: false,
      width: 60,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <DeleteButton id={params.id as string} callback={handleDelete} />
      ),
    },
    {
      field: "id",
      headerName: "ID",
      width: 300,
      editable: false,
      filterable: true,
      sortable: false,
      hide: true,
    },
    {
      field: "email",
      headerName: "メールアドレス",
      width: 300,
      editable: false,
      filterable: true,
      sortable: true,
    },
    {
      field: "lastName",
      headerName: "姓",
      width: 200,
      editable: false,
      filterable: true,
      sortable: true,
    },
    {
      field: "firstName",
      headerName: "名",
      width: 200,
      editable: false,
      filterable: true,
      sortable: true,
    },
    {
      field: "lastNameKana",
      headerName: "セイ",
      width: 200,
      editable: false,
      filterable: true,
      sortable: true,
    },
    {
      field: "firstNameKana",
      headerName: "メイ",
      width: 200,
      editable: false,
      filterable: true,
      sortable: true,
    },
    {
      field: "createdAt",
      headerName: "作成日時",
      type: "dateTime",
      width: 150,
      editable: false,
      filterable: true,
      sortable: true,
      valueFormatter: (params: GridValueFormatterParams) => {
        return formatDateFromTimestamp(params.value);
      },
    },
    {
      field: "updatedAt",
      headerName: "更新日時",
      type: "datetime",
      width: 150,
      editable: false,
      filterable: true,
      sortable: true,
      valueFormatter: (params: GridValueFormatterParams) => {
        return formatDateFromTimestamp(params.value);
      },
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const res = await axiosInstance.get<
        FetchUserListResponse | ErrorResponse
      >(UserListURI, {
        params: {
          role: "admin",
          offset: 0,
          limit: 5000,
          sort_by: "created_at-desc",
        } as FetchUserListParams,
      });

      if (res.status === 200) {
        setData((res.data as FetchUserListResponse).objects);
      } else {
        setErrorMessage((res.data as ErrorResponse).message!);
        setErrorMessageOpen(true);
        setTimeout(() => {
          setErrorMessageOpen(false);
        }, 5000);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (deletedDataId) {
      setData(data.filter((element) => !deletedDataId.includes(element.id)));
    }
  }, [deletedDataId]);

  return (
    <>
      <Card mb={6}>
        <Paper>
          <div style={{ height: 800, width: "100%" }}>
            <DataGrid
              rowsPerPageOptions={[50, 100]}
              rows={data}
              columns={columns}
              pageSize={10}
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
              components={{ Toolbar: CustomToolbar }}
              initialState={{
                sorting: {
                  sortModel: [
                    {
                      field: "createdAt",
                      sort: "desc",
                    },
                  ],
                },
              }}
            />
          </div>
        </Paper>
      </Card>
      <Notification
        message={errorMessage}
        isOpen={errorMessageOpen}
        onClose={() => {
          setErrorMessageOpen(false);
        }}
      />
    </>
  );
}

function AdminsPage() {
  return (
    <React.Fragment>
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            システム管理者一覧
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              ダッシュボード
            </Link>
            <Typography>システム管理者一覧</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            <Button
              component={NavLink}
              to="/admins/new"
              variant="contained"
              color="primary"
            >
              <AddIcon />
              新規追加
            </Button>
          </div>
        </Grid>
      </Grid>
      <Divider my={6} />
      <AdminsPageContent />
    </React.Fragment>
  );
}

export default AdminsPage;
