import { CamelCasedPropertiesDeep } from "type-fest";
import { components } from "../../schema";
import { InitialObjectDevice } from "./device";
import { InitialObjectOffice } from "./office";

// URI
export const AddNewOfficeDeviceURI = "/admin/office-device/new";
export const OfficeDeviceListURI = "/admin/office-device/list";
export const OfficeDeviceURI = (id: string) => `/admin/office-device/${id}`;
export const OfficeDeviceLogDownloadURI = "/admin/office-device/log/list";
export const OfficeDevicesUploadCsvURI = "/admin/office-device/csv";
export const OfficeDevicesDownloadCsvURI = "/admin/office-device/csv";
export const OfficeDevicesLogGenerateCsvURI =
  "/admin/office-device/log/generate-csv";
export const OfficeDevicesLogCsvDownloadUrlURI =
  "/admin/office-device/log/csv-download-url";
export const OfficeDevicesGenerateCsvURI = "/admin/office-device/generate-csv";

// RequestBody
export type AddNewOfficeDeviceParams =
  components["schemas"]["RequestBody_AddNewOfficeDevice"];
export type UpdateOfficeDeviceParams =
  components["schemas"]["RequestBody_UpdateOfficeDevice"];

// Response
export type OfficeDeviceListResponse = CamelCasedPropertiesDeep<
  components["schemas"]["Response_OfficeDeviceList"]
>;
export type OfficeDeviceResponse = CamelCasedPropertiesDeep<
  components["schemas"]["Response_OfficeDevice"]
>;
export type OfficeDeviceLogListResponse = CamelCasedPropertiesDeep<
  components["schemas"]["Response_OfficeDeviceLogList"]
>;
export type OfficeDevicesUploadCsvResponse = CamelCasedPropertiesDeep<
  components["schemas"]["Response_UploadCSV"]
>;

export type ArrayOfficeDevice = CamelCasedPropertiesDeep<
  components["schemas"]["Array_OfficeDevice"]
>;
export type ObjectOfficeDevice = CamelCasedPropertiesDeep<
  components["schemas"]["Object_OfficeDevice"]
>;
export type ResponseSuccess = CamelCasedPropertiesDeep<
  components["schemas"]["Response_Success"]
>;

export const InitialObjectOfficeDevice: ObjectOfficeDevice = {
  id: "",
  createdAt: 0,
  updatedAt: 0,
  office: InitialObjectOffice,
  device: InitialObjectDevice,
  macAddress: "",
  exDeviceId: "",
  locationType: "office_address",
  placeName: "",
  setupDate: "",
};

export const InitialObjectOfficeDevices: ArrayOfficeDevice = [
  InitialObjectOfficeDevice,
];
