import styled from "@emotion/styled";
import { IconButton as MuiIconButton } from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import { spacing } from "@mui/system";
import { useNavigate } from "react-router-dom";

const IconButton = styled(MuiIconButton)(spacing);

type Props = {
  href: string;
};

const DashboardButton = ({ href }: Props) => {
  const navigate = useNavigate();
  const onClickHandler = () => {
    navigate(href);
  };

  return (
    <IconButton
      mx={2}
      aria-label="Show"
      size="large"
      color="default"
      onClick={onClickHandler}
    >
      <TuneIcon fontSize="small" />
    </IconButton>
  );
};

export default DashboardButton;
