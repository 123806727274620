import { CamelCasedPropertiesDeep } from "type-fest";
import { components, operations } from "../schema";

// Common
export type ErrorResponse = CamelCasedPropertiesDeep<
  components["schemas"]["Response_Error"]
>;

// User Me
export const UserMeURI = "/admin/user/me";
export type UserMeResponse = CamelCasedPropertiesDeep<
  components["schemas"]["Response_User"]
>;

// User List
export const UserListURI = "/admin/user/list";
export type FetchUserListParams = CamelCasedPropertiesDeep<
  operations["admin__get_user_list"]["parameters"]["query"]
>;
export type FetchUserListResponse = CamelCasedPropertiesDeep<
  components["schemas"]["Response_UserList"]
>;

// Request List
export const RequestListURI = "/admin/request/list";
export type FetchRequestListParams = CamelCasedPropertiesDeep<
  operations["admin__get_request_list"]["parameters"]["query"]
>;
export type FetchRequestListResponse = CamelCasedPropertiesDeep<
  components["schemas"]["Response_RequestList"]
>;

// Notification List
export const NotificationListURI = "/admin/notification/list";
export type FetchNotificationListParams = CamelCasedPropertiesDeep<
  operations["admin__get_notification_list"]["parameters"]["query"]
>;
export type FetchNotificationListResponse = CamelCasedPropertiesDeep<
  components["schemas"]["Response_NotificationList"]
>;

// Office Device Log List
export const OfficeDeviceLogListURI = (deviceId: string) =>
  `/admin/office-device/${deviceId}/log/list`;
export type FetchOfficeDeviceLogListParams = CamelCasedPropertiesDeep<
  operations["app__get_office_device_log_list_by_id"]["parameters"]["query"]
>;
export type FetchOfficeDeviceLogListResponse = CamelCasedPropertiesDeep<
  components["schemas"]["Response_OfficeDeviceLogList"]
>;

// Office Category List
export const OfficeCategoryListURI = "/admin/office-category/list";
export type OfficeCategoryListParams = CamelCasedPropertiesDeep<
  operations["admin__get_office_category_list"]["parameters"]["query"]
>;
export type OfficeCategoryListResponse = CamelCasedPropertiesDeep<
  components["schemas"]["Response_OfficeCategoryList"]
>;

// Device Category List
export const DeviceCategoryListURI = "/admin/device-category/list";
export type DeviceCategoryListParams = CamelCasedPropertiesDeep<
  operations["admin__get_device_category_list"]["parameters"]["query"]
>;
export type DeviceCategoryListResponse = CamelCasedPropertiesDeep<
  components["schemas"]["Response_DeviceCategoryList"]
>;

// Device New
export const DeviceNewURI = "/admin/device/new";
export type DeviceNewParams = CamelCasedPropertiesDeep<
  components["schemas"]["RequestBody_AddNewDevice"]
>;
export type DeviceNewResponse = CamelCasedPropertiesDeep<
  components["schemas"]["Response_Device"]
>;

// Device Edit
export const DeviceEditURI = (id: string) => `/admin/device/${id}`;
export type DeviceEditParams = CamelCasedPropertiesDeep<
  components["schemas"]["RequestBody_UpdateDevice"]
>;
export type DeviceEditResponse = CamelCasedPropertiesDeep<
  components["schemas"]["Response_Device"]
>;

// Device Edit
export const DeviceURI = (id: string) => `/admin/device/${id}`;
export type DeviceResponse = CamelCasedPropertiesDeep<
  components["schemas"]["Response_Device"]
>;

// Device Delete
export const DeviceDeleteURI = (id: string) => `/admin/device/${id}`;
export type DeviceDeleteResponse = CamelCasedPropertiesDeep<
  components["schemas"]["Response_Device"]
>;

// User New
export const UserNewURI = "/admin/user/new";
export type UserNewParams = CamelCasedPropertiesDeep<
  components["schemas"]["RequestBody_AddNewUser"]
>;
export type UserNewResponse = CamelCasedPropertiesDeep<
  components["schemas"]["Response_User"]
>;

// User Edit
export const UserEditURI = (userId: string) => `/admin/user/${userId}`;
export type UserEditParams = CamelCasedPropertiesDeep<
  components["schemas"]["RequestBody_UpdateUser"]
>;
export type UserEditResponse = CamelCasedPropertiesDeep<
  components["schemas"]["Response_User"]
>;

// User
export const UserURI = (userId: string) => `/admin/user/${userId}`;
export type UserResponse = CamelCasedPropertiesDeep<
  components["schemas"]["Response_User"]
>;

// User Delete
export const UserDeleteURI = (userId: string) => `/admin/user/${userId}`;
export type UserDeleteResponse = CamelCasedPropertiesDeep<
  components["schemas"]["Response_User"]
>;

export const RequestCategoryListURI = "/admin/request-category/list";
export type RequestCategoryListParams = CamelCasedPropertiesDeep<
  operations["admin__get_request_category_list"]["parameters"]["query"]
>;
export type RequestCategoryListResponse = CamelCasedPropertiesDeep<
  components["schemas"]["Response_RequestCategoryList"]
>;

// Request New
export const RequestNewURI = "/admin/request/new";
export type RequestNewParams = CamelCasedPropertiesDeep<
  components["schemas"]["RequestBody_AddNewRequest"]
>;
export type RequestNewResponse = CamelCasedPropertiesDeep<
  components["schemas"]["Response_Request"]
>;
