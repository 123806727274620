import {
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { groupAtom, groupsAtom } from "../../atoms/group";
import { useRecoilState } from "recoil";
import { Fragment, useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import { axiosInstance } from "../../utils/axios";
import {
  ArrayGroup,
  FetchGroupListResponse,
  GroupListURI,
} from "../../types/api/group";
import { ErrorResponse } from "../../types/api";

function NavbarGroupsDropdown() {
  const { user } = useAuth();
  const [currentGroup, setCurrentGroup] = useRecoilState(groupAtom);
  const [currentGroups, setCurrentGroups] = useRecoilState(groupsAtom);

  const handleChange = (
    event: SelectChangeEvent<String | null>,
    child: React.ReactNode
  ) => {
    const targetId = event.target.value as String;
    const targetGroup = currentGroups.find((group) => group.id === targetId);
    if (targetGroup) {
      setCurrentGroup(targetGroup);
    }
  };

  const fetchGroups = async () => {
    const res = await axiosInstance.get<FetchGroupListResponse | ErrorResponse>(
      GroupListURI,
      {
        params: {
          offset: 0,
          limit: 10000,
          sort_by: "created_at-desc",
        },
      }
    );

    if (res.status === 200) {
      const responseData = res.data as FetchGroupListResponse;
      const groups = responseData.objects as ArrayGroup;
      setCurrentGroups(groups);
      setCurrentGroup(groups[0]);
    }
  };

  useEffect(() => {
    if (user && ["admin"].includes(user.role)) {
      fetchGroups();
    }
  }, []);

  if (currentGroups.length > 0 && currentGroup.id.length > 0) {
    return (
      <Fragment>
        <FormControl fullWidth>
          <InputLabel id="group-select-label">事業所グループ</InputLabel>
          <Select
            labelId="group-select-label"
            id="group-select"
            value={currentGroup.id}
            label="Group"
            onChange={handleChange}
            size="small"
          >
            {currentGroups.map((group, i) => {
              return (
                <MenuItem key={group.id} value={group.id}>
                  {group.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Fragment>
    );
  }

  return <></>;
}

export default NavbarGroupsDropdown;
