import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";

import {
  Autocomplete,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import { axiosInstance } from "../../utils/axios";
import { DatePicker } from "@mui/lab";
import { format } from "date-fns";
import { OfficeListResponse, OfficeListURI } from "../../types/api/office";
import {
  AddNewOfficeDeviceURI,
  OfficeDeviceResponse,
} from "../../types/api/officeDevice";
import { DeviceListURI, FetchDeviceListResponse } from "../../types/api/device";
import { InitialMessageData, MessageType } from "../../types/message";
import { ErrorResponse } from "../../types/api";
import Notification from "../../components/Notification";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)<{ m?: number }>`
  min-width: 240px;
  width: 100%;
`;

interface ButtonProps extends SpacingProps {
  component?: string;
}
const Button = styled(MuiButton)<ButtonProps>(spacing);

const deviceLocationTypeList = [
  { id: "office_address", name: "事業所の住所" },
  { id: "manual", name: "手動で入力" },
];

function OfficeDeviceNewPageContent() {
  const [message, setMessage] = useState<MessageType>(InitialMessageData);
  const [officeList, setOfficeList] = useState<OfficeListResponse["objects"]>(
    []
  );
  const [deviceList, setDeviceList] = useState<
    FetchDeviceListResponse["objects"]
  >([]);
  const [selectedOfficeId, setSelectedOfficeId] = useState<string>();
  const [selectedDeviceId, setSelectedDeviceId] = useState<string>();
  const [macAddressValue, setMacAddressValue] = React.useState<String | null>(
    null
  );
  const [placeNameValue, setPlaceNameValue] = React.useState<String | null>(
    null
  );
  const [selectedSetupDate, setSelectedSetupDate] = React.useState<
    string | null
  >(null);
  const [selectedRemovalDate, setSelectedRemovalDate] = useState<string | null>(
    null
  );
  const [selectedDeviceLocationType, setSelectedDeviceLocationType] =
    React.useState<String | null>(null);
  const [latLngValue, setLatLngValue] = React.useState<String | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const officeListResponse = await axiosInstance.get<OfficeListResponse>(
        OfficeListURI,
        {
          params: {
            offset: 0,
            limit: 5000,
            sort_by: "created_at-desc",
          },
        }
      );
      setOfficeList(officeListResponse.data.objects);

      const deviceListResponse =
        await axiosInstance.get<FetchDeviceListResponse>(DeviceListURI, {
          params: {
            offset: 0,
            limit: 5000,
            sort_by: "created_at-desc",
          },
        });
      setDeviceList(deviceListResponse.data.objects);
    };

    fetchData();
  }, []);

  const submit = async () => {
    const res = await axiosInstance.post<OfficeDeviceResponse | ErrorResponse>(
      AddNewOfficeDeviceURI,
      {
        office_id: selectedOfficeId,
        device_id: selectedDeviceId,
        mac_address: macAddressValue,
        place_name: placeNameValue,
        setup_date: selectedSetupDate,
        location_type: selectedDeviceLocationType,
        lat_lng: latLngValue,
        removal_date: selectedRemovalDate,
      }
    );

    if (res.status === 200) {
      navigate("/office-devices");
    } else {
      setMessage({
        body: (res.data as ErrorResponse).message!,
        isOpen: true,
        type: "error",
      });
      setTimeout(() => {
        setMessage({
          ...message,
          isOpen: false,
        });
      }, 5000);
    }
  };

  return (
    <>
      <Card mb={6}>
        {officeList.length > 0 && deviceList.length > 0 && (
          <CardContent>
            <Grid container spacing={6}>
              <Grid item md={6}>
                <FormControl my={2}>
                  <Autocomplete
                    disablePortal
                    id="office-list-select"
                    fullWidth
                    renderInput={(params) => (
                      <TextField {...params} label="事業所" required />
                    )}
                    options={officeList.map((item) => {
                      return { id: item.id, label: item.name };
                    })}
                    onChange={(event, target) => {
                      if (target) {
                        setSelectedOfficeId(target.id);
                      }
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl my={2}>
                  <Autocomplete
                    disablePortal
                    id="device-list-select"
                    fullWidth
                    renderInput={(params) => (
                      <TextField {...params} label="デバイス" required />
                    )}
                    options={deviceList.map((item) => {
                      return { id: item.id, label: item.name };
                    })}
                    onChange={(event, target) => {
                      if (target) {
                        setSelectedDeviceId(target.id);
                      }
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={6}>
              <Grid item md={6}>
                <FormControl fullWidth my={2} variant="outlined">
                  <TextField
                    id="mac-address"
                    label="MACアドレス"
                    required
                    variant="outlined"
                    defaultValue={macAddressValue}
                    fullWidth
                    onChange={(e) => {
                      setMacAddressValue(e.target.value.replace(/:/g, ""));
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth my={2} variant="outlined">
                  <TextField
                    id="place-name"
                    label="設置場所名"
                    required
                    variant="outlined"
                    defaultValue={placeNameValue}
                    fullWidth
                    onChange={(e) => {
                      setPlaceNameValue(e.target.value);
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={6}>
              <Grid item md={6}>
                <FormControl fullWidth my={2} variant="outlined">
                  <DatePicker
                    label="設置日"
                    value={selectedSetupDate}
                    inputFormat={"yyyy-MM-dd"}
                    onChange={(date: Date | null) => {
                      if (date)
                        setSelectedSetupDate(format(date, "yyyy-MM-dd"));
                    }}
                    renderInput={(params) => <TextField {...params} required />}
                  />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth my={2} variant="outlined">
                  <DatePicker
                    label="廃止日"
                    value={selectedRemovalDate}
                    inputFormat={"yyyy-MM-dd"}
                    onChange={(date: Date | null) => {
                      setSelectedRemovalDate(
                        date ? format(date, "yyyy-MM-dd") : null
                      );
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={6}>
              <Grid item md={6}>
                <FormControl my={2}>
                  <InputLabel
                    id="device-location-type-list-select-label"
                    required
                  >
                    デバイス位置情報の種別
                  </InputLabel>
                  <Select
                    labelId="device-location-type-list-select-label"
                    id="device-location-type-list-select"
                    required
                    value={selectedDeviceLocationType}
                    label="事業所"
                    fullWidth
                    onChange={(e) => {
                      setSelectedDeviceLocationType(e.target.value);
                    }}
                  >
                    {deviceLocationTypeList.map((deviceLocationType, i) => {
                      return (
                        <MenuItem
                          key={deviceLocationType.id}
                          value={deviceLocationType.id}
                        >
                          {deviceLocationType.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              {selectedDeviceLocationType === "manual" && (
                <Grid item md={6}>
                  <FormControl fullWidth my={2} variant="outlined">
                    <TextField
                      id="lat-lng"
                      label="緯度経度"
                      variant="outlined"
                      defaultValue={latLngValue}
                      fullWidth
                      onChange={(e) => {
                        setLatLngValue(e.target.value);
                      }}
                    />
                  </FormControl>
                </Grid>
              )}
            </Grid>
            <Button
              my={2}
              variant="contained"
              color="primary"
              onClick={() => submit()}
            >
              作成する
            </Button>
          </CardContent>
        )}
        {!(officeList.length > 0 && deviceList.length > 0) && (
          <CardContent
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </CardContent>
        )}
      </Card>
      <Notification
        message={message.body}
        isOpen={message.isOpen}
        type={message.type}
        onClose={() => {
          setMessage({
            ...message,
            isOpen: false,
          });
        }}
      />
    </>
  );
}

function OfficeDevicesNewPage() {
  return (
    <React.Fragment>
      <Typography variant="h3" gutterBottom display="inline">
        新規設置デバイス追加
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          ダッシュボード
        </Link>
        <Link component={NavLink} to="/office-devices">
          設置デバイス一覧
        </Link>
        <Typography>新規設置デバイス追加</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <OfficeDeviceNewPageContent />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default OfficeDevicesNewPage;
