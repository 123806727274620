import { atom } from "recoil";
import {
  InitialObjectOfficeDevice,
  ObjectOfficeDevice,
  InitialObjectOfficeDevices,
  ArrayOfficeDevice,
} from "../types/api/officeDevice";

export const officeDeviceAtom = atom<ObjectOfficeDevice>({
  key: "officeDeviceAtom",
  default: InitialObjectOfficeDevice,
});

export const officeDevicesAtom = atom<ArrayOfficeDevice>({
  key: "officeDevicesAtom",
  default: InitialObjectOfficeDevices,
});
