export const PrefectureList = [
  { id: "hokkaido", label: "北海道", prefecture_id: "1" },
  { id: "aomori", label: "青森県", prefecture_id: "2" },
  { id: "iwate", label: "岩手県", prefecture_id: "3" },
  { id: "miyagi", label: "宮城県", prefecture_id: "4" },
  { id: "akita", label: "秋田県", prefecture_id: "5" },
  { id: "yamagata", label: "山形県", prefecture_id: "6" },
  { id: "fukushima", label: "福島県", prefecture_id: "7" },
  { id: "ibaraki", label: "茨城県", prefecture_id: "8" },
  { id: "tochigi", label: "栃木県", prefecture_id: "9" },
  { id: "gunma", label: "群馬県", prefecture_id: "10" },
  { id: "saitama", label: "埼玉県", prefecture_id: "11" },
  { id: "chiba", label: "千葉県", prefecture_id: "12" },
  { id: "tokyo", label: "東京都", prefecture_id: "13" },
  { id: "kanagawa", label: "神奈川県", prefecture_id: "14" },
  { id: "niigata", label: "新潟県", prefecture_id: "15" },
  { id: "toyama", label: "富山県", prefecture_id: "16" },
  { id: "ishikawa", label: "石川県", prefecture_id: "17" },
  { id: "fukui", label: "福井県", prefecture_id: "18" },
  { id: "yamanashi", label: "山梨県", prefecture_id: "19" },
  { id: "nagano", label: "長野県", prefecture_id: "20" },
  { id: "gifu", label: "岐阜県", prefecture_id: "21" },
  { id: "shizuoka", label: "静岡県", prefecture_id: "22" },
  { id: "aichi", label: "愛知県", prefecture_id: "23" },
  { id: "mie", label: "三重県", prefecture_id: "24" },
  { id: "shiga", label: "滋賀県", prefecture_id: "25" },
  { id: "kyoto", label: "京都府", prefecture_id: "26" },
  { id: "osaka", label: "大阪府", prefecture_id: "27" },
  { id: "hyogo", label: "兵庫県", prefecture_id: "28" },
  { id: "nara", label: "奈良県", prefecture_id: "29" },
  { id: "wakayama", label: "和歌山県", prefecture_id: "30" },
  { id: "tottori", label: "鳥取県", prefecture_id: "31" },
  { id: "shimane", label: "島根県", prefecture_id: "32" },
  { id: "okayama", label: "岡山県", prefecture_id: "33" },
  { id: "hiroshima", label: "広島県", prefecture_id: "34" },
  { id: "yamaguchi", label: "山口県", prefecture_id: "35" },
  { id: "tokushima", label: "徳島県", prefecture_id: "36" },
  { id: "kagawa", label: "香川県", prefecture_id: "37" },
  { id: "ehime", label: "愛媛県", prefecture_id: "38" },
  { id: "kochi", label: "高知県", prefecture_id: "39" },
  { id: "fukuoka", label: "福岡県", prefecture_id: "40" },
  { id: "saga", label: "佐賀県", prefecture_id: "41" },
  { id: "nagasaki", label: "長崎県", prefecture_id: "42" },
  { id: "kumamoto", label: "熊本県", prefecture_id: "43" },
  { id: "oita", label: "大分県", prefecture_id: "44" },
  { id: "miyazaki", label: "宮崎県", prefecture_id: "45" },
  { id: "kagoshima", label: "鹿児島県", prefecture_id: "46" },
  { id: "okinawa", label: "沖縄県", prefecture_id: "47" },
];
