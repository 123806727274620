import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";

import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Button,
  Grid,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridValueFormatterParams,
  jaJP,
} from "@mui/x-data-grid";
import { spacing } from "@mui/system";
import {
  Add as AddIcon,
  Upload as UploadIcon,
  Download as DownloadIcon,
} from "@mui/icons-material";
import {
  ErrorResponse,
  FetchUserListResponse,
  UserDeleteResponse,
  UserDeleteURI,
  UserListURI,
} from "../../types/api";
import { axiosInstance } from "../../utils/axios";
import EditButton from "../../components/buttons/EditButton";
import DeleteButton from "../../components/buttons/DeleteButton";
import Notification, { NotificationProps } from "../../components/Notification";
import { CustomToolbar } from "../../components/tables/Toolbar";
import { formatDate, formatDateFromTimestamp } from "../../utils/dateHelper";
import useAuth from "../../hooks/useAuth";
import {
  UsersDownloadCsvURI,
  UsersGenerateCsvURI,
  UsersUploadCsvResponse,
  UsersUploadCsvURI,
} from "../../types/api/user";
import { downloadFile } from "../../utils/file";
import { scroll2Top } from "../../utils/dataGridHelper";
import { LoadingButton } from "@mui/lab";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

function ManagersPageContent() {
  const [data, setData] = useState<FetchUserListResponse["objects"] | []>([]);
  const [deletedDataId, setDeletedDataId] = useState<string[]>([]);
  const [errorMessageOpen, setErrorMessageOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleDelete = async (id: string) => {
    const res = await axiosInstance.delete<UserDeleteResponse | ErrorResponse>(
      UserDeleteURI(id)
    );
    if (res.status === 200) {
      setDeletedDataId([...deletedDataId, id]);
    } else {
      setErrorMessage((res.data as ErrorResponse).message!);
      setErrorMessageOpen(true);
      setTimeout(() => {
        setErrorMessageOpen(false);
      }, 5000);
    }
  };

  const columns: GridColDef[] = [
    // 編集ボタン
    {
      field: "editBtn",
      headerName: "編集",
      sortable: false,
      filterable: false,
      width: 60,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => <EditButton href={`/managers/${params.id}`} />,
    },
    // 削除ボタン
    {
      field: "deleteBtn",
      headerName: "削除",
      sortable: false,
      filterable: false,
      width: 60,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <DeleteButton id={params.id as string} callback={handleDelete} />
      ),
    },
    {
      field: "id",
      headerName: "ID",
      width: 300,
      editable: false,
      hide: true,
    },
    {
      field: "email",
      headerName: "メールアドレス",
      width: 300,
      editable: false,
    },
    {
      field: "lastName",
      headerName: "姓",
      width: 200,
      editable: false,
    },
    {
      field: "firstName",
      headerName: "名",
      width: 200,
      editable: false,
    },
    {
      field: "lastNameKana",
      headerName: "セイ",
      width: 200,
      editable: false,
    },
    {
      field: "firstNameKana",
      headerName: "メイ",
      width: 200,
      editable: false,
    },
    {
      field: "createdAt",
      headerName: "作成日時",
      type: "dateTime",
      width: 150,
      editable: false,
      valueFormatter: (params: GridValueFormatterParams) => {
        return formatDateFromTimestamp(params.value);
      },
    },
    {
      field: "updatedAt",
      headerName: "更新日時",
      type: "datetime",
      width: 150,
      editable: false,
      valueFormatter: (params: GridValueFormatterParams) => {
        return formatDateFromTimestamp(params.value);
      },
    },
  ];

  const [pageSize, setPageSize] = useState<number>(50);
  const [page, setPage] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const handlePageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
  };
  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const fetchData = async (pageSize: number, page: number) => {
    setLoading(true);
    const res = await axiosInstance.get<FetchUserListResponse | ErrorResponse>(
      UserListURI,
      {
        params: {
          role: "manager",
          offset: page * pageSize,
          limit: pageSize,
          sort_by: "created_at-desc",
        },
      }
    );

    if (res.status === 200) {
      const responseData = res.data as FetchUserListResponse;
      setData(responseData.objects);
      setTotal(responseData.paging.total);
      setLoading(false);
    } else {
      setErrorMessage((res.data as ErrorResponse).message!);
      setErrorMessageOpen(true);
      setTimeout(() => {
        setErrorMessageOpen(false);
      }, 5000);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!loading) {
      scroll2Top();
    }
  }, [loading]);

  useEffect(() => {
    fetchData(pageSize, page);
  }, []);

  useEffect(() => {
    fetchData(pageSize, page);
  }, [pageSize, page]);

  useEffect(() => {
    if (deletedDataId) {
      setData(data.filter((element) => !deletedDataId.includes(element.id)));
    }
  }, [deletedDataId]);

  return (
    <>
      <Card mb={6}>
        <Paper>
          <div style={{ height: 800, width: "100%" }}>
            <DataGrid
              rowsPerPageOptions={[50, 100]}
              rows={data}
              columns={columns}
              pageSize={pageSize}
              rowCount={total}
              page={page}
              checkboxSelection={false}
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
              components={{ Toolbar: CustomToolbar }}
              initialState={{
                sorting: {
                  sortModel: [
                    {
                      field: "createdAt",
                      sort: "desc",
                    },
                  ],
                },
              }}
              loading={loading}
              pagination
              paginationMode="server"
              onPageSizeChange={handlePageSizeChange}
              onPageChange={handlePageChange}
            />
          </div>
        </Paper>
      </Card>
      <Notification
        message={errorMessage}
        isOpen={errorMessageOpen}
        onClose={() => {
          setErrorMessageOpen(false);
        }}
      />
    </>
  );
}

function ManagersPage() {
  const { user } = useAuth();
  const [refetch, setRefetch] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [notification, setNotification] = useState<NotificationProps>();

  const changeUploadFile = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { files } = event.target;

    if (files && files.length > 0) {
      const data = new FormData();
      data.append("csv_file", files[0]);
      const headers = { "content-type": "multipart/form-data" };

      const res = await axiosInstance.post<UsersUploadCsvResponse>(
        UsersUploadCsvURI,
        data,
        { headers }
      );
      if (res.status === 200) {
        setRefetch(true);
      }
    }

    event.target.value = "";
  };

  const downloadCsvTemplateFile = () => {
    setLoading(true);
    axiosInstance
      .get(UsersDownloadCsvURI, {
        params: {
          role: "manager",
          sort_by: "created_at-desc",
        },
      })
      .then((response) => {
        downloadFile(
          `グループ管理者一覧_${formatDate(new Date(), "yyyyMMddHHmmss")}`,
          response
        );
        setLoading(false);
      });
  };

  useEffect(() => {
    setRefetch(false);
  }, []);

  return (
    <React.Fragment>
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            グループ管理者一覧
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              ダッシュボード
            </Link>
            <Typography>グループ管理者一覧</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item>
              <div>
                <Button
                  component={NavLink}
                  to="/managers/new"
                  variant="contained"
                  color="primary"
                >
                  <AddIcon />
                  新規追加
                </Button>
              </div>
            </Grid>
            {user?.role === "admin" && (
              <Grid item pl={2}>
                <Button variant="contained" component="label" color="primary">
                  <UploadIcon />
                  CSVアップロード
                  <input type="file" hidden onChange={changeUploadFile} />
                </Button>
              </Grid>
            )}

            {user?.role === "admin" && (
              <Grid item pl={2}>
                <LoadingButton
                  variant="contained"
                  component="label"
                  color="primary"
                  onClick={downloadCsvTemplateFile}
                  loading={loading}
                >
                  <DownloadIcon />
                  CSV一括ダウンロード
                </LoadingButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Divider my={6} />

      <ManagersPageContent />

      {notification && (
        <Notification
          message={notification.message || ""}
          type={notification.type}
          isOpen={notification.isOpen}
          onClose={() =>
            setNotification({
              message: "",
              isOpen: false,
              type: "info",
            })
          }
        />
      )}
    </React.Fragment>
  );
}

export default ManagersPage;
