import styled from "@emotion/styled";
import { IconButton as MuiIconButton } from "@mui/material";
import { QueryStats as QueryStatsIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { useNavigate } from "react-router-dom";

const IconButton = styled(MuiIconButton)(spacing);

type Props = {
  href: string;
};

const ReportButton = ({ href }: Props) => {
  const navigate = useNavigate();
  const onClickHandler = () => {
    navigate(href);
  };

  return (
    <IconButton
      mx={2}
      aria-label="Show"
      size="large"
      color="warning"
      onClick={onClickHandler}
    >
      <QueryStatsIcon fontSize="small" />
    </IconButton>
  );
};

export default ReportButton;
