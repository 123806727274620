import { ReactNode, useEffect } from "react";
import { useRecoilState } from "recoil";
import { groupAtom, groupsAtom } from "../atoms/group";
import { officeAtom, officesAtom } from "../atoms/office";
import { officeDeviceAtom, officeDevicesAtom } from "../atoms/officeDevice";
import { ErrorResponse } from "../types/api";
import {
  ArrayOffice,
  OfficeListResponse,
  OfficeListURI,
} from "../types/api/office";
import {
  ArrayOfficeDevice,
  OfficeDeviceListResponse,
  OfficeDeviceListURI,
} from "../types/api/officeDevice";
import { axiosInstance } from "../utils/axios";

export const RecoilSyncProvider = ({ children }: { children: ReactNode }) => {
  const [currentGroups, setCurrentGroups] = useRecoilState(groupsAtom);
  const [currentGroup, setCurrentGroup] = useRecoilState(groupAtom);
  const [currentOffice, setCurrentOffice] = useRecoilState(officeAtom);
  const [currentOffices, setCurrentOffices] = useRecoilState(officesAtom);
  const [currentOfficeDevice, setCurrentOfficeDevice] =
    useRecoilState(officeDeviceAtom);
  const [currentOfficeDevices, setCurrentOfficeDevices] =
    useRecoilState(officeDevicesAtom);

  const fetchOffices = async (groupId: string) => {
    const res = await axiosInstance.get<OfficeListResponse | ErrorResponse>(
      OfficeListURI,
      {
        params: {
          group_id: groupId,
          offset: 0,
          limit: 10000,
          sort_by: "created_at-desc",
        },
      }
    );

    if (res.status === 200) {
      const responseData = res.data as OfficeListResponse;
      const offices = responseData.objects as ArrayOffice;
      setCurrentOffices(offices);
      setCurrentOffice(offices[0]);
    }
  };

  const fetchOfficeDevices = async (officeId: string) => {
    const res = await axiosInstance.get<
      OfficeDeviceListResponse | ErrorResponse
    >(OfficeDeviceListURI, {
      params: {
        office_id: officeId,
        offset: 0,
        limit: 10000,
        sort_by: "created_at-desc",
      },
    });

    if (res.status === 200) {
      const responseData = res.data as OfficeDeviceListResponse;
      const officeDevices = responseData.objects as ArrayOfficeDevice;
      setCurrentOfficeDevices(officeDevices);
      setCurrentOfficeDevice(officeDevices[0]);
    }
  };

  useEffect(() => {
    if (currentGroup && currentGroup.id.length > 0) {
      fetchOffices(currentGroup.id);
    }
  }, [currentGroup]);

  useEffect(() => {
    if (currentOffice && currentOffice.id.length > 0) {
      fetchOfficeDevices(currentOffice.id);
    }
  }, [currentOffice]);

  return <>{children}</>;
};
