import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import useAuth from "../../../hooks/useAuth";
import DoughnutChart from "./DoughnutChart";
import OfficeListWithAirQuality from "./OfficeListWithAirQuality";

import { blue, orange, green, red, grey, yellow } from "@mui/material/colors";

import { axiosInstance } from "../../../utils/axios";
import {
  OfficeDeviceListResponse,
  OfficeDeviceListURI,
} from "../../../types/api/officeDevice";
import { ErrorResponse } from "../../../types/api";
import { getAirQualityLevel } from "../../../utils/airQualityHelper";
import { getBffUri, UriType } from "../../../utils/uriHelper";
import { getDeviceSensorStatus } from "../../../utils/deviceHelper";
import { useRecoilState } from "recoil";
import { groupAtom } from "../../../atoms/group";

function AirQualigtyViewer() {
  const { user } = useAuth();
  const [currentGroup, setCurrentGroup] = useRecoilState(groupAtom);

  const [totalCount, setTotalCount] = useState<number>(0);
  const [doughnutChartData, setDoughnutChartData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  type EachLevelCountType = {
    [key: string]: number;
  };
  const [eachLevelCount, setEachLevelCount] = useState<EachLevelCountType>({
    level0: 0,
    level1: 0,
    level2: 0,
    level3: 0,
    level4: 0,
    level5: 0,
  });

  type EachLevelOfficeDevicesType = {
    [key: string]: OfficeDeviceListResponse["objects"] | [];
  };
  const [eachLevelOfficeDevices, setEachLevelOfficeDevices] =
    useState<EachLevelOfficeDevicesType>({
      level0: [],
      level1: [],
      level2: [],
      level3: [],
      level4: [],
      level5: [],
    });

  const fetchData = async (groupId: string | undefined) => {
    setLoading(true);
    const res = await axiosInstance.request<
      OfficeDeviceListResponse | ErrorResponse
    >({
      method: "GET",
      url: OfficeDeviceListURI,
      baseURL: getBffUri(UriType.SUB),
      params: {
        group_id: groupId,
        offset: 0,
        limit: 10000,
      },
    });

    if (res.status === 200) {
      const responseData = res.data as OfficeDeviceListResponse;
      setTotalCount(responseData.paging.total);
      let levelCount = [0, 0, 0, 0, 0, 0];
      let officeDevices: any = [[], [], [], [], [], []];

      responseData.objects.forEach((officeDevice, index) => {
        const deviceSensorStatus = getDeviceSensorStatus(officeDevice);
        const aql = deviceSensorStatus
          ? getAirQualityLevel(
              Number(officeDevice.latestDeviceLog?.co2Value),
              Number(officeDevice.latestDeviceLog?.tvocValue),
              Number(officeDevice.latestDeviceLog?.pm25Value)
            )
          : 0;

        levelCount[aql] = levelCount[aql] + 1;
        officeDevices[aql].push(officeDevice);
      });
      setEachLevelCount({
        level0: levelCount[0],
        level1: levelCount[1],
        level2: levelCount[2],
        level3: levelCount[3],
        level4: levelCount[4],
        level5: levelCount[5],
      });
      setDoughnutChartData({
        labels: ["Lv.1", "Lv.2", "Lv.3", "Lv.4", "Lv.5", "停止中"],
        datasets: [
          {
            data: [
              levelCount[1],
              levelCount[2],
              levelCount[3],
              levelCount[4],
              levelCount[5],
              levelCount[0],
            ],
            backgroundColor: [
              red[500],
              yellow[200],
              orange[500],
              green[500],
              blue[600],
              grey[200],
            ],
            borderWidth: 5,
            borderColor: "#ffffff",
          },
        ],
      });
      setEachLevelOfficeDevices({
        level0: officeDevices[0],
        level1: officeDevices[1],
        level2: officeDevices[2],
        level3: officeDevices[3],
        level4: officeDevices[4],
        level5: officeDevices[5],
      });
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user?.role === "admin") {
      fetchData(undefined);
    } else if (
      user?.role === "manager" &&
      currentGroup.id !== undefined &&
      currentGroup.id !== null &&
      currentGroup.id.length > 0
    ) {
      fetchData(currentGroup.id);
    }
  }, []);

  return (
    <React.Fragment>
      {user && ["manager", "admin"].includes(user.role) && (
        <>
          <Grid container spacing={6} mt={6} mb={6}>
            <Grid item xs={12} lg={4}>
              <DoughnutChart
                eachLevelCount={eachLevelCount}
                loading={loading}
                doughnutChartData={doughnutChartData}
                totalCount={totalCount}
              />
            </Grid>
            <Grid item xs={12} lg={8}>
              <OfficeListWithAirQuality
                eachLevelOfficeDevices={eachLevelOfficeDevices}
              />
            </Grid>
          </Grid>
        </>
      )}
    </React.Fragment>
  );
}

export default AirQualigtyViewer;
