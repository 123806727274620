import { CamelCasedPropertiesDeep } from "type-fest";
import { components } from "../../schema";
import { InitialObjectGroup } from "./group";
import { InitialObjectOfficeCategory } from "./officeCategory";

// URI
export const OfficeListURI = "/admin/office/list";
export const AddNewOfficeURI = "/admin/office/new";
export const OfficeURI = (id: string) => `/admin/office/${id}`;
export const OfficesUploadCsvURI = "/admin/office/csv";
export const OfficeDownloadCsvURI = "/admin/office/csv";
export const OfficesGenerateCsvURI = "/admin/office/generate-csv";

// RequestBody
export type AddNewOfficeParams =
  components["schemas"]["RequestBody_AddNewOffice"];
export type UpdateOfficeParams =
  components["schemas"]["RequestBody_UpdateOffice"];

// Response
export type OfficeListResponse = CamelCasedPropertiesDeep<
  components["schemas"]["Response_OfficeList"]
>;
export type OfficeResponse = CamelCasedPropertiesDeep<
  components["schemas"]["Response_Office"]
>;
export type OfficesUploadCsvResponse = CamelCasedPropertiesDeep<
  components["schemas"]["Response_UploadCSV"]
>;
export type ArrayOffice = CamelCasedPropertiesDeep<
  components["schemas"]["Array_Office"]
>;
export type ObjectOffice = CamelCasedPropertiesDeep<
  components["schemas"]["Object_Office"]
>;

export const InitialObjectOffice: ObjectOffice = {
  id: "",
  createdAt: 0,
  updatedAt: 0,
  officeCategory: InitialObjectOfficeCategory,
  group: InitialObjectGroup,
  name: "",
  postalCode: "",
  prefecture: "aichi",
  district: "",
  address: "",
};

export const InitialObjectOffices: ArrayOffice = [InitialObjectOffice];
