import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import Chart from "react-chartjs-2";
import { rgba } from "polished";
import { blue } from "@mui/material/colors";
import { Card as MuiCard, CardContent, Typography } from "@mui/material";
import { spacing } from "@mui/system";
import { axiosInstance } from "../../../utils/axios";
import {
  ErrorResponse,
  FetchOfficeDeviceLogListResponse,
  OfficeDeviceLogListURI,
} from "../../../types/api";
import { getBffUri, UriType } from "../../../utils/uriHelper";
import { getAirQualityLevel } from "../../../utils/airQualityHelper";
import { formatDate } from "../../../utils/dateHelper";
import { average, objGroupBy } from "../../../utils/other";

const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`
  height: 340px;
  width: 100%;
`;

type Props = {
  officeDeviceId: string;
  targetDate: string;
  comparisonDate: string | null;
};

const BarChart = ({ officeDeviceId, targetDate, comparisonDate }: Props) => {
  const firstDatasetColor = blue["600"];
  const secondDatasetColor = rgba(blue["600"], 0.33);

  const [data, setData] = useState<any>();

  const sortByTimestampVlue = (list: any[]) => {
    return list.sort((a, b) => (a.timestampValue < b.timestampValue ? -1 : 1));
  };

  const fetchData = async (
    id: string,
    targetDate: string,
    comparisonDate: string | null
  ) => {
    const endDate = new Date(targetDate);
    const startDate = new Date(targetDate);
    startDate.setDate(startDate.getDate() - 7);

    const res = await axiosInstance.request<
      FetchOfficeDeviceLogListResponse | ErrorResponse
    >({
      method: "GET",
      url: OfficeDeviceLogListURI(id),
      baseURL: getBffUri(UriType.SUB),
      params: {
        timezone: "jst",
        start_date: formatDate(startDate, "yyyy-MM-dd"),
        end_date: formatDate(endDate, "yyyy-MM-dd"),
        type: "chart",
      },
    });

    if (res.status === 200) {
      const sortedData = sortByTimestampVlue(
        (res.data as FetchOfficeDeviceLogListResponse).logs
      );
      let labels: string[] = [];
      let firstDataSets: number[] = [];
      let secondDataSets: number[] = [];

      const groupedAverageCo2Data: any = {};
      const groupedAverageTvocData: any = {};
      const groupedAveragePm25Data: any = {};

      const groupedData = objGroupBy(sortedData, (item) => item.date);
      Object.keys(groupedData).forEach((key) => {
        labels.push(key);
        if (groupedData[key]) {
          groupedAverageCo2Data[key] =
            average(groupedData[key]!.map((v) => v.co2Value)) || 0;
          groupedAverageTvocData[key] = average(
            groupedData[key]!.map((v) => v.tvocValue)
          );
          groupedAveragePm25Data[key] = average(
            groupedData[key]!.map((v) => v.pm25Value)
          );
        }
      });
      const uniqueLabels = Array.from(new Set(labels));
      uniqueLabels.forEach((label: string, index) => {
        const airQualityLevel = getAirQualityLevel(
          groupedAverageCo2Data[label],
          groupedAverageTvocData[label],
          groupedAveragePm25Data[label]
        );
        firstDataSets.push(airQualityLevel);
      });

      setData({
        labels: uniqueLabels,
        datasets: [
          {
            label: "First",
            backgroundColor: firstDatasetColor,
            borderColor: firstDatasetColor,
            hoverBackgroundColor: firstDatasetColor,
            hoverBorderColor: firstDatasetColor,
            data: firstDataSets,
            barPercentage: 0.5,
            categoryPercentage: 0.5,
          },
          {
            label: "Second",
            backgroundColor: secondDatasetColor,
            borderColor: secondDatasetColor,
            hoverBackgroundColor: secondDatasetColor,
            hoverBorderColor: secondDatasetColor,
            data: secondDataSets,
            barPercentage: 0.5,
            categoryPercentage: 0.5,
            borderRadius: 6,
          },
        ],
      });
    }
  };

  useEffect(() => {
    if (officeDeviceId && targetDate) {
      fetchData(officeDeviceId, targetDate, comparisonDate);
    }
  }, [officeDeviceId, targetDate, comparisonDate]);

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        grid: {
          display: false,
        },
        stacked: true,
        min: 0,
        max: 5,
        ticks: {
          stepSize: 1,
        },
      },
      x: {
        stacked: true,
        grid: {
          color: "transparent",
        },
      },
    },
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" mb="16px" fontWeight="bold">
          直近１週間の空気質レベル
        </Typography>
        <ChartWrapper>
          {data && <Chart type="bar" data={data} options={options} />}
        </ChartWrapper>
      </CardContent>
    </Card>
  );
};

export default withTheme(BarChart);
