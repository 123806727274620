import { createContext, ReactNode, useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useResetRecoilState } from "recoil";
import { groupAtom, groupsAtom } from "../atoms/group";
import { officeAtom, officesAtom } from "../atoms/office";
import { officeDeviceAtom, officeDevicesAtom } from "../atoms/officeDevice";
import {
  LoginAuthParams,
  LoginAuthURI,
  ResetPasswordParams,
  ResetPasswordResponse,
  ResetPasswordURI,
  VerificationCodeNewParams,
  VerificationCodeNewResponse,
  VerificationCodeNewURI,
} from "../types/api/auth";
import { ObjectUser } from "../types/api/user";
import { JWTContextType, ActionMap, AuthState, AuthUser } from "../types/auth";
import { axiosInstance } from "../utils/axios";
import { setSession } from "../utils/jwt";

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";

type AuthActionTypes = {
  [INITIALIZE]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [SIGN_IN]: {
    user: AuthUser;
  };
  [SIGN_OUT]: undefined;
  [SIGN_UP]: {
    user: AuthUser;
  };
};

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (
  state: AuthState,
  action: ActionMap<AuthActionTypes>[keyof ActionMap<AuthActionTypes>]
) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case SIGN_UP:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [currentGroup, setCurrentGroup] = useRecoilState(groupAtom);
  const [currentGroups, setCurrentGroups] = useRecoilState(groupsAtom);
  const [currentOffice, setCurrentOffice] = useRecoilState(officeAtom);
  const [currentOffices, setCurrentOffices] = useRecoilState(officesAtom);

  const resetGroup = useResetRecoilState(groupAtom);
  const resetGroups = useResetRecoilState(groupsAtom);
  const resetOffice = useResetRecoilState(officeAtom);
  const resetOffices = useResetRecoilState(officesAtom);
  const resetOfficeDevice = useResetRecoilState(officeDeviceAtom);
  const resetOfficeDevices = useResetRecoilState(officeDevicesAtom);

  const [state, dispatch] = useReducer(JWTReducer, initialState);
  const navigate = useNavigate();

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");

        // if (accessToken && isValidToken(accessToken)) {
        if (accessToken) {
          await setSession(accessToken);

          const response = await axiosInstance.get("/admin/user/me");
          if (response.status === 422) {
            setSession(null);
            dispatch({
              type: INITIALIZE,
              payload: {
                isAuthenticated: false,
                user: null,
              },
            });
          }
          const user = response.data as ObjectUser;

          if (user.role === "manager") {
            if (user.groups.length > 0) {
              setCurrentGroups(user.groups);
              setCurrentGroup(user.groups[0]);
            }
          } else if (user.role === "staff") {
            if (user.offices.length > 0) {
              setCurrentOffices(user.offices);
              setCurrentOffice(user.offices[0]);
            }
          }

          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          setSession(null);
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        setSession(null);
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const signIn = async (email: string, password: string) => {
    const response = await axiosInstance.post(LoginAuthURI, {
      email,
      password,
    } as LoginAuthParams);
    const accessToken = response.data.accessToken;
    const user = response.data.user as ObjectUser;

    await setSession(accessToken);

    dispatch({
      type: SIGN_IN,
      payload: {
        user,
      },
    });

    if (accessToken) {
      const response = await axiosInstance.get("/admin/user/me");
      if (response.status === 422) {
        setSession(null);
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
      const user = response.data as ObjectUser;

      if (user.role === "manager") {
        if (user.groups.length > 0) {
          setCurrentGroups(user.groups);
          setCurrentGroup(user.groups[0]);
        }
      } else if (user.role === "staff") {
        if (user.offices.length > 0) {
          setCurrentOffices(user.offices);
          setCurrentOffice(user.offices[0]);
        }
      }

      const group = user.role === "manager" ? user.groups[0] : null;
      const office =
        user.role === "manager"
          ? user.offices.find((o) => o.group.id === group?.id)
          : user.role === "staff"
          ? user.offices[0]
          : null;

      dispatch({
        type: INITIALIZE,
        payload: {
          isAuthenticated: true,
          user,
        },
      });
    } else {
      setSession(null);
      dispatch({
        type: INITIALIZE,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
    return { status: response.status, message: response.data.message };
  };

  const signOut = async () => {
    setSession(null);
    resetAllRecoilSates();
    dispatch({ type: SIGN_OUT });
    navigate("/auth/sign-in");
  };

  const signUp = async (
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ) => {
    const response = await axiosInstance.post("/api/auth/sign-up", {
      email,
      password,
      firstName,
      lastName,
    });
    const accessToken = response.data.accessToken;
    const user = response.data.user as ObjectUser;

    await setSession(accessToken);

    dispatch({
      type: SIGN_UP,
      payload: {
        user,
      },
    });
  };

  const verificationCodeNew = async (email: string) => {
    const response = await axiosInstance.post<VerificationCodeNewResponse>(
      VerificationCodeNewURI,
      {
        email,
        type: "password_reset",
      } as VerificationCodeNewParams
    );
  };

  const resetPassword = async (
    email: string,
    verificationCode: string,
    password: string
  ) => {
    const response = await axiosInstance.post<ResetPasswordResponse>(
      ResetPasswordURI,
      {
        email,
        verification_code: verificationCode,
        password,
      } as ResetPasswordParams
    );
  };

  const resetAllRecoilSates = async () => {
    resetGroup();
    resetGroups();
    resetOffice();
    resetOffices();
    resetOfficeDevice();
    resetOfficeDevices();
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        signIn,
        signOut,
        signUp,
        verificationCodeNew,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
