import styled from "@emotion/styled";
import { IconButton as MuiIconButton } from "@mui/material";
import { spacing } from "@mui/system";
import { useNavigate } from "react-router-dom";
import ViewTimelineOutlinedIcon from "@mui/icons-material/ViewTimelineOutlined";

const IconButton = styled(MuiIconButton)(spacing);

type Props = {
  href: string;
};

const LogButton = ({ href }: Props) => {
  const navigate = useNavigate();
  const onClickHandler = () => {
    window.open(href, "_blank", "noopener,noreferrer");
    // navigate(href);
  };

  return (
    <IconButton
      mx={2}
      aria-label="Show"
      size="large"
      color="success"
      onClick={onClickHandler}
    >
      <ViewTimelineOutlinedIcon fontSize="small" />
    </IconButton>
  );
};

export default LogButton;
