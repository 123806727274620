import * as React from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";

import {
  Grid,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
} from "@mui/material";

import {
  Menu as MenuIcon,
  ArrowBack as ArrowBackIcon,
} from "@mui/icons-material";

import NavbarUserDropdown from "./NavbarUserDropdown";
import NavbarOfficesDropdown from "./NavbarOfficesDropdown";
import useAuth from "../../hooks/useAuth";
import NavbarGroupsDropdown from "./NavbarGroupsDropdown";
import { useNavigate } from "react-router-dom";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

type NavbarProps = {
  onDrawerToggle: React.MouseEventHandler<HTMLElement>;
};

const Navbar: React.FC<NavbarProps> = ({ onDrawerToggle }) => {
  const { user } = useAuth();
  const navigate = useNavigate();

  if (!!user) {
    return (
      <React.Fragment>
        <AppBar position="sticky" elevation={0}>
          <Toolbar>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item sx={{ display: { xs: "block", md: "none" } }}>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                  size="large"
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
              <Grid item flex={1}>
                <Grid container justifyContent="right">
                  <Grid item marginRight="auto">
                    <IconButton
                      color="inherit"
                      aria-label="Open drawer"
                      onClick={() => {
                        navigate(-1);
                      }}
                      size="large"
                    >
                      <ArrowBackIcon />
                    </IconButton>
                  </Grid>
                  <Grid
                    item
                    minWidth={200}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {user.role === "manager" ? (
                      <NavbarGroupsDropdown />
                    ) : (
                      user.role === "staff" && <NavbarOfficesDropdown />
                    )}
                  </Grid>
                  <Grid item>
                    <NavbarUserDropdown />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </React.Fragment>
    );
  }

  return <></>;
};

export default withTheme(Navbar);
