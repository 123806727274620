import { CamelCasedPropertiesDeep } from "type-fest";
import { components } from "../../schema";

// URI
export const GeneratePasswordURI = "/admin/user/generate-passwords";
export const UsersUploadCsvURI = "/admin/user/csv";
export const UsersDownloadCsvURI = "/admin/user/csv";
export const UsersGenerateCsvURI = "/admin/user/generate-csv";

// Request
export type GeneratePasswordParams =
  components["schemas"]["RequestBody_GeneratePasswords"];

// Response
export type ObjectUser = CamelCasedPropertiesDeep<
  components["schemas"]["Object_User"]
>;
export type GeneratePasswordResponse = CamelCasedPropertiesDeep<
  components["schemas"]["Response_Success"]
>;
export type UsersUploadCsvResponse = CamelCasedPropertiesDeep<
  components["schemas"]["Response_UploadCSV"]
>;
