import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import {
  Autocomplete,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import {
  UserEditResponse,
  UserEditURI,
  UserResponse,
  UserURI,
} from "../../types/api";
import { axiosInstance } from "../../utils/axios";
import { OfficeListResponse, OfficeListURI } from "../../types/api/office";
import { FetchGroupListResponse, GroupListURI } from "../../types/api/group";
import { ObjectUser } from "../../types/api/user";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)<{ m?: number }>`
  min-width: 240px;
  width: 100%;
`;

interface ButtonProps extends SpacingProps {
  component?: string;
}
const Button = styled(MuiButton)<ButtonProps>(spacing);

type Props = {
  userId: string;
};

const StaffsEditPageContent = ({ userId }: Props) => {
  const navigate = useNavigate();
  const [officeList, setOfficeList] = useState<OfficeListResponse["objects"]>(
    []
  );
  const [filteredOfficeList, setFilteredOfficeList] = useState<
    OfficeListResponse["objects"]
  >([]);
  const [groupList, setGroupList] = useState<FetchGroupListResponse["objects"]>(
    []
  );
  const [selectedOffice, setSelectedOffice] =
    useState<{ id: string; label: string }>();
  const [selectedGroup, setSelectedGroup] =
    useState<{ id: string; label: string }>();
  const [user, setUser] = useState<UserResponse>();

  useEffect(() => {
    const fetchData = async () => {
      const officeListResponse = await axiosInstance.get<OfficeListResponse>(
        OfficeListURI,
        {
          params: {
            offset: 0,
            limit: 5000,
            sort_by: "created_at-desc",
          },
        }
      );
      setOfficeList(officeListResponse.data.objects);

      const groupListResponse = await axiosInstance.get<FetchGroupListResponse>(
        GroupListURI,
        {
          params: {
            offset: 0,
            limit: 5000,
            sort_by: "created_at-desc",
          },
        }
      );
      setGroupList(groupListResponse.data.objects);

      const userResponse = await axiosInstance.get<UserResponse>(
        UserURI(userId)
      );
      if (userResponse) {
        const user = userResponse.data as ObjectUser;
        const group = user.groups[0] || user.offices[0].group;
        const office = user.offices[0];
        if (group) {
          setSelectedGroup({
            id: group.id,
            label: group.name,
          });
        }
        if (office) {
          setSelectedOffice({
            id: office.id,
            label: office.name,
          });
        }
        setUser(user);
      }
    };

    fetchData();
  }, [userId]);

  useEffect(() => {
    if (officeList && selectedGroup) {
      setFilteredOfficeList(
        officeList.filter((office) => {
          return office.group.id === selectedGroup.id;
        })
      );
    }
  }, [officeList, selectedGroup]);

  const submit = async () => {
    const res = await axiosInstance.patch<UserEditResponse>(
      UserEditURI(userId),
      {
        office_ids: selectedOffice ? [selectedOffice?.id] : null,
        group_ids: selectedOffice
          ? null
          : selectedGroup
          ? [selectedGroup?.id]
          : null,
        role: "staff",
        first_name: user?.firstName,
        last_name: user?.lastName,
        first_name_kana: user?.firstNameKana,
        last_name_kana: user?.lastNameKana,
        email: user?.email,
      }
    );
    if (res.data?.id) navigate("/staffs");
  };

  return (
    <Card mb={6}>
      {groupList.length > 0 && officeList.length > 0 && user && (
        <CardContent>
          <Grid container spacing={6}>
            <Grid item md={3}>
              <FormControl my={2}>
                <Autocomplete
                  disablePortal
                  id="group-list-select"
                  fullWidth
                  defaultValue={
                    selectedGroup && selectedGroup.id && selectedGroup.label
                      ? {
                          id: selectedGroup?.id,
                          label: selectedGroup?.label,
                        }
                      : undefined
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="事業所グループ" required />
                  )}
                  options={groupList.map((item) => {
                    return { id: item.id, label: item.name };
                  })}
                  onChange={(e, target) => {
                    if (target && target.id && target.label) {
                      setSelectedGroup({
                        id: target.id,
                        label: target.label,
                      });
                    } else {
                      setSelectedGroup(undefined);
                    }
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item md={3}>
              <FormControl my={2}>
                <Autocomplete
                  disablePortal
                  id="office-list-select"
                  fullWidth
                  defaultValue={
                    selectedOffice && selectedOffice.id && selectedOffice.label
                      ? {
                          id: selectedOffice?.id,
                          label: selectedOffice?.label,
                        }
                      : undefined
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="事業所" required />
                  )}
                  options={filteredOfficeList.map((item) => {
                    return { id: item.id, label: item.name };
                  })}
                  onChange={(e, target) => {
                    if (target && target.id && target.label) {
                      setSelectedOffice({
                        id: target.id,
                        label: target.label,
                      });
                    } else {
                      setSelectedOffice(undefined);
                    }
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item md={6}>
              <FormControl fullWidth my={2} variant="outlined">
                <TextField
                  id="last-name"
                  label="性"
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  value={user?.lastName}
                  fullWidth
                  onChange={(e) => {
                    if (user) setUser({ ...user, lastName: e.target?.value });
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl fullWidth my={2} variant="outlined">
                <TextField
                  id="first-name"
                  label="名"
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  value={user?.firstName}
                  fullWidth
                  onChange={(e) => {
                    if (user) setUser({ ...user, firstName: e.target?.value });
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item md={6}>
              <FormControl fullWidth my={2} variant="outlined">
                <TextField
                  id="last-name"
                  label="セイ"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  value={user?.lastNameKana}
                  fullWidth
                  onChange={(e) => {
                    if (user)
                      setUser({ ...user, lastNameKana: e.target?.value });
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl fullWidth my={2} variant="outlined">
                <TextField
                  id="first-name"
                  label="メイ"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  value={user?.firstNameKana}
                  fullWidth
                  onChange={(e) => {
                    if (user)
                      setUser({ ...user, firstNameKana: e.target?.value });
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item md={6}>
              <FormControl fullWidth my={2} variant="outlined">
                <TextField
                  id="email"
                  label="Email"
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  value={user?.email}
                  fullWidth
                  onChange={(e) => {
                    if (user) setUser({ ...user, email: e.target?.value });
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Button
            my={2}
            variant="contained"
            color="primary"
            onClick={() => submit()}
          >
            変更を保存する
          </Button>
        </CardContent>
      )}
      {!(groupList.length > 0 && officeList.length > 0) && (
        <CardContent
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </CardContent>
      )}
    </Card>
  );
};

function StaffsEditPage() {
  const location = useLocation();
  const pathNames = location.pathname.split("/").filter((x) => x);
  const userId = pathNames[pathNames.length - 1];

  return (
    <React.Fragment>
      <Typography variant="h3" gutterBottom display="inline">
        事業所担当者編集
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          ダッシュボード
        </Link>
        <Link component={NavLink} to="/staffs">
          事業所担当者一覧
        </Link>
        <Typography>事業所担当者編集</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <StaffsEditPageContent userId={userId} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default StaffsEditPage;
