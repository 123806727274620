import Axios from "axios";

export const getAddressInfosByPoseCode = async (postCode?: string) => {
  if (postCode) {
    let results: any[] = [];
    const replacePostCode = postCode.replace(/-/g, "");
    await Axios.get(
      `https://zipcloud.ibsnet.co.jp/api/search?zipcode=${replacePostCode}`
    ).then((value) => {
      results = value.data.results;
    });
    return results;
  } else {
    return [];
  }
};
