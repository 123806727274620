import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useLocation } from "react-router-dom";

import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Grid,
  Button,
  FormControl,
  Autocomplete,
  TextField,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridSortItem,
  GridSortModel,
  GridValueFormatterParams,
  jaJP,
  getGridNumericColumnOperators,
} from "@mui/x-data-grid";
import { spacing } from "@mui/system";
import {
  Add as AddIcon,
  Upload as UploadIcon,
  Download as DownloadIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import { axiosInstance } from "../../utils/axios";
import {
  OfficeDeviceListResponse,
  OfficeDeviceListURI,
  OfficeDeviceResponse,
  OfficeDevicesDownloadCsvURI,
  OfficeDevicesGenerateCsvURI,
  OfficeDevicesUploadCsvResponse,
  OfficeDevicesUploadCsvURI,
  OfficeDeviceURI,
  ResponseSuccess,
} from "../../types/api/officeDevice";
import EditButton from "../../components/buttons/EditButton";
import DeleteButton from "../../components/buttons/DeleteButton";
import Notification, { NotificationProps } from "../../components/Notification";
import { ErrorResponse } from "../../types/api";
import ReportButton from "../../components/buttons/ReportButton";
import LogButton from "../../components/buttons/LogButton";
import { CustomToolbar } from "../../components/tables/Toolbar";
import useAuth from "../../hooks/useAuth";
import { formatDate, formatDateFromTimestamp } from "../../utils/dateHelper";
import { InitialMessageData, MessageType } from "../../types/message";
import { officeAtom } from "../../atoms/office";
import { useRecoilState } from "recoil";
import { groupAtom } from "../../atoms/group";
import { ObjectIdName } from "../../types/api/idNameList";
import {
  ArrayGroup,
  FetchGroupListResponse,
  GroupListURI,
} from "../../types/api/group";
import {
  ArrayOffice,
  OfficeListResponse,
  OfficeListURI,
} from "../../types/api/office";
import { camelToSnakeCase } from "../../utils/other";
import { downloadFile } from "../../utils/file";
import { scroll2Top } from "../../utils/dataGridHelper";
import DashboardButton from "../../components/buttons/DashboardButton";
import { format } from "date-fns";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

type Props = {
  selectedGroupIdName?: ObjectIdName;
  selectedOfficeIdName?: ObjectIdName;
  macAddress?: string;
  clickedSearchBtn: boolean;
};

const OfficeDevicesPageContent = ({
  selectedGroupIdName,
  selectedOfficeIdName,
  macAddress,
  clickedSearchBtn,
}: Props) => {
  const { user } = useAuth();
  const [currentOffice, setCurrentOffice] = useRecoilState(officeAtom);
  const [currentGroup, setCurrentGroup] = useRecoilState(groupAtom);
  const [message, setMessage] = useState<MessageType>(InitialMessageData);
  const [data, setData] = useState<OfficeDeviceListResponse["objects"] | []>(
    []
  );
  const [deletedDataId, setDeletedDataId] = useState<string[]>([]);
  const [notification, setNotification] = useState<NotificationProps>();

  const handleDelete = async (id: string) => {
    const res = await axiosInstance.delete<OfficeDeviceResponse>(
      OfficeDeviceURI(id)
    );
    if (res.status === 200) {
      setDeletedDataId([...deletedDataId, id]);
    }
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 300,
      hide: true,
      sortable: false,
      filterable: false,
    },
    {
      field: "exDeviceId",
      headerName: "ExDeviceId",
      width: 300,
      hide: true,
      sortable: false,
      filterable: false,
    },
    {
      field: "macAddress",
      headerName: "MACアドレス",
      width: 300,
      sortable: true,
      filterable: true,
      filterOperators: getGridNumericColumnOperators().filter(
        (operator) => operator.value === "include"
      ),
    },
    {
      field: "group",
      headerName: "グループ",
      width: 200,
      editable: false,
      sortable: false,
      filterable: false,
      valueGetter: (params) => {
        const officeDevice = params.row as OfficeDeviceResponse;
        return officeDevice.office.group.name;
      },
    },
    {
      field: "officeName",
      headerName: "事業所",
      width: 200,
      editable: false,
      sortable: true,
      filterable: false,
      valueGetter(params) {
        const officeDevice = params.row as OfficeDeviceResponse;
        return officeDevice.office.name;
      },
    },
    {
      field: "placeName",
      headerName: "設置場所",
      width: 200,
      editable: false,
      sortable: false,
      filterable: false,
      valueGetter(params) {
        const officeDevice = params.row as OfficeDeviceResponse;
        return officeDevice.placeName;
      },
    },
    {
      field: "name",
      headerName: "機器名",
      width: 200,
      editable: false,
      sortable: false,
      filterable: false,
      valueGetter(params) {
        const officeDevice = params.row as OfficeDeviceResponse;
        return officeDevice.device.name;
      },
    },
    {
      field: "maker",
      headerName: "メーカー",
      width: 200,
      editable: false,
      sortable: false,
      filterable: false,
      valueGetter(params) {
        const officeDevice = params.row as OfficeDeviceResponse;
        return officeDevice.device.maker;
      },
    },
    {
      field: "placeName",
      headerName: "設置場所",
      width: 200,
      editable: false,
      sortable: false,
      filterable: false,
    },
    {
      field: "lastUpdatedAt",
      headerName: "最終データ取得日時",
      width: 150,
      editable: false,
      sortable: false,
      filterable: false,
      valueGetter(params) {
        const officeDevice = params.row as OfficeDeviceResponse;
        return formatDateFromTimestamp(
          officeDevice.latestDeviceLog?.timestampValue
        );
      },
    },
    {
      field: "removalDate",
      headerName: "廃止日",
      type: "datetime",
      width: 150,
      editable: false,
      sortable: true,
      filterable: false,
      valueFormatter: (params: GridValueFormatterParams) => {
        return (
          params.value && format(new Date(params.value as string), "yyyy/MM/dd")
        );
      },
    },
    {
      field: "createdAt",
      headerName: "作成日時",
      type: "dateTime",
      width: 150,
      editable: false,
      sortable: true,
      filterable: false,
      valueFormatter: (params: GridValueFormatterParams) => {
        return formatDateFromTimestamp(params.value);
      },
    },
    {
      field: "updatedAt",
      headerName: "更新日時",
      type: "datetime",
      width: 150,
      editable: false,
      sortable: true,
      filterable: false,
      hide: true,
      valueFormatter: (params: GridValueFormatterParams) => {
        return formatDateFromTimestamp(params.value);
      },
    },
  ];

  if (user?.role === "admin") {
    columns.unshift(
      // ダッシュボード
      {
        field: "dashboardBtn",
        headerName: "ダッシュボード",
        sortable: false,
        filterable: false,
        width: 150,
        align: "center",
        headerAlign: "center",
        renderCell: (params) => {
          return (
            <DashboardButton href={`/office-devices/${params.id}/dashboard`} />
          );
        },
      }
    );
    columns.unshift(
      // レポート確認ボタン
      {
        field: "reportBtn",
        headerName: "レポート",
        sortable: false,
        filterable: false,
        width: 80,
        align: "center",
        headerAlign: "center",
        renderCell: (params) => (
          <ReportButton href={`/office-devices/${params.id}/report`} />
        ),
      }
    );
    columns.unshift({
      field: "logBtn",
      headerName: "ログ",
      sortable: false,
      filterable: false,
      width: 80,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <LogButton href={`/office-devices/${params.id}/log`} />
      ),
    });
    columns.unshift(
      // 削除ボタン
      {
        field: "editBtn",
        headerName: "編集",
        sortable: false,
        filterable: false,
        width: 60,
        align: "center",
        headerAlign: "center",
        renderCell: (params) =>
          params.row.removalDate === null ? (
            <EditButton href={`/office-devices/${params.id}`} />
          ) : (
            "廃止"
          ),
        valueGetter: (params) => {
          // const officeDevice = params.row as OfficeDeviceResponse;
          if (params.row.removalDate !== null) {
            return "廃止";
          }
        },
      }
    );
    columns.unshift(
      // 削除ボタン
      {
        field: "deleteBtn",
        headerName: "削除",
        sortable: false,
        filterable: false,
        width: 60,
        align: "center",
        headerAlign: "center",
        renderCell: (params) => (
          <DeleteButton id={params.id as string} callback={handleDelete} />
        ),
      }
    );
  } else {
    columns.unshift(
      // ダッシュボード
      {
        field: "dashboardBtn",
        headerName: "ダッシュボード",
        sortable: false,
        filterable: false,
        width: 150,
        align: "center",
        headerAlign: "center",
        renderCell: (params) => {
          return (
            <DashboardButton href={`/office-devices/${params.id}/dashboard`} />
          );
        },
      }
    );
    columns.unshift({
      field: "logBtn",
      headerName: "ログ",
      sortable: false,
      filterable: false,
      width: 80,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <LogButton href={`/office-devices/${params.id}/log`} />
      ),
    });
    columns.unshift(
      // 削除ボタン
      {
        field: "editBtn",
        headerName: "編集",
        sortable: false,
        filterable: false,
        width: 60,
        align: "center",
        headerAlign: "center",
        renderCell: (params) =>
          params.row.removalDate === null ? (
            <EditButton href={`/office-devices/${params.id}`} />
          ) : (
            "廃止"
          ),
        valueGetter: (params) => {
          // const officeDevice = params.row as OfficeDeviceResponse;
          if (params.row.removalDate !== null) {
            return "廃止";
          }
        },
      }
    );
  }

  const [pageSize, setPageSize] = useState<number>(50);
  const [page, setPage] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const handlePageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
  };
  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const [gridSortItem, setGridSortItem] = useState<GridSortItem>({
    field: "createdAt",
    sort: "desc",
  });
  const handleSortModelChange = React.useCallback(
    (sortModel: GridSortModel) => {
      if (sortModel.length > 0) {
        setGridSortItem({
          field: sortModel[0].field,
          sort: sortModel[0].sort,
        });
      }
    },
    []
  );

  const fetchData = async (
    groupId: string | undefined,
    officeId: string | undefined,
    gridSortItem: GridSortItem,
    macAddress: string | undefined,
    pageSize: number,
    page: number
  ) => {
    setLoading(true);
    const res = await axiosInstance.get<
      OfficeDeviceListResponse | ErrorResponse
    >(OfficeDeviceListURI, {
      params: {
        group_id: groupId,
        office_id: officeId,
        offset: page * pageSize,
        limit: pageSize,
        sort_by: `${camelToSnakeCase(gridSortItem.field)}-${gridSortItem.sort}`,
        keyword: macAddress,
      },
    });

    if (res.status === 200) {
      const responseData = res.data as OfficeDeviceListResponse;
      setData(responseData.objects);
      setTotal(responseData.paging.total);
      setLoading(false);
    } else {
      setMessage({
        body: (res.data as ErrorResponse).message!,
        isOpen: true,
        type: "error",
      });
      setTimeout(() => {
        setMessage({
          ...message,
          isOpen: false,
        });
      }, 5000);
      setLoading(false);
    }
  };

  const featchInEachRole = () => {
    if (user?.role === "admin") {
      fetchData(
        selectedGroupIdName?.id,
        selectedOfficeIdName?.id,
        gridSortItem,
        macAddress,
        pageSize,
        page
      );
    } else if (user?.role === "manager") {
      !selectedOfficeIdName &&
        currentGroup.id.length > 0 &&
        fetchData(
          currentGroup.id,
          undefined,
          gridSortItem,
          macAddress,
          pageSize,
          page
        );
      selectedOfficeIdName &&
        fetchData(
          undefined,
          selectedOfficeIdName.id,
          gridSortItem,
          macAddress,
          pageSize,
          page
        );
    } else if (user?.role === "staff") {
      currentOffice.id.length > 0 &&
        fetchData(
          undefined,
          currentOffice.id,
          gridSortItem,
          macAddress,
          pageSize,
          page
        );
    }
  };

  useEffect(() => {
    featchInEachRole();
  }, [user, currentGroup, currentOffice, gridSortItem, pageSize, page]);

  useEffect(() => {
    if (deletedDataId) {
      setData(data.filter((element) => !deletedDataId.includes(element.id)));
    }
  }, [deletedDataId]);

  useEffect(() => {
    if (!loading) {
      scroll2Top();
    }
  }, [loading]);

  useEffect(() => {
    if (clickedSearchBtn) {
      featchInEachRole();
      clickedSearchBtn = false;
    }
  }, [clickedSearchBtn]);

  return (
    <>
      <Card mb={6}>
        <Paper>
          <div style={{ height: 800, width: "100%" }}>
            {user && (
              <DataGrid
                rowsPerPageOptions={[50, 100]}
                rows={data}
                columns={columns}
                pageSize={pageSize}
                rowCount={total}
                page={page}
                checkboxSelection={false}
                localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
                components={{ Toolbar: CustomToolbar }}
                initialState={{
                  sorting: {
                    sortModel: [gridSortItem],
                  },
                }}
                loading={loading}
                pagination
                paginationMode="server"
                onPageSizeChange={handlePageSizeChange}
                onPageChange={handlePageChange}
                sortingMode="server"
                onSortModelChange={handleSortModelChange}
                sortingOrder={["desc", "asc"]}
                // filterMode="server"
                // onFilterModelChange={handleFilterModelChange}
              />
            )}
          </div>
        </Paper>
      </Card>
      <Notification
        message={message.body}
        isOpen={message.isOpen}
        type={message.type}
        onClose={() => {
          setMessage({
            ...message,
            isOpen: false,
          });
        }}
      />
    </>
  );
};

const OfficeDevicesPage = () => {
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const defaultOfficeId = query.get("id");
  const defaultOfficeName = query.get("name");
  const defaultOfficeIdName =
    defaultOfficeId && defaultOfficeName
      ? { id: defaultOfficeId, name: defaultOfficeName }
      : undefined;

  const { user } = useAuth();
  const [currentGroup, setCurrentGroup] = useRecoilState(groupAtom);
  const [groupList, setGroupList] = useState<ArrayGroup>([]);
  // const [groupIdNameList, setGroupIdNameList] = useState<ArrayIdName>([]);
  const [selectedGroupIdName, setSelectedGroupIdName] = useState<
    ObjectIdName | undefined
  >(
    user?.role === "manager"
      ? {
          id: currentGroup.id,
          name: currentGroup.name,
        }
      : undefined
  );
  const [officeList, setOfficeList] = useState<ArrayOffice>([]);
  const [selectedOfficeIdName, setSelectedOfficeIdName] = useState<
    ObjectIdName | undefined
  >(defaultOfficeIdName);
  const [macAddress, setMacAddress] = useState<string>("");

  const [refetch, setRefetch] = useState(false);
  const [clickedSearchBtn, setClickedSearchBtn] = useState(false);

  const [notification, setNotification] = useState<NotificationProps>();

  const changeUploadFile = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { files } = event.target;

    if (files && files.length > 0) {
      const data = new FormData();
      data.append("csv_file", files[0]);
      const headers = { "content-type": "multipart/form-data" };

      const res = await axiosInstance.post<
        OfficeDevicesUploadCsvResponse | ResponseSuccess
      >(OfficeDevicesUploadCsvURI, data, { headers });
      console.log(res);
      if (res.status === 200) {
        setRefetch(true);
        setNotification({
          message: "CSVアップロードに成功しました",
          isOpen: true,
          type: "success",
        });
        window.location.reload();
      } else {
        setNotification({
          message: (res.data as ResponseSuccess).message,
          isOpen: true,
          type: "error",
        });
      }
    }

    event.target.value = "";
  };

  const downloadCsvTemplateFile = () => {
    // axiosInstance
    //   .get(OfficeDevicesDownloadCsvURI, {
    //     params: {
    //       group_id: selectedGroupIdName?.id,
    //       office_id: selectedOfficeIdName?.id,
    //     },
    //   })
    //   .then((response) => {
    //     downloadFile(
    //       `事業所_設置場所_${formatDate(new Date(), "yyyyMMddHHmmss")}`,
    //       response
    //     );
    //   });

    axiosInstance.post(OfficeDevicesGenerateCsvURI, {}).then((response) => {
      if (response.status === 200) {
        setNotification({
          message: "CSVダウンロードのリクエストを送信しました",
          isOpen: true,
          type: "success",
        });
      } else {
        setNotification({
          message: "CSVダウンロードのリクエストを送信に失敗しました",
          isOpen: true,
          type: "error",
        });
      }
    });
  };

  useEffect(() => {
    setRefetch(false);
  }, []);

  const fetchGroupIdNameList = async () => {
    // const res = await axiosInstance.get<FetchGroupIdNameListResponse | ErrorResponse>(GroupIdNameListURI, {
    //   params: {
    //     sort_by: "created_at-desc",
    //   }
    // });
    // if (res.status === 200) {
    //   setGroupIdNameList((res.data as FetchGroupIdNameListResponse).objects);
    // }

    const res = await axiosInstance.get<FetchGroupListResponse | ErrorResponse>(
      GroupListURI,
      {
        params: {
          offset: 0,
          limit: 5000,
          sort_by: "created_at-desc",
        },
      }
    );
    if (res.status === 200) {
      setGroupList((res.data as FetchGroupListResponse).objects);
    }
  };

  const fetchOfficeIdNameList = async (groupId: string | undefined) => {
    const res = await axiosInstance.get<OfficeListResponse | ErrorResponse>(
      OfficeListURI,
      {
        params: {
          offset: 0,
          limit: 5000,
          sort_by: "created_at-desc",
          group_id: groupId,
        },
      }
    );
    if (res.status === 200) {
      setOfficeList((res.data as OfficeListResponse).objects);
    }
  };

  useEffect(() => {
    fetchGroupIdNameList();
  }, []);

  useEffect(() => {
    fetchOfficeIdNameList(selectedGroupIdName?.id);
  }, [selectedGroupIdName]);

  useEffect(() => {
    if (clickedSearchBtn) {
      setClickedSearchBtn(false);
    }
  }, [clickedSearchBtn]);

  return (
    <React.Fragment>
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            設置センサー機器一覧
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              ダッシュボード
            </Link>
            <Typography>設置センサー機器一覧</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Grid container justifyContent="flex-end" mb={4}>
            {user && ["manager", "admin"].includes(user.role) && (
              <Grid item pl={2}>
                <div>
                  <Button
                    component={NavLink}
                    to="/office-devices/new"
                    variant="contained"
                    color="primary"
                  >
                    <AddIcon />
                    新規追加
                  </Button>
                </div>
              </Grid>
            )}
            {user && ["admin"].includes(user.role) && (
              <>
                <Grid item pl={2}>
                  <Button variant="contained" component="label" color="primary">
                    <UploadIcon />
                    CSVアップロード
                    <input type="file" hidden onChange={changeUploadFile} />
                  </Button>
                </Grid>
              </>
            )}
            {user && ["admin"].includes(user.role) && (
              <>
                <Grid item pl={2}>
                  <Button
                    variant="contained"
                    component="label"
                    color="primary"
                    onClick={downloadCsvTemplateFile}
                  >
                    <DownloadIcon />
                    CSV一括ダウンロード
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
          <Grid container>
            {user && ["manager", "admin"].includes(user.role) && (
              <>
                <Grid item pl={2}>
                  <FormControl fullWidth style={{ minWidth: "300px" }}>
                    <TextField
                      id="mac-address"
                      label="MACアドレス"
                      variant="outlined"
                      value={macAddress}
                      fullWidth
                      onChange={(e) => {
                        setMacAddress(e.target?.value.toString());
                      }}
                      size="small"
                    />
                  </FormControl>
                </Grid>
                <Grid item pl={2}>
                  <FormControl fullWidth style={{ minWidth: "300px" }}>
                    <Autocomplete
                      disablePortal
                      id="group-list-select"
                      fullWidth
                      size="small"
                      renderInput={(params) => (
                        <TextField {...params} label="グループ" />
                      )}
                      options={groupList.map((item) => {
                        return { id: item.id, label: item.name };
                      })}
                      onChange={(event, target) => {
                        if (target) {
                          setSelectedGroupIdName({
                            id: target.id,
                            name: target.label,
                          });
                        } else {
                          setSelectedGroupIdName(undefined);
                        }
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item pl={2}>
                  <FormControl fullWidth style={{ minWidth: "300px" }}>
                    <Autocomplete
                      disablePortal
                      id="office-list-select"
                      fullWidth
                      size="small"
                      defaultValue={
                        selectedOfficeIdName &&
                        selectedOfficeIdName.id &&
                        selectedOfficeIdName.name
                          ? {
                              id: selectedOfficeIdName?.id,
                              label: selectedOfficeIdName?.name,
                            }
                          : undefined
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="オフィス" />
                      )}
                      options={officeList.map((item) => {
                        return { id: item.id, label: item.name };
                      })}
                      onChange={(event, target) => {
                        if (target && target.id && target.label) {
                          setSelectedOfficeIdName({
                            id: target.id,
                            name: target.label,
                          });
                        } else {
                          setSelectedOfficeIdName(undefined);
                        }
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item pl={2}>
                  <Button
                    variant="contained"
                    component="label"
                    color="primary"
                    onClick={() => {
                      setClickedSearchBtn(true);
                    }}
                  >
                    <SearchIcon />
                    検索
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Divider my={6} />

      <OfficeDevicesPageContent
        macAddress={macAddress}
        selectedGroupIdName={selectedGroupIdName}
        selectedOfficeIdName={selectedOfficeIdName}
        clickedSearchBtn={clickedSearchBtn}
      />

      {notification && (
        <Notification
          message={notification.message}
          isOpen={notification.isOpen}
          type={notification.type}
          onClose={() =>
            setNotification({
              message: "",
              isOpen: false,
              type: "info",
            })
          }
        />
      )}
    </React.Fragment>
  );
};

export default OfficeDevicesPage;
