import React, { useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  Link,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import { axiosInstance } from "../../utils/axios";
import { ErrorResponse } from "../../types/api";
import {
  ChangePasswordParams,
  ChangePasswordResponse,
  ChangePasswordURI,
} from "../../types/api/auth";
import Notification from "../../components/Notification";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}
const Button = styled(MuiButton)<ButtonProps>(spacing);

const SettingsPageContent = () => {
  type ParamsType = {
    password: string;
    passwordConf: string;
  };
  type MessageType = {
    body: string;
    isOpen: boolean;
    type?: "success" | "info" | "warning" | "error";
  };

  const [message, setMessage] = useState<MessageType>({
    body: "",
    isOpen: false,
    type: "error",
  });
  const [params, setParams] = useState<ParamsType>({
    password: "",
    passwordConf: "",
  });
  const submit = async () => {
    const res = await axiosInstance.post<
      ChangePasswordResponse | ErrorResponse
    >(ChangePasswordURI, {
      password: params?.password,
      password_conf: params?.passwordConf,
    } as ChangePasswordParams);

    if (res.status === 200) {
      setMessage({
        body: "パスワードの変更が完了しました",
        isOpen: true,
        type: "success",
      });
      setParams({ password: "", passwordConf: "" });
    } else {
      setMessage({
        body: (res.data as ErrorResponse).message!,
        isOpen: true,
        type: "error",
      });
    }
    setTimeout(() => {
      setMessage({
        ...message,
        isOpen: false,
      });
    }, 5000);
  };

  return (
    <>
      <Card mb={6}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            パスワード変更
          </Typography>

          <Grid container spacing={6}>
            <Grid item md={6}>
              <TextField
                id="password"
                label="新しいパスワード"
                type="password"
                variant="outlined"
                value={params.password}
                fullWidth
                my={2}
                onChange={(e) => {
                  const password = e.target.value as string;
                  setParams({ ...params, password });
                }}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                id="password-conf"
                label="新しいパスワードの確認"
                type="password"
                variant="outlined"
                value={params.passwordConf}
                fullWidth
                my={2}
                onChange={(e) => {
                  const passwordConf = e.target.value as string;
                  setParams({ ...params, passwordConf });
                }}
              />
            </Grid>
          </Grid>

          <Button
            variant="contained"
            color="primary"
            mt={3}
            onClick={() => submit()}
          >
            変更を保存する
          </Button>
        </CardContent>
      </Card>
      <Notification
        message={message.body}
        isOpen={message.isOpen}
        type={message.type}
        onClose={() => {
          setMessage({
            ...message,
            isOpen: false,
          });
        }}
      />
    </>
  );
};

const SettingsPage = () => {
  return (
    <React.Fragment>
      <Typography variant="h3" gutterBottom display="inline">
        設定
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          ダッシュボード
        </Link>
        <Typography>設定</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <SettingsPageContent />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default SettingsPage;
