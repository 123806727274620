import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Outlet } from "react-router-dom";

import { Box, CssBaseline, Paper as MuiPaper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { spacing } from "@mui/system";

import GlobalStyle from "../components/GlobalStyle";
import Navbar from "../components/navbar/Navbar";
import {
  adminNavItems,
  managerNavItems,
  staffNavItems,
} from "../components/sidebar/dashboardItems";
import Sidebar from "../components/sidebar/Sidebar";
import Footer from "../components/Footer";
import AuthGuard from "../components/guards/AuthGuard";
import useAuth from "../hooks/useAuth";
import { NavItemsType } from "../types/navbar";
import Settings from "../components/Settings";

const drawerWidth = 258;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const Dashboard: React.FC<any> = ({ children }) => {
  const { user } = useAuth();
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const [navItems, setNavItems] = useState<NavItemsType[] | []>([]);

  useEffect(() => {
    if (user) {
      setNavItems(
        user.role === "admin"
          ? adminNavItems
          : user.role === "manager"
          ? managerNavItems
          : staffNavItems
      );
    }
  }, [user]);

  return (
    <AuthGuard>
      <Root>
        <CssBaseline />
        <GlobalStyle />
        {navItems.length > 0 && (
          <>
            <Drawer>
              <Box sx={{ display: { xs: "block", lg: "none" } }}>
                <Sidebar
                  PaperProps={{ style: { width: drawerWidth } }}
                  variant="temporary"
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  items={navItems}
                />
              </Box>
              <Box sx={{ display: { xs: "none", md: "block" } }}>
                <Sidebar
                  PaperProps={{ style: { width: drawerWidth } }}
                  items={navItems}
                />
              </Box>
            </Drawer>

            <AppContent>
              <Navbar onDrawerToggle={handleDrawerToggle} />
              <MainContent p={isLgUp ? 12 : 5}>
                {children}
                <Outlet />
              </MainContent>
              <Footer />
            </AppContent>
          </>
        )}
        <Settings />
      </Root>
    </AuthGuard>
  );
};

export default Dashboard;
