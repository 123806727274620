import { SerializedStyles } from "@emotion/react";
import MemoImgCharacter01 from "../images/ImgCharacter01";
import MemoImgCharacter02 from "../images/ImgCharacter02";
import MemoImgCharacter03 from "../images/ImgCharacter03";
import MemoImgCharacter04 from "../images/ImgCharacter04";
import MemoImgCharacter05 from "../images/ImgCharacter05";

export const getAirQualityLevel = (
  co2Value: number,
  tvocValue: number,
  pm25Value: number
) => {
  if (co2Value === 0 && tvocValue === 0 && pm25Value === 0) return -1;

  const CO2_FT = AIR_QUALITY_VARIATION.CO2.THRESHOLD.UPPER.FIRST;
  const CO2_ST = AIR_QUALITY_VARIATION.CO2.THRESHOLD.UPPER.SECOND || 0;
  const TVOC_FT = AIR_QUALITY_VARIATION.TVOC.THRESHOLD.UPPER.FIRST;
  const TVOC_ST = AIR_QUALITY_VARIATION.TVOC.THRESHOLD.UPPER.SECOND || 0;
  const PM25_FT = AIR_QUALITY_VARIATION.PM25.THRESHOLD.UPPER.FIRST;
  const PM25_ST = AIR_QUALITY_VARIATION.PM25.THRESHOLD.UPPER.SECOND || 0;

  let airQualityLevel = 0;
  if (CO2_FT <= co2Value || TVOC_FT <= tvocValue || PM25_FT <= pm25Value)
    return 6 - 5;
  if (CO2_ST < co2Value && co2Value < CO2_FT) airQualityLevel++;
  if (TVOC_ST < tvocValue && tvocValue < TVOC_FT) airQualityLevel++;
  if (PM25_ST < pm25Value && pm25Value < PM25_FT) airQualityLevel++;
  return 6 - (airQualityLevel + 1);
};

export const getAirQualityImage = (
  airQualityLevel: number,
  size?: string[],
  style?: SerializedStyles
) => {
  switch (airQualityLevel) {
    case 1:
      return (
        <MemoImgCharacter01
          size={size || ["96px", "120px", "240px", "320px", "400px"]}
          style={style}
        />
      );
    case 2:
      return (
        <MemoImgCharacter02
          size={size || ["96px", "120px", "240px", "320px", "400px"]}
          style={style}
        />
      );
    case 3:
      return (
        <MemoImgCharacter03
          size={size || ["96px", "120px", "240px", "320px", "400px"]}
          style={style}
        />
      );
    case 4:
      return (
        <MemoImgCharacter04
          size={size || ["96px", "120px", "240px", "320px", "400px"]}
          style={style}
        />
      );
    case 5:
      return (
        <MemoImgCharacter05
          size={size || ["96px", "120px", "240px", "320px", "400px"]}
          style={style}
        />
      );
  }
};

export const getAirQualityMessage = (airQualityLevel: number) => {
  switch (airQualityLevel) {
    case 1:
      return `空気は綺麗です！\nこのまま維持しましょう！`;
    case 2:
      return `空気は比較的綺麗です！\n定期的な換気を！`;
    case 3:
      return `空気が少し汚れています！\n換気をし気分転換をしましょう！`;
    case 4:
      return `空気が汚れています...\n定期的な換気をしましょう！`;
    case 5:
      return `空気がとても汚れています...\n今すぐ換気をしましょう！`;
  }
};

type AIR_QUALITY_THRESHOLD_VALUE_TYPE = {
  LABEL: {
    EN: string;
    JA: string;
  };
  UNIT: string;
  THRESHOLD: {
    UPPER: {
      FIRST: number;
      SECOND: number | undefined;
    };
    LOWER: {
      FIRST: number | undefined;
      SECOND: number | undefined;
    };
  };
};

export type AIR_QUALITY_VARIATION_TYPE = {
  RH: AIR_QUALITY_THRESHOLD_VALUE_TYPE;
  CO2: AIR_QUALITY_THRESHOLD_VALUE_TYPE;
  TEMP: AIR_QUALITY_THRESHOLD_VALUE_TYPE;
  PM25: AIR_QUALITY_THRESHOLD_VALUE_TYPE;
  TVOC: AIR_QUALITY_THRESHOLD_VALUE_TYPE;
};

export const AIR_QUALITY_VARIATION: AIR_QUALITY_VARIATION_TYPE = {
  RH: {
    LABEL: {
      EN: "RH",
      JA: "湿度",
    },
    UNIT: "%",
    THRESHOLD: {
      UPPER: {
        FIRST: 70,
        SECOND: undefined,
      },
      LOWER: {
        FIRST: 40,
        SECOND: undefined,
      },
    },
  },
  CO2: {
    LABEL: {
      EN: "CO2",
      JA: "二酸化炭素濃度",
    },
    UNIT: "PPM",
    THRESHOLD: {
      UPPER: {
        FIRST: 1000,
        SECOND: 800,
      },
      LOWER: {
        FIRST: undefined,
        SECOND: undefined,
      },
    },
  },
  TEMP: {
    LABEL: {
      EN: "TEMP",
      JA: "温度",
    },
    UNIT: "℃",
    THRESHOLD: {
      UPPER: {
        FIRST: 32,
        SECOND: undefined,
      },
      LOWER: {
        FIRST: 16,
        SECOND: undefined,
      },
    },
  },
  PM25: {
    LABEL: {
      EN: "PM2.5",
      JA: "微小粒子状物質濃度",
    },
    UNIT: "μg/m3",
    THRESHOLD: {
      UPPER: {
        FIRST: 35,
        SECOND: 30,
      },
      LOWER: {
        FIRST: undefined,
        SECOND: undefined,
      },
    },
  },
  TVOC: {
    LABEL: {
      EN: "TVOC",
      JA: "揮発性有機化合物濃度",
    },
    UNIT: "ppb",
    THRESHOLD: {
      UPPER: {
        FIRST: 7500,
        SECOND: 6000,
      },
      LOWER: {
        FIRST: undefined,
        SECOND: undefined,
      },
    },
  },
};
