import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  Link,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import {
  ErrorResponse,
  UserEditResponse,
  UserEditURI,
  UserResponse,
  UserURI,
} from "../../types/api";
import { axiosInstance } from "../../utils/axios";
import Notification from "../../components/Notification";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)<{ m?: number }>`
  min-width: 240px;
  width: 100%;
`;

interface ButtonProps extends SpacingProps {
  component?: string;
}
const Button = styled(MuiButton)<ButtonProps>(spacing);

type Props = {
  userId: string;
};

const AdminsEditPageContent = ({ userId }: Props) => {
  const navigate = useNavigate();
  const [errorMessageOpen, setErrorMessageOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [user, setUser] = useState<UserResponse>();

  useEffect(() => {
    const fetchData = async () => {
      const res = await axiosInstance.get<UserResponse | ErrorResponse>(
        UserURI(userId)
      );

      if (res.status === 200) {
        setUser(res.data as UserResponse);
      } else {
        setErrorMessage((res.data as ErrorResponse).message!);
        setErrorMessageOpen(true);
        setTimeout(() => {
          setErrorMessageOpen(false);
        }, 5000);
      }
    };

    fetchData();
  }, [userId]);

  const submit = async () => {
    const res = await axiosInstance.patch<UserEditResponse | ErrorResponse>(
      UserEditURI(userId),
      {
        role: "admin",
        first_name: user?.firstName,
        last_name: user?.lastName,
        first_name_kana: user?.firstNameKana,
        last_name_kana: user?.lastNameKana,
      }
    );

    if (res.status === 200) {
      navigate("/admins");
    } else {
      setErrorMessage((res.data as ErrorResponse).message!);
      setErrorMessageOpen(true);
      setTimeout(() => {
        setErrorMessageOpen(false);
      }, 5000);
    }
  };

  return (
    <>
      <Card mb={6}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item md={6}>
              <FormControl fullWidth my={2} variant="outlined">
                <TextField
                  id="last-name"
                  label="姓"
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  value={user?.lastName}
                  fullWidth
                  onChange={(e) => {
                    if (user) setUser({ ...user, lastName: e.target?.value });
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl fullWidth my={2} variant="outlined">
                <TextField
                  id="first-name"
                  label="名"
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  value={user?.firstName}
                  fullWidth
                  onChange={(e) => {
                    if (user) setUser({ ...user, firstName: e.target?.value });
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item md={6}>
              <FormControl fullWidth my={2} variant="outlined">
                <TextField
                  id="last-name"
                  label="セイ"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  value={user?.lastNameKana}
                  fullWidth
                  onChange={(e) => {
                    if (user)
                      setUser({ ...user, lastNameKana: e.target?.value });
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl fullWidth my={2} variant="outlined">
                <TextField
                  id="first-name"
                  label="メイ"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  value={user?.firstNameKana}
                  fullWidth
                  onChange={(e) => {
                    if (user)
                      setUser({ ...user, firstNameKana: e.target?.value });
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Button
            my={2}
            variant="contained"
            color="primary"
            onClick={() => submit()}
          >
            変更を保存する
          </Button>
        </CardContent>
      </Card>
      <Notification
        message={errorMessage}
        isOpen={errorMessageOpen}
        onClose={() => {
          setErrorMessageOpen(false);
        }}
      />
    </>
  );
};

const AdminsEditPage = () => {
  const location = useLocation();
  const pathNames = location.pathname.split("/").filter((x) => x);
  const userId = pathNames[pathNames.length - 1];

  return (
    <React.Fragment>
      <Typography variant="h3" gutterBottom display="inline">
        システム管理者修正
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          ダッシュボード
        </Link>
        <Link component={NavLink} to="/admins">
          システム管理者一覧
        </Link>
        <Typography>システム管理者修正</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <AdminsEditPageContent userId={userId} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default AdminsEditPage;
