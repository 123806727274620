import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import Chart from "react-chartjs-2";

import { green, red } from "@mui/material/colors";
import {
  Box,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow as MuiTableRow,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);
const Image = styled.img`
  height: 32px;
  width: 32px;
`;

const ChartWrapper = styled.div`
  height: 168px;
  position: relative;
`;

const DoughnutInner = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -22px;
  text-align: center;
  z-index: 0;
`;

const TableRow = styled(MuiTableRow)`
  height: 42px;
`;

const TableCell = styled(MuiTableCell)`
  padding-top: 0;
  padding-bottom: 0;
`;

const GreenText = styled.span`
  color: ${() => green[400]};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

type EachLevelCountType = {
  [key: string]: number;
};

type Props = {
  loading: boolean;
  eachLevelCount: EachLevelCountType;
  doughnutChartData: any;
  totalCount: number;
};

const DoughnutChart = ({
  loading,
  eachLevelCount,
  doughnutChartData,
  totalCount,
}: Props) => {
  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    cutout: "80%",
  };

  return (
    <Card>
      {!loading && (
        <CardContent>
          <Typography variant="h6" mb="16px" fontWeight="bold">
            空気質割合
          </Typography>
          {doughnutChartData && (
            <ChartWrapper>
              <DoughnutInner>
                <Typography variant="h4">
                  {Math.floor(
                    (100 *
                      (eachLevelCount["level5"] + eachLevelCount["level4"])) /
                      totalCount
                  )}
                  %
                </Typography>
                <Typography variant="caption">正常な店舗の割合</Typography>
              </DoughnutInner>
              <Chart
                type="doughnut"
                data={doughnutChartData}
                options={options}
              />
            </ChartWrapper>
          )}

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>空気質レベル</TableCell>
                <TableCell align="right">事業所数</TableCell>
                <TableCell align="right">割合</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Image
                      alt="ImgCharacter05"
                      src={`/static/img/illustrations/ImgCharacter05.png`}
                    />
                    <Typography fontWeight="bold" ml="8px">
                      Lv.5
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell align="right">{eachLevelCount["level5"]}</TableCell>
                <TableCell align="right">
                  <GreenText>
                    {Math.floor((100 * eachLevelCount["level5"]) / totalCount)}%
                  </GreenText>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Image
                      alt="ImgCharacter04"
                      src={`/static/img/illustrations/ImgCharacter04.png`}
                    />
                    <Typography fontWeight="bold" ml="8px">
                      Lv.4
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell align="right">{eachLevelCount["level4"]}</TableCell>
                <TableCell align="right">
                  <GreenText>
                    {Math.floor((100 * eachLevelCount["level4"]) / totalCount)}%
                  </GreenText>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Image
                      alt="ImgCharacter03"
                      src={`/static/img/illustrations/ImgCharacter03.png`}
                    />
                    <Typography fontWeight="bold" ml="8px">
                      Lv.3
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell align="right">{eachLevelCount["level3"]}</TableCell>
                <TableCell align="right">
                  <GreenText>
                    {Math.floor((100 * eachLevelCount["level3"]) / totalCount)}%
                  </GreenText>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Image
                      alt="ImgCharacter02"
                      src={`/static/img/illustrations/ImgCharacter02.png`}
                    />
                    <Typography fontWeight="bold" ml="8px">
                      Lv.2
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell align="right">{eachLevelCount["level2"]}</TableCell>
                <TableCell align="right">
                  <GreenText>
                    {Math.floor((100 * eachLevelCount["level2"]) / totalCount)}%
                  </GreenText>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Image
                      alt="ImgCharacter01"
                      src={`/static/img/illustrations/ImgCharacter01.png`}
                    />
                    <Typography fontWeight="bold" ml="8px">
                      Lv.1
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell align="right">{eachLevelCount["level1"]}</TableCell>
                <TableCell align="right">
                  <GreenText>
                    {Math.floor((100 * eachLevelCount["level1"]) / totalCount)}%
                  </GreenText>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  <Typography fontWeight="bold" color={red[400]}>
                    停止中
                  </Typography>
                </TableCell>
                <TableCell align="right">{eachLevelCount["level0"]}</TableCell>
                <TableCell align="right">
                  <GreenText>
                    {Math.floor((100 * eachLevelCount["level0"]) / totalCount)}%
                  </GreenText>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      )}
      {loading && (
        <CardContent>
          <div style={{ paddingLeft: 16, paddingRight: 16 }}>
            <CircularProgress size={24} />
          </div>
        </CardContent>
      )}
    </Card>
  );
};

export default withTheme(DoughnutChart);
