import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from "recoil";
import {
  InitialObjectGroup,
  ObjectGroup,
  InitialObjectGroups,
  ArrayGroup,
} from "../types/api/group";

export const groupAtom = atom<ObjectGroup>({
  key: "groupAtom",
  default: InitialObjectGroup,
});

export const groupsAtom = atom<ArrayGroup>({
  key: "groupsAtom",
  default: InitialObjectGroups,
});
